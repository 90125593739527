import React from 'react';
import {
  useMemo,
  useState,
  useLocation,
  useHistory,
  useCallback,
  useAutoChangeProject,
  usePagination,
} from 'hooks/hooks.js';
import { PageHeader, Pagination, Tabs } from 'Components/components.js';
import { CostsTab, PipelineTab, ResourceTab } from './components/components.js';
import { useUserInfo } from 'context/context.js';
import {
  AMPL_PAGE_EVENT,
  DAYS_LIMIT,
  PipelineTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import {
  DefaultSearchValues,
  SELECTED_DESTINATION,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from './libs/constants/constants.js';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.pipeline} -> Tab`;
const TABLE_NAME_SEPARATOR = '.';
const LABEL_SEPARATOR = ':';
const DAYS_LIMIT_FOR_YALO = 90;
const YALO_TENANT = 'yalo-n8gj5';

const Pipelines = () => {
  const location = useLocation();
  const history = useHistory();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [paginationTotal, setPaginationTotal] = useState(0);

  const { user } = useUserInfo();
  const isYaloTenant =
    user.info?.tenant === YALO_TENANT ||
    user.info?.adminData?.currentTenant === YALO_TENANT;

  useAutoChangeProject();

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const getCurrentTabFromUrl = () => {
    const tab = searchParams.get(SEARCH_PARAMS.TAB);
    if (tab && Object.values(PipelineTabs).includes(tab)) {
      return tab;
    }
    return PipelineTabs.COSTS;
  };

  const [currentTab, setCurrentTab] = useState(getCurrentTabFromUrl());
  const hasDateRangePicker = currentTab !== PipelineTabs.PIPELINE_INFO;

  const getSearchValue = () => {
    const value = { ...DefaultSearchValues };
    for (const searchKey in DefaultSearchValues) {
      const searchValue =
        searchKey === SELECTED_TAGS
          ? searchParams.getAll(searchKey)
          : searchParams.get(searchKey);
      if (searchValue) {
        if (
          searchKey === SELECTED_TABLE ||
          searchKey === SELECTED_DESTINATION
        ) {
          const [dataset, table] = searchValue.split(TABLE_NAME_SEPARATOR);
          value[searchKey] = { dataset, table };
          break;
        }

        if (searchKey === SELECTED_LABEL) {
          const [labelKey, labelValue] = searchValue.split(LABEL_SEPARATOR);
          value[searchKey] = { key: labelKey, value: labelValue };
          break;
        }

        if (searchKey === SELECTED_TAGS) {
          if (Array.isArray(searchValue)) {
            value[searchKey] = [...searchValue];
          } else {
            value[searchKey] = [searchValue];
          }
          break;
        }

        value[searchKey] = searchValue;
        break;
      }
    }

    return value;
  };

  const [searchValues, setSearchValues] = useState(getSearchValue());

  const handleChangeTab = useCallback(
    (selectedTab) => {
      if (searchParams.get(SEARCH_PARAMS.TAB) !== selectedTab) {
        if (selectedTab === PipelineTabs.RESOURCE_ALLOCATION) {
          for (const defaultSearchKey in DefaultSearchValues) {
            searchParams.delete(defaultSearchKey);
          }
        }

        searchParams.set(SEARCH_PARAMS.TAB, selectedTab);
        history.replace({
          pathname: location.pathname,
          search: searchParams.toString(),
          state: history.location.state,
        });
      }
      amplEvent(`${EVENT_CLICK} ${selectedTab}`);
      setCurrentTab(selectedTab);
    },
    [history, location.pathname, searchParams]
  );

  const handleChangeSearchValues = useCallback(
    (searchKey, searchValue) => {
      handleChangePage({}, 0);
      const newSearchValues = { ...DefaultSearchValues };
      if (searchKey && searchValue) {
        newSearchValues[searchKey] = searchValue;
      }
      setSearchValues(newSearchValues);

      for (const defaultSearchKey in DefaultSearchValues) {
        if (defaultSearchKey === searchKey && searchValue) {
          if (
            searchKey === SELECTED_TABLE ||
            searchKey === SELECTED_DESTINATION
          ) {
            const param =
              searchValue.dataset && searchValue.table
                ? `${searchValue.dataset}${TABLE_NAME_SEPARATOR}${searchValue.table}`
                : `${searchValue.dataset}`;

            searchParams.set(defaultSearchKey, param);
            continue;
          }
          if (searchKey === SELECTED_LABEL) {
            searchParams.set(
              defaultSearchKey,
              `${searchValue.key}${LABEL_SEPARATOR}${searchValue.value}`
            );
            continue;
          }
          if (searchKey === SELECTED_TAGS && Array.isArray(searchValue)) {
            searchParams.delete(defaultSearchKey);
            for (const item of searchValue) {
              searchParams.append(defaultSearchKey, item);
            }
            continue;
          }
          searchParams.set(defaultSearchKey, searchValue);
        } else {
          searchParams.delete(defaultSearchKey);
        }
      }
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    },
    [handleChangePage, history, location.pathname, searchParams]
  );

  const handleChangePaginationTotal = useCallback((value) => {
    setPaginationTotal(value);
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case PipelineTabs.PIPELINE_INFO:
        return (
          <PipelineTab
            searchValues={searchValues}
            handleChangeSearchValues={handleChangeSearchValues}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onReceivePaginationTotal={handleChangePaginationTotal}
          />
        );
      case PipelineTabs.RESOURCE_ALLOCATION:
        return (
          <ResourceTab
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onReceivePaginationTotal={handleChangePaginationTotal}
          />
        );
      default:
        return (
          <CostsTab
            searchValues={searchValues}
            handleChangeSearchValues={handleChangeSearchValues}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onReceivePaginationTotal={handleChangePaginationTotal}
          />
        );
    }
  };

  return (
    <>
      <PageHeader
        hasDateRangePicker={hasDateRangePicker}
        daysLimit={isYaloTenant ? DAYS_LIMIT_FOR_YALO : DAYS_LIMIT.month}
      >
        Pipelines
      </PageHeader>

      <Tabs
        tabs={PipelineTabs}
        currentTab={currentTab}
        onTabClick={handleChangeTab}
      />

      {renderPage()}

      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Pipelines;
