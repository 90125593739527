import { useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext } from 'context/context.js';

const useCostsDeadEndTables = ({ enabled = true }) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const { data } = useQuery(
    [QUERY_TYPES.deadEndTablesStats, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled }
  );

  const deadEndTablesStats = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { deadEndTablesStats };
};

export { useCostsDeadEndTables };
