import React from 'react';
import {
  useCallback,
  useHistory,
  useMemo,
  useDispatch,
  useGetIsAnomalyTable,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import moment from 'moment/moment';
import {
  Modal,
  RadioDropdown,
  StyledTableRow,
  StyledTableCell,
  RightSideModal,
} from 'Components/components.js';
import { RemoveScanModalBody, EditScanModalBody } from './components.js';
import { useModal } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import {
  ACTION_EDIT,
  ACTION_REMOVE,
  EDIT_RULE_DROPDOWN_ACTIONS,
  JobStatuses,
  SCHEDULE_TYPE,
} from '../libs/constants/constants.js';
import { getFullTableName, getStringByCron } from 'utils/helpers/helpers.js';
import { removeDataScan } from 'slices/actions.js';
import { amplEvent } from 'service/services.js';
import { AMPL_PAGE_EVENT } from 'constants/constants.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';
import errorIcon from 'assets/img/icons/checkIcons/check-error.svg';
import successIcon from 'assets/img/icons/checkIcons/complete.svg';
import progressIcon from 'assets/img/icons/ic-24-progress.svg';
import infoFullIcon from 'assets/img/icons/ic-24-info-full.svg';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.dataQuality} ->`;

const DataQualityTable = ({
  rowData,
  saveScanEditing = () => {},
  isShowTableNameCell = true,
  isShowEditCell = true,
}) => {
  const {
    dataPlexId,
    id,
    lastRunTime,
    dataset,
    table,
    scheduleCron,
    location,
  } = rowData;
  const { setModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();

  const fullTableName = useMemo(() => {
    return getFullTableName(table, dataset);
  }, [dataset, table]);

  const { data: isAnomalyTable } = useGetIsAnomalyTable(dataset, table);

  const scheduleCronToText = useMemo(() => {
    if (!!scheduleCron) {
      const cronValue = scheduleCron.substring(scheduleCron.indexOf(' ') + 1);
      return getStringByCron(cronValue);
    }

    return SCHEDULE_TYPE.ON_DEMAND;
  }, [scheduleCron]);

  const removeScan = useCallback(() => {
    if (location && dataPlexId) {
      dispatch(removeDataScan({ location: location, dataPlexId: dataPlexId }));
    }
  }, [dispatch, location, dataPlexId]);

  const onFilterSelect = (event) => {
    if (event.value === ACTION_EDIT) {
      setModal(() => (
        <RightSideModal
          onAgree={() => {}}
          title='Edit data quality scan'
          ModalBodyComponent={EditScanModalBody}
          mainBlockMargin={0}
          saveDataScan={saveScanEditing}
          dataScan={rowData}
        />
      ));
    }

    if (event.value === ACTION_REMOVE) {
      setModal(() => (
        <Modal
          onAgree={removeScan}
          title={`Remove ${dataPlexId}`}
          titlePosition='center'
          ModalBodyComponent={RemoveScanModalBody}
          agreeButtonText='Remove'
        />
      ));
    }
  };

  const onMonitors = () => {
    history.push({
      pathname: AppRoutes.Monitors.path,
      search: new URLSearchParams({ dataset, table }).toString(),
      state: { ...history.location.state, isShowBackToIncidentsBtn: true },
    });

    amplEvent(
      `${EVENT_CLICK} click table ${fullTableName} -> to Monitors page`
    );
  };

  const lastJobStatusIcon = useMemo(() => {
    if (
      rowData.lastJobState === JobStatuses.PENDING ||
      rowData.lastJobState === JobStatuses.CANCELING
    ) {
      return progressIcon;
    }

    if (
      rowData.lastJobState === JobStatuses.FAILED ||
      rowData.lastJobPassed === false
    ) {
      return errorIcon;
    }

    if (rowData.lastJobState === JobStatuses.SUCCEEDED) {
      return successIcon;
    }

    if (rowData.lastJobState === JobStatuses.CANCELLED) {
      return infoFullIcon;
    }

    return null;
  }, [rowData.lastJobState, rowData.lastJobPassed]);

  const navigateToScanPage = useCallback(() => {
    history.push(`${AppRoutes.DataQuality.path}/${id}`);
  }, [history, id]);

  return (
    <StyledTableRow>
      <StyledTableCell
        className='blueBoldText appLink'
        onClick={navigateToScanPage}
      >
        {dataPlexId}
      </StyledTableCell>

      <StyledTableCell className='greyText'>
        {lastJobStatusIcon !== null && (
          <img src={lastJobStatusIcon} alt='check' className='me-2' />
        )}
        {lastRunTime && moment(lastRunTime).fromNow()}
      </StyledTableCell>

      {isShowTableNameCell && (
        <StyledTableCell
          className={clsx('blueText appLink', isAnomalyTable && 'redText')}
          onClick={onMonitors}
        >
          {fullTableName}
        </StyledTableCell>
      )}

      <StyledTableCell className='greyText'>
        {scheduleCronToText}
      </StyledTableCell>

      {isShowEditCell && (
        <StyledTableCell>
          <RadioDropdown
            options={EDIT_RULE_DROPDOWN_ACTIONS}
            current={{ icon: PointsIcon }}
            onSelect={(event) => onFilterSelect(event)}
            bottom={'-24px'}
            right={'12px'}
          />
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export { DataQualityTable };
