import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import { TableCell, TableRow } from '@mui/material';
import {
  OverflownText,
  PipelineDetails,
  RightSideModal,
} from 'Components/components.js';
import {
  CostLabel,
  JobTagsTableCell,
  SourceTableCell,
} from 'Pages/Pipelines/components/components.js';
import { useModal } from 'context/context.js';
import { bytesToSize, millisecondsToTimeSlots } from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const { RESERVATION_WIDTH, PRINCIPAL_EMAIL_WIDTH } = PipelineTableCellStyles;

const ResourceTableSourceRaw = ({ sourceData, destinationTable }) => {
  const { setModal } = useModal();
  const { bqBillingData, currentBqBillingData } = useBqBillingData();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;

  const {
    jobHash,
    tables,
    sourceUri,
    destinationUri,
    principalEmail,
    jobTags,
    reservation,
    sourceProject,
    totalBytes,
    totalSlotsMs,
    costs,
    routines,
  } = sourceData;

  const sourcePaymentType = bqBillingData?.values?.find(
    (item) => item.project === sourceProject
  )?.paymentType;
  const isOnDemand = sourcePaymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const totalData = isOnDemand
    ? bytesToSize(totalBytes)
    : millisecondsToTimeSlots(totalSlotsMs);

  const sources = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  const onPipelineRawClick = () => {
    setModal(() => (
      <RightSideModal
        title='Pipeline details'
        subtitle={`Pipeline execution email: ${principalEmail}`}
        ModalBodyComponent={PipelineDetails}
        modalWidth={824}
        jobHash={jobHash}
        jobTags={jobTags}
        lineageData={{
          destinationTable,
          tables: sources,
          sourceUri,
          destinationUri,
          pipeline: {
            pipelineType: destinationTable.pipelineType,
            sourceProject: sourceProject,
          },
        }}
      />
    ));
  };

  return (
    <TableRow onClick={onPipelineRawClick}>
      <SourceTableCell sourceData={sources} sourceUri={sourceUri} />

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      {isExistingReservationCell && (
        <TableCell align='left' className='nowrapText'>
          {reservation?.length ? (
            <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
              {reservation}
            </OverflownText>
          ) : (
            <span className='txt-grey-13-500'>N/A</span>
          )}
        </TableCell>
      )}

      <TableCell align='right' className='nowrapText'>
        {sourceProject}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {totalData.length ? (
          totalData
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
      </TableCell>
    </TableRow>
  );
};

export { ResourceTableSourceRaw };
