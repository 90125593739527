import { TABLE_TYPES } from 'constants/constants.js';

const getWidgetLabel = (type = TABLE_TYPES.dbTable) => {
  const getValidType = () => {
    switch (type) {
      case TABLE_TYPES.view:
        return 'View';
      case TABLE_TYPES.external:
        return 'External table';
      default:
        return 'Table';
    }
  };

  const lowerCaseType = getValidType().toLowerCase();

  return {
    lineage: {
      title: 'Lineage',
      subtitle: 'Upstreams and downstream dependencies',
    },
    freshness: {
      title: 'Freshness',
      subtitle: `Frequency of ${lowerCaseType} update`,
    },
    volume: {
      title: 'Volume',
      subtitle: `Number of records incoming to ${lowerCaseType}`,
    },
    pipelineErrors: {
      title: 'Pipeline errors',
      subtitle: 'Any other related errors',
    },
    dependentReports: {
      title: 'Dependent reports',
      subtitle: 'Depending BI reports',
    },
    tableInfo: {
      title: `${getValidType()} Info`,
      subtitle: `${getValidType()}\`s metadata`,
    },
    schema: {
      title: 'Schema',
      subtitle: `Organization of data in terms of ${lowerCaseType}`,
    },
    dataScan: {
      title: 'Data quality scan',
      subtitle: 'List of data quality scans',
    },
    viewQuery: {
      title: 'View Query',
      subtitle: '',
    },
    lastRuns: {
      title: 'Last Runs',
      subtitle: '',
    },
    jobTags: {
      title: 'Pipeline tags / labels',
      subtitle: '',
    },
    pipelineIncidents: {
      title: 'Pipeline incidents',
      subtitle: 'Pipeline incidents affecting the table',
    },
  };
};

export { getWidgetLabel };
