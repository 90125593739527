import React from 'react';
import {
  useCallback,
  usePagination,
  usePopover,
  useMemo,
  useSort,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Table, TableBody } from '@mui/material';
import {
  Button,
  BUTTON_COLOR,
  TableHeadWithCheckbox,
  Popover,
  RightSideModal,
  Pagination,
} from 'Components/components.js';
import {
  SqlRowCheckModalBody,
  BuiltInRuleModalBody,
} from 'Pages/DataQuality/components/components.js';
import { useModal } from 'context/context.js';
import { RulesTable } from './components/components.js';
import {
  ACTION_BUILT_IN_RULE,
  ACTION_SQL,
  RULES_CREATION_DROPDOWN_ACTIONS,
} from 'Pages/DataQuality/libs/constants/constants.js';
import {
  ColumnName,
  RuleType,
  Dimension,
  Parameters,
  Threshold,
  EditCell,
} from './libs/enums/enums.js';
import { getComparator, stableSort } from 'utils/helpers/helpers.js';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';

const HEAD_CELLS = [
  ColumnName,
  RuleType,
  Dimension,
  Parameters,
  Threshold,
  EditCell,
];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: 24,
  },
}));

const RulesBlock = ({
  table = '',
  dataset = '',
  saveRules = () => {},
  editRule = () => {},
  removeRule = () => {},
  isDisabledAddRuleButton = false,
  isDisabledRemoveRuleButton = false,
  rules = [],
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { anchorEl, isOpenPopover, handleClose, handleChangePopover } =
    usePopover();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const { order, orderBy, handleRequestSort } = useSort(ColumnName.id);

  const sortedData = useMemo(() => {
    return stableSort(rules, getComparator(order, orderBy));
  }, [order, orderBy, rules]);

  const paginatedRules = useMemo(
    () =>
      sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortedData, page, rowsPerPage]
  );

  const handleSaveRules = useCallback(
    (rules) => {
      saveRules(rules);
      handleChangePage({}, 0);
    },
    [saveRules, handleChangePage]
  );

  const handleSaveRule = useCallback(
    (rule) => {
      saveRules([rule]);
      handleChangePage({}, 0);
    },
    [saveRules, handleChangePage]
  );

  const handleEditRule = useCallback(
    (rule) => {
      editRule(rule);
      handleChangePage({}, 0);
    },
    [editRule, handleChangePage]
  );

  const handleRemoveRule = useCallback(
    (rule) => {
      removeRule(rule);
      handleChangePage({}, 0);
    },
    [removeRule, handleChangePage]
  );

  const onFilterSelect = (value) => {
    if (value === ACTION_BUILT_IN_RULE) {
      handleClose();
      setModal(() => (
        <RightSideModal
          modalWidth={1380}
          title='Add Built-in rule'
          ModalBodyComponent={BuiltInRuleModalBody}
          mainBlockMargin={0}
          saveRules={handleSaveRules}
          table={table}
          dataset={dataset}
        />
      ));
    }

    if (value === ACTION_SQL) {
      handleClose();
      setModal(() => (
        <RightSideModal
          title='Add SQL row check rule'
          ModalBodyComponent={SqlRowCheckModalBody}
          mainBlockMargin={0}
          addNewRule={handleSaveRule}
          table={table}
          dataset={dataset}
        />
      ));
    }
  };

  return (
    <div className={clsx(classes.container)}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div className='txt-mainDark-16-700 mb-1'>Data quality rules</div>
          <div className='txt-grey-13-500'>
            Define and configure data quality validation rules
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            text='Add rules'
            color={BUTTON_COLOR.secondary}
            fullWidth={false}
            endIcon={<ArrowDown />}
            onClick={handleChangePopover}
            isDisabled={isDisabledAddRuleButton}
          />

          <Popover
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            {RULES_CREATION_DROPDOWN_ACTIONS.map((item) => {
              return (
                <div
                  key={item.value}
                  onClick={() => onFilterSelect(item.value)}
                >
                  {item.label}
                </div>
              );
            })}
          </Popover>
        </div>
      </div>

      {paginatedRules.length ? (
        <Table className='mt-4'>
          <TableHeadWithCheckbox
            onRequestSort={handleRequestSort}
            headCells={HEAD_CELLS}
            selectedColumn={false}
          />
          <TableBody>
            {paginatedRules.map((value) => (
              <RulesTable
                key={value.id}
                rowData={value}
                saveRule={handleEditRule}
                removeRule={handleRemoveRule}
                isDisabledRemoveRule={isDisabledRemoveRuleButton}
              />
            ))}
          </TableBody>
        </Table>
      ) : null}

      <Pagination
        count={rules.length}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export { RulesBlock };
