import React from 'react';
import { useAltPlanData, useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  CHIP_COLOR,
  CostsStatsWidget,
  StatsWidget,
} from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  PipelineTabs,
  DEAD_END_TABLES,
  DEAD_END_WIDGET_TIP,
  ALTERNATIVE_PLAN_WIDGET_TIP,
  ALTERNATIVE_PLAN_OPTION,
  COSTS_STATS_VIEW_TYPE,
} from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(6),
    alignItems: 'start',
  },
  largeStatsWidget: {
    gridColumn: (props) => props.largeGridItemPosition,
  },
}));

const CostsStats = ({
  costsStats,
  costsStatsPrevious,
  deadEndTablesStats,
  className = '',
  viewType = COSTS_STATS_VIEW_TYPE.table,
}) => {
  const { altPlanData } = useAltPlanData();
  const largeGridItemPosition =
    !deadEndTablesStats || !altPlanData
      ? !deadEndTablesStats && !altPlanData
        ? '1 / 5'
        : '1 / 4'
      : '1 / 3';

  const classes = useStyles({ largeGridItemPosition });

  const totalAmount = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const deadEndWidgetData = useMemo(() => {
    const deadEndCosts = deadEndTablesStats?.costs || 0;
    const percentageOfTotal = totalAmount
      ? ((deadEndCosts / totalAmount) * 100).toFixed(2)
      : 0;

    return {
      name: 'Dead-end tables',
      mainInfo: deadEndTablesStats?.total || 0,
      subname: 'Cost optimisation est.',
      info: `$${numberFormat(deadEndCosts)}` || '',
      chipLabel: `${percentageOfTotal}% savings`,
      chipColorType: CHIP_COLOR.error,
    };
  }, [deadEndTablesStats, totalAmount]);

  const alternativePlanWidgetData = useMemo(() => {
    const totalCosts = altPlanData?.costs || 0;
    const percentageOfTotal = totalAmount
      ? ((totalCosts / totalAmount) * 100).toFixed(2)
      : 0;

    return {
      name: 'Alternative plan savings',
      mainInfo: altPlanData?.total || 0,
      subname: 'Cost optimisation est.',
      info: `$${numberFormat(totalCosts)}` || '',
      chipLabel: `${percentageOfTotal}% savings`,
      chipColorType: CHIP_COLOR.success,
    };
  }, [altPlanData, totalAmount]);

  return (
    <div className={clsx(classes.gridContainer, className)}>
      <CostsStatsWidget
        costsStats={costsStats}
        costsStatsPrevious={costsStatsPrevious}
        className={classes.largeStatsWidget}
        viewType={viewType}
      />

      {deadEndTablesStats !== null && (
        <StatsWidget
          statsInfo={deadEndWidgetData}
          pathname={AppRoutes.Pipelines.path}
          search={{ tab: PipelineTabs.COSTS }}
          state={{
            filterPipelineEfficiencyIds: [DEAD_END_TABLES],
          }}
          tooltipTip={DEAD_END_WIDGET_TIP}
        />
      )}
      {altPlanData !== null && (
        <StatsWidget
          statsInfo={alternativePlanWidgetData}
          pathname={AppRoutes.Pipelines.path}
          search={{ tab: PipelineTabs.COSTS }}
          state={{
            filterPipelineEfficiencyIds: [ALTERNATIVE_PLAN_OPTION],
          }}
          tooltipTip={ALTERNATIVE_PLAN_WIDGET_TIP}
        />
      )}
    </div>
  );
};

export { CostsStats };
