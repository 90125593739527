const DEAD_END_WIDGET_TIP =
  'Dead-end table - is a table which does not have any downstream dependencies like a table or view, Looker dashboard, ' +
  'or is not queried by users from the BigQuery console. These tables are the final destination of transformations ' +
  'or extractions but are never used further.';

const ALTERNATIVE_PLAN_WIDGET_TIP =
  'Calculated as the difference between the total cost under the current plan and the potential cost under the alternative plan.';

const SUGGESTED_CRITICAL_TIP =
  'The score is based on the rating built on how important the table is in your system and how it impacts tables, views, ' +
  'and dashboards. It takes into account upstream and downstream dependencies, dashboards, external destinations, size, ' +
  'and frequency of updates.';

const ALTERNATIVE_PLAN_CELL =
  'For Editions, the alternative billing plan is On-Demand. The proposed On-Demand alternative is Editions Standard pricing.';

export {
  DEAD_END_WIDGET_TIP,
  ALTERNATIVE_PLAN_WIDGET_TIP,
  SUGGESTED_CRITICAL_TIP,
  ALTERNATIVE_PLAN_CELL,
};
