import {
  useEffect,
  useState,
  useCallback,
  useBqBillingData,
} from 'hooks/hooks.js';
import { PipelineDataVolume } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { JOB_METRIC_TYPES } from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { PipelineWidgets } from '../libs/constants/constants.js';

const PipelineCostDataVolume = ({ jobHash, onChangeLoadingWidget }) => {
  const { currentBqBillingData } = useBqBillingData();
  const { paymentType = BQ_BILLING_TYPE.FLAT_RATE } =
    currentBqBillingData || {};
  const [isLoadingVolumeData, setIsLoadingVolumeData] = useState(true);

  const metricType =
    paymentType === BQ_BILLING_TYPE.ON_DEMANDS
      ? JOB_METRIC_TYPES.bytes
      : JOB_METRIC_TYPES.slots;
  const widgetLabel =
    metricType === JOB_METRIC_TYPES.bytes
      ? 'Utilization by bytes'
      : 'Utilization by slot-ms';

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(
        PipelineWidgets.COST_DATA_VOLUME,
        isLoadingVolumeData
      );
    }
  }, [isLoadingVolumeData, onChangeLoadingWidget]);

  const handleChangeLoadingVolumeData = useCallback((value) => {
    setIsLoadingVolumeData(value);
  }, []);

  return (
    <Widget
      label={widgetLabel}
      secondaryLabel='See the trend for the past 60 data points'
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <PipelineDataVolume
        jobHash={jobHash}
        metricType={metricType}
        onLoadingVolumeDataChange={handleChangeLoadingVolumeData}
      />
    </Widget>
  );
};

export { PipelineCostDataVolume };
