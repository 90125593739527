import React from 'react';
import { useCallback, useDispatch, useGetIsAnomalyTable } from 'hooks/hooks.js';
import clsx from 'clsx';
import { getFullTableName, getColumnTypeIcon } from 'utils/helpers/helpers.js';
import { updateDesc } from 'slices/actions.js';
import {
  EditCell,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import {
  columnNameId,
  columnsTypeId,
  datasetId,
  descriptionId,
} from 'Components/Columns/HeadCellsEnum.js';

const DictionaryColumnDetails = ({ columnInfo, onMonitors, headCells }) => {
  const { name, table, dataset, description, type, uuid } = columnInfo;
  const dispatch = useDispatch();

  const { data: isAnomalyTable } = useGetIsAnomalyTable(dataset, table);
  const existHeadCell = (id) => !!headCells.find((it) => it.id === id);

  const updateColumnDesc = useCallback(
    (desc) => dispatch(updateDesc({ uuid, desc })),
    [dispatch, uuid]
  );

  return (
    <StyledTableRow key={uuid}>
      {existHeadCell(columnsTypeId) && (
        <StyledTableCell width={68}>
          <img src={getColumnTypeIcon(type)} alt='' />
        </StyledTableCell>
      )}

      {existHeadCell(columnNameId) && (
        <StyledTableCell className='darkBold'>{name}</StyledTableCell>
      )}

      {existHeadCell(descriptionId) && (
        <StyledTableCell>
          <EditCell value={description} onEdit={updateColumnDesc} />
        </StyledTableCell>
      )}

      {existHeadCell(datasetId) && (
        <StyledTableCell
          className={clsx('blueText', isAnomalyTable && 'redText')}
        >
          <span className='appLink' onClick={() => onMonitors(dataset, table)}>
            {getFullTableName(table, dataset)}
          </span>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export { DictionaryColumnDetails };
