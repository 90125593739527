import {
  useCallback,
  useMemo,
  usePipelineQuery,
  useState,
  useEffect,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { PositionLoader, QueryWidget } from 'Components/components.js';
import {
  LastRunsWidget,
  LineageWidget,
  PipelineCostDataVolume,
  RequestLineageWidget,
  PipelineFreshnessWidget,
  PipelineTagsWidget,
} from './components/components.js';
import { PIPELINE_TYPE } from 'utils/constants.js';
import { PipelineWidgets } from './libs/constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },

  visible: {
    display: 'flex',
  },

  hidden: {
    display: 'none',
  },
}));

const PipelineDetails = ({
  jobHash,
  jobTags = [],
  lineageData = null,
  pipelineType = null,
}) => {
  const classes = useStyles();
  const { query, isFetching } = usePipelineQuery(jobHash);

  const [isLoadingWidgets, setIsLoadingWidgets] = useState({
    [PipelineWidgets.COST_DATA_VOLUME]: null,
    [PipelineWidgets.FRESHNESS]: null,
    [PipelineWidgets.LAST_RUNS]: null,
    [PipelineWidgets.LINEAGE]: null,
    [PipelineWidgets.QUERY]: null,
  });

  useEffect(() => {
    setIsLoadingWidgets((prevState) => ({
      ...prevState,
      [PipelineWidgets.QUERY]: isFetching,
    }));
  }, [isFetching]);

  const handleChangeLoadingWidgets = useCallback((key, value) => {
    setIsLoadingWidgets((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const isLoading = useMemo(() => {
    return Object.values(isLoadingWidgets).some((item) => item === true);
  }, [isLoadingWidgets]);

  return (
    <div>
      <div className={clsx(isLoading ? classes.visible : classes.hidden)}>
        <PositionLoader />
      </div>

      <div
        className={clsx(
          classes.container,
          isLoading ? classes.hidden : classes.visible
        )}
      >
        {jobTags.length > 0 && <PipelineTagsWidget jobTags={jobTags} />}

        {pipelineType !== PIPELINE_TYPE.BROWSER && (
          <>
            <PipelineCostDataVolume
              jobHash={jobHash}
              onChangeLoadingWidget={handleChangeLoadingWidgets}
            />

            <PipelineFreshnessWidget
              jobHash={jobHash}
              onChangeLoadingWidget={handleChangeLoadingWidgets}
            />
          </>
        )}

        <LastRunsWidget
          jobHash={jobHash}
          onChangeLoadingWidget={handleChangeLoadingWidgets}
        />

        {lineageData ? (
          <LineageWidget
            destinationTable={lineageData.destinationTable}
            sourceTables={lineageData.tables}
            destinationUri={lineageData.destinationUri}
            sourceUri={lineageData.sourceUri}
            pipeline={lineageData.pipeline}
            onChangeLoadingWidget={handleChangeLoadingWidgets}
          />
        ) : (
          <RequestLineageWidget
            jobHash={jobHash}
            onChangeLoadingWidget={handleChangeLoadingWidgets}
          />
        )}

        <QueryWidget query={query} />
      </div>
    </div>
  );
};

export { PipelineDetails };
