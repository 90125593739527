import { useBqBillingData, useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext, useUserInfo } from 'context/context.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';

const useAltPlanData = () => {
  const { isCurrentProjectDemo } = useUserInfo();
  const { isWelcome } = useBqBillingData();
  const isAllowedSRequest = !isWelcome || isCurrentProjectDemo;
  const { startDateUtc, endDateUtc } = useDateContext();

  const { data } = useQuery(
    [QUERY_TYPES.altPaymentPlan, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled: isAllowedSRequest }
  );

  const altPlanData = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { altPlanData };
};

export { useAltPlanData };
