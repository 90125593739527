import React from 'react';
import {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Chart, ELEMENT_CLASS_NAME, ChartEvent, register } from '@antv/g2';
import { useStyles } from './Volume.style.js';
import {
  createFilteredDataTooltipItemName,
  createFilteredDataTooltipItemValue,
  getDatetimeFormat,
  getIntervalTitle,
} from './utils.js';
import {
  PositionLoader,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import {
  numberFormat,
  getUnixDateByStr,
  calcDeviation,
  getIncidentFormatData,
  getPercentString,
} from 'utils/helpers/helpers.js';
import { METRIC_TYPE } from 'constants/constants.js';
import {
  BRUSH_EVENT_TYPE,
  GRAPH_NAME,
  BRUSH_ACTION,
  MARK_TYPES,
  MARK_KEYS,
} from './constants.js';
import theme from 'theme.js';

const BASE_BLUE_COLOR = '#2F50FF';
const LINE_WIDTH = 2;
const MIN_DATA_LENGTH = 2;
const POINT_ANIMATION_TIME = 500;
const COORDINATE_GAP = 3;

register('shape.point.circle', (style, context) => {
  const { document } = context;
  return (P) => {
    const coordinates = P[0];

    const container = document.createElement('g', {});

    const dot = document.createElement('circle', {
      style: {
        cx: coordinates[0] + COORDINATE_GAP,
        cy: coordinates[1] + COORDINATE_GAP,
        r: 4,
        fill: BASE_BLUE_COLOR,
      },
    });

    const animateDot = document.createElement('circle', {
      style: {
        cx: coordinates[0] + COORDINATE_GAP,
        cy: coordinates[1] + COORDINATE_GAP,
        r: 15,
        fill: BASE_BLUE_COLOR,
        fillOpacity: 0.5,
        opacity: 0.5,
      },
    });

    animateDot.animate(
      [
        { transform: 'scale(0.1, 0.1)', opacity: 0.0, fillOpacity: 0.0 },
        { opacity: 0.8, fillOpacity: 0.8 },
        { transform: 'scale(1.0, 1.0)', opacity: 0.0, fillOpacity: 0.0 },
      ],
      {
        duration: 1800,
        easing: 'easeLinear',
        iterations: Infinity,
      }
    );

    container.appendChild(dot);
    container.appendChild(animateDot);

    return container;
  };
});

const Volume = ({
  currentDatetime = null,
  volumeData,
  isFetchingVolumeData = false,
  frequencyMilliseconds,
  withScatterGraph = false,
  scatterData = [],
  isFetchingScatterData = false,
  isShownScatterGraph = false,
  isDestroyingGraph = false,
  metricType = null,
  percentThreshold = 0,
  customPercentThreshold = null,
  volumeGraphWidth = null,
}) => {
  const classes = useStyles();
  const volumeContainerRef = useRef(null);
  const volumeRef = useRef(null);
  const scatterContainerRef = useRef(null);
  const scatterRef = useRef(null);
  const [brushActionInfo, setBrushActionInfo] = useState(BRUSH_ACTION.zoomIn);

  const metricTypeTitle = useMemo(() => {
    switch (metricType) {
      case METRIC_TYPE.BILLED_BYTES:
        return 'Bytes, consumed';
      case METRIC_TYPE.SLOTS_MS:
        return 'Slots ms, consumed';
      case METRIC_TYPE.DURATION:
        return 'Milliseconds, duration';
      default:
        return '';
    }
  }, [metricType]);

  const freshnessAnomalyData = useMemo(() => {
    if (!volumeData.length) {
      return null;
    }
    return volumeData.filter((item) => item.isFreshness);
  }, [volumeData]);

  const scatterDataWithFreshness = useMemo(() => {
    if (
      !scatterData.length ||
      !freshnessAnomalyData ||
      frequencyMilliseconds === null
    ) {
      return [];
    }
    return [
      ...scatterData,
      ...freshnessAnomalyData.map((item) => ({
        datetime: getUnixDateByStr(item.datetime) + frequencyMilliseconds / 2,
        value: null,
        isFreshness: true,
      })),
    ].sort((a, b) => {
      return getUnixDateByStr(a.datetime) - getUnixDateByStr(b.datetime);
    });
  }, [scatterData, freshnessAnomalyData, frequencyMilliseconds]);

  useEffect(() => {
    return () => {
      if (volumeRef.current) {
        volumeRef.current.destroy();
        volumeRef.current = null;
      }
      if (scatterRef.current) {
        scatterRef.current.destroy();
        scatterRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (volumeRef.current && isDestroyingGraph) {
      volumeRef.current.destroy();
      volumeRef.current = null;
      scatterRef.current.destroy();
      scatterRef.current = null;
    }
  }, [isDestroyingGraph]);

  const volumeYAxisValues = useMemo(() => {
    if (volumeData.length > 0) {
      const maxDataValue = Math.max(...volumeData.map((item) => item.value));
      const minDataValue = Math.min(...volumeData.map((item) => item.value));
      const maxYAxis = Math.floor(maxDataValue + maxDataValue * 0.05);
      const minYAxis = Math.floor(minDataValue);

      return [minYAxis, maxYAxis];
    }

    return [0, 0];
  }, [volumeData]);

  const updateVolumeGraph = useCallback((newData, isFullVolumeGraph) => {
    const options = volumeRef.current.options();
    const actionText = isFullVolumeGraph
      ? BRUSH_ACTION.zoomIn
      : BRUSH_ACTION.zoomOut;

    setBrushActionInfo(actionText);

    options.children = options.children.map((item) => {
      if (item?.encode?.key === MARK_KEYS.lineDash) {
        if (!isFullVolumeGraph) {
          const lastDatetime = newData.at(-1)?.datetime;
          const lastLineDashDatetime = item.data.at(-1)?.datetime;
          if (
            lastDatetime &&
            lastLineDashDatetime &&
            lastDatetime !== lastLineDashDatetime
          ) {
            item.data = [];
          }
        } else if (item?.encode?.defaultData?.length) {
          item.data = item.encode.defaultData;
        }
      } else if (item?.type === MARK_TYPES.point) {
        if (!isFullVolumeGraph) {
          const pointDatetime = item.encode?.x;
          const firstDatetimeStr = newData.at(0)?.datetime;
          const lastDatetimeStr = newData.at(-1)?.datetime;
          if (firstDatetimeStr && lastDatetimeStr && pointDatetime) {
            const firstDatetime = new Date(firstDatetimeStr);
            const lastDatetime = new Date(lastDatetimeStr);
            if (pointDatetime < firstDatetime || pointDatetime > lastDatetime) {
              item.encode.x = null;
            }
          }
        } else if (item?.encode?.defaultX) {
          item.encode.x = item.encode.defaultX;
        }
      }

      return item;
    });

    volumeRef.current.options({
      ...options,
      data: newData,
    });

    volumeRef.current.render();
  }, []);

  const clearSelectedPointGraph = () => {
    const options = volumeRef.current.options();
    options.children = options.children.filter((item) => {
      return item.type !== MARK_TYPES.point;
    });
    options.title = undefined;

    volumeRef.current.options({
      ...options,
    });

    volumeRef.current.render();
  };

  const setPointShape = (selectedVolumePoint) => {
    const { datetime, value } = selectedVolumePoint;

    if (value === null) {
      return;
    }

    const options = volumeRef.current.options();
    options.children = options.children.filter((item) => {
      return item.type !== MARK_TYPES.point;
    });

    volumeRef.current.options({
      ...options,
    });

    volumeRef.current.title({
      size: 12,
      subtitle: `Selected value: ${numberFormat(value)}`,
      style: {
        align: 'right',
        subtitleFill: theme.palette.text.primary,
        subtitleFillOpacity: 1,
      },
    });

    const defaultX = new Date(datetime);
    volumeRef.current
      .point()
      .encode('defaultX', defaultX)
      .encode('x', defaultX)
      .encode('y', value)
      .encode('size', 5)
      .encode('shape', MARK_TYPES.point)
      .animate('enter', { type: 'zoomIn', duration: POINT_ANIMATION_TIME })
      .animate('exit', { type: 'zoomOut', duration: POINT_ANIMATION_TIME })
      .tooltip(false);

    volumeRef.current.render();
  };

  const setLineShape = (isRendering = true) => {
    const { y: scaleY } = volumeRef.current.getScale();
    const [scaleYStart, scaleYEnd] = scaleY?.getOptions().domain;

    const filteredMaxValues = volumeData
      .filter((item) => item.maxFiltered !== null)
      .map((item) => item.maxFiltered.value);

    const filteredMinValues = volumeData.filter(
      (item) => item.minFiltered !== null
    );

    const maxFilteredValue = Math.max(...filteredMaxValues);
    const minFilteredValue = Math.min(...filteredMaxValues);

    const existingRange = maxFilteredValue > scaleYEnd + scaleYEnd * 0.3;

    const maxYAxis = existingRange ? Math.floor(maxFilteredValue) : scaleYEnd;
    const minYAxis = existingRange ? Math.floor(minFilteredValue) : scaleYStart;

    if (filteredMaxValues.length) {
      volumeRef.current
        .line()
        .encode('key', MARK_KEYS.filteredMaxValues)
        .encode('x', ({ datetime }) => new Date(datetime))
        .encode('y', ({ maxFiltered }) => maxFiltered?.value)
        .encode('color', BASE_BLUE_COLOR)
        .scale('y', {
          independent: true,
          domain: [minYAxis, maxYAxis],
          range: existingRange ? [0.1, 0] : [1, 0],
        })
        .style('lineWidth', 1)
        .style('lineDash', [8, 4])
        .style('opacity', 0.5)
        .axis('y', {
          position: 'right',
          title: 'Custom bar',
          grid: null,
        })
        .tooltip({
          title: null,
          items: [
            ({ minFiltered, maxFiltered }) => ({
              name: createFilteredDataTooltipItemName(minFiltered, maxFiltered),
              value: createFilteredDataTooltipItemValue(
                minFiltered,
                maxFiltered,
                metricType
              ),
            }),
          ],
        });
    }

    if (filteredMinValues.length) {
      volumeRef.current
        .line()
        .encode('key', MARK_KEYS.filteredMinValues)
        .encode('x', ({ datetime }) => new Date(datetime))
        .encode('y', ({ minFiltered }) =>
          minFiltered?.value < 0 ? 0 : minFiltered?.value
        )
        .encode('color', BASE_BLUE_COLOR)
        .scale('y', {})
        .style('lineWidth', 1)
        .style('lineDash', [8, 4])
        .style('opacity', 0.5)
        .tooltip({
          title: null,
          items: [
            ({ minFiltered, maxFiltered }) => {
              return {
                name: createFilteredDataTooltipItemName(
                  minFiltered,
                  maxFiltered
                ),
                value: createFilteredDataTooltipItemValue(
                  minFiltered,
                  maxFiltered,
                  metricType
                ),
              };
            },
          ],
        });
    }

    if (isRendering && (filteredMaxValues.length || filteredMinValues.length)) {
      volumeRef.current.render();
    }
  };

  const getPointShape = () => {
    const options = volumeRef.current.options();
    const pointMark = options.children.find(
      (item) => item.type === MARK_TYPES.point
    );

    if (pointMark) {
      return { datetime: pointMark.encode.x, value: pointMark.encode.y };
    }

    return null;
  };

  const updateScatterHighlight = useCallback(
    (startPeriodStr) => {
      const startPeriodUnix = getUnixDateByStr(startPeriodStr);
      const endPeriodUnix = startPeriodUnix + frequencyMilliseconds;
      const selectedData = getPointShape();

      const startSelectedPeriodUnix = selectedData?.datetime?.getTime();
      const endSelectedPeriodUnix =
        startSelectedPeriodUnix + frequencyMilliseconds;

      const { canvas } = scatterRef.current.getContext();
      const elements =
        canvas.document.getElementsByClassName(ELEMENT_CLASS_NAME);

      for (const element of elements) {
        if (
          element.markType === MARK_TYPES.line ||
          element.markType === MARK_TYPES.rangeX
        ) {
          continue;
        }

        const rawDataPointTime = element.__data__.key.substring(
          0,
          element.__data__.key.indexOf('-')
        );
        const rawDataPointTimeUnix = getUnixDateByStr(rawDataPointTime);
        if (
          selectedData &&
          rawDataPointTimeUnix >= startSelectedPeriodUnix &&
          rawDataPointTimeUnix < endSelectedPeriodUnix
        ) {
          continue;
        }
        if (!startPeriodStr) {
          element.style.fill = theme.palette.divider;
        } else {
          if (
            rawDataPointTimeUnix >= startPeriodUnix &&
            rawDataPointTimeUnix < endPeriodUnix
          ) {
            element.style._fill = element.style.fill;
            element.style.fill = theme.palette.primary.main;
          } else {
            element.style._fill = element.style.fill;
            element.style.fill = theme.palette.divider;
          }
        }
      }
    },
    [frequencyMilliseconds]
  );

  const updateScatterSelected = useCallback(
    (eventData, isSamePoint = false) => {
      const startPeriodUnix = getUnixDateByStr(eventData.datetime);
      const endPeriodUnix = startPeriodUnix + frequencyMilliseconds;
      const pointShapeDatetime = getPointShape();
      if (
        !isSamePoint &&
        pointShapeDatetime &&
        pointShapeDatetime?.datetime.getTime() === startPeriodUnix
      ) {
        clearSelectedPointGraph();

        return;
      }
      if (!isSamePoint) {
        setPointShape(eventData);
      }

      const { canvas } = scatterRef.current.getContext();
      const elements =
        canvas.document.getElementsByClassName(ELEMENT_CLASS_NAME);

      for (const element of elements) {
        if (
          element.markType === MARK_TYPES.line ||
          element.markType === MARK_TYPES.rangeX
        ) {
          continue;
        }

        const rawDataPointTime = element.__data__.key.substring(
          0,
          element.__data__.key.indexOf('-')
        );
        const rawDataPointTimeUnix = getUnixDateByStr(rawDataPointTime);

        if (
          rawDataPointTimeUnix >= startPeriodUnix &&
          rawDataPointTimeUnix < endPeriodUnix
        ) {
          element.style._fill = element.style.fill;
          element.style.fill = theme.palette.primary.main;
        } else {
          element.style._fill = element.style.fill;
          element.style.fill = theme.palette.divider;
        }
      }
    },
    [frequencyMilliseconds]
  );

  // Updates
  useEffect(() => {
    if (volumeRef.current && currentDatetime) {
      const options = volumeRef.current.options();
      options.children = options.children.filter((item) => {
        return (
          item.type !== MARK_TYPES.point &&
          item.encode?.key !== MARK_KEYS.filteredMinValues &&
          item.encode?.key !== MARK_KEYS.filteredMaxValues
        );
      });
      volumeRef.current.options({
        ...options,
      });

      volumeRef.current
        .point()
        .encode('x', new Date(currentDatetime))
        .encode('y', (datum) =>
          datum.datetime === currentDatetime ? datum.value : null
        )
        .encode('shape', 'circle')
        .tooltip(false);

      setLineShape(false);

      volumeRef.current.render();
    }
  }, [currentDatetime]);

  useEffect(() => {
    if (volumeRef.current && customPercentThreshold !== null) {
      const options = volumeRef.current.options();
      volumeRef.current.options({
        ...options,
      });

      volumeRef.current.data(volumeData);

      volumeRef.current.render();
    }
  }, [customPercentThreshold, volumeData]);

  const isReadyChartsForRender = useMemo(() => {
    if (withScatterGraph) {
      return (
        volumeData.length > 0 &&
        scatterDataWithFreshness.length > 0 &&
        !scatterRef.current &&
        !volumeRef.current &&
        frequencyMilliseconds !== null
      );
    }

    return (
      volumeData.length > 0 &&
      !volumeRef.current &&
      frequencyMilliseconds !== null
    );
  }, [
    volumeData,
    frequencyMilliseconds,
    withScatterGraph,
    scatterDataWithFreshness,
  ]);

  // First render
  useEffect(() => {
    if (!isReadyChartsForRender) {
      return;
    }
    volumeRef.current = new Chart({
      container: volumeContainerRef.current,
      width: volumeGraphWidth !== null && volumeGraphWidth,
      autoFit: true,
      paddingTop: 6,
      paddingBottom: 6,
      theme: {
        color: BASE_BLUE_COLOR,
      },
    });

    volumeRef.current
      .data(volumeData)
      .encode('x', ({ datetime }) => new Date(datetime))
      .encode('y', 'value')
      .axis('x', {
        labelAutoRotate: false,
        labelFormatter: (data) => getDatetimeFormat(data),
        line: true,
        grid: null,
      })
      .axis('y', {
        title: metricType ? metricTypeTitle : false,
        labelFormatter: '~s',
        gridLineDash: null,
        gridStrokeWidth: 1,
        gridStroke: theme.palette.divider,
        gridStrokeOpacity: 0.75,
      })
      .scale('y', { nice: true, domain: volumeYAxisValues });

    volumeRef.current
      .line()
      .style('lineWidth', LINE_WIDTH)
      .tooltip({
        title: ({ datetime }) => getDatetimeFormat(datetime),
        items: [
          ({ value }) => ({
            name: 'value',
            value: getIncidentFormatData(metricType, value),
          }),

          ({ interval }) => ({
            name: 'interval',
            value: getIntervalTitle(interval, metricType),
          }),

          ({ value, interval, isAnomaly }) => {
            if (!isAnomaly) {
              return {
                name: ' ',
                value: '',
                color: 'transparent',
              };
            }
            const deviation = calcDeviation(
              value,
              interval.at(0),
              interval.at(-1)
            );

            if (deviation === 0) {
              return {
                name: ' ',
                value: '',
                color: 'transparent',
              };
            }

            return {
              name: 'deviation',
              value: getPercentString(deviation),
              color: theme.palette.error.main,
            };
          },
        ],
      });

    if (volumeData.length >= MIN_DATA_LENGTH) {
      const lineDashData = volumeData.slice(Math.max(volumeData.length - 2, 0));
      volumeRef.current
        .line()
        .data(lineDashData)
        .encode('key', MARK_KEYS.lineDash)
        .encode('defaultData', lineDashData)
        .style({
          lineWidth: LINE_WIDTH,
          stroke: theme.palette.common.white,
          color: BASE_BLUE_COLOR,
          lineDash: [5, 7],
        })
        .tooltip(false);
    }

    volumeRef.current
      .area()
      .encode('y', 'interval')
      .style('fillOpacity', 0.1)
      .tooltip(false);

    volumeRef.current
      .rangeX()
      .encode('x', (data) => {
        const startInterval =
          getUnixDateByStr(data.datetime) - frequencyMilliseconds / 2;

        return new Date(startInterval);
      })
      .encode('x1', (data) => {
        const endInterval =
          getUnixDateByStr(data.datetime) + frequencyMilliseconds / 2;

        return new Date(endInterval);
      })
      .style({
        fill: theme.palette.error.main,
        fillOpacity: ({ isAnomaly }) => (isAnomaly ? 0.1 : 0),
      });

    if (currentDatetime) {
      volumeRef.current
        .point()
        .encode('x', new Date(currentDatetime))
        .encode('y', (datum) =>
          datum.datetime === currentDatetime ? datum.value : null
        )
        .encode('shape', 'circle')
        .tooltip(false);
    }

    volumeRef.current.interaction('tooltip', {
      marker: false,
    });

    volumeRef.current.on(`element:${ChartEvent.POINTER_MOVE}`, (event) => {
      if (event.data.data && event.data.data.value === null) {
        volumeRef.current.emit('tooltip:disable');
      } else {
        volumeRef.current.emit('tooltip:enable');
      }
    });

    if (withScatterGraph) {
      scatterRef.current = new Chart({
        container: scatterContainerRef.current,
        autoFit: true,
        paddingTop: 0,
        paddingBottom: 6,
        theme: {
          color: theme.palette.divider,
        },
      });

      scatterRef.current
        .data(scatterDataWithFreshness)
        .encode('x', ({ datetime }) => new Date(datetime))
        .encode('y', 'value')
        .axis('x', {
          labelAutoRotate: false,
          labelFormatter: (data) => getDatetimeFormat(data),
          line: true,
          grid: null,
        })
        .axis('y', {
          title: false,
          labelFormatter: '~s',
          gridLineDash: null,
          gridStrokeWidth: 1,
          gridStroke: theme.palette.divider,
          gridStrokeOpacity: 0.75,
        })
        .scale('y', { nice: true });

      scatterRef.current
        .line()
        .encode('color', theme.palette.primary.main)
        .style({ lineWidth: 0 })
        .tooltip({
          title: ({ datetime, isFreshness }) => {
            if (isFreshness) {
              const unixDatetime = getUnixDateByStr(datetime);
              const from = unixDatetime - frequencyMilliseconds / 2;
              const to = unixDatetime + frequencyMilliseconds / 2;

              return `${getDatetimeFormat(from)} - ${getDatetimeFormat(to)}`;
            }
            return getDatetimeFormat(datetime);
          },
          items: [
            ({ value }) => ({
              name: 'raw value',
              value: numberFormat(value),
            }),
          ],
        });

      scatterRef.current
        .point()
        .encode('size', 2)
        .encode('shape', MARK_TYPES.point)
        .tooltip(false);

      if (freshnessAnomalyData?.length) {
        scatterRef.current
          .rangeX()
          .data(freshnessAnomalyData)
          .encode('x', (data) => new Date(data.datetime))
          .encode('x1', (data) => {
            const endInterval =
              getUnixDateByStr(data.datetime) + frequencyMilliseconds;

            return new Date(endInterval);
          })
          .style({
            fill: theme.palette.error.main,
            fillOpacity: 0.1,
          });
      }

      scatterRef.current.interaction({
        brushXFilter: true,
      });

      scatterRef.current.on('brush:filter', (event) => {
        const { selection } = event.data;
        const zoomScatterDatetime = selection.at(0);

        if (!zoomScatterDatetime.length) {
          return;
        }

        const scatterMinDatetime = getUnixDateByStr(zoomScatterDatetime.at(0));
        const scatterMaxDatetime = getUnixDateByStr(zoomScatterDatetime.at(-1));

        const zoomData = volumeData.filter((item) => {
          const startPeriodUnix = getUnixDateByStr(item.datetime);
          const endPeriodUnix = startPeriodUnix + frequencyMilliseconds;

          if (
            scatterMinDatetime >= startPeriodUnix &&
            scatterMinDatetime < endPeriodUnix
          ) {
            return true;
          }

          if (
            startPeriodUnix > scatterMinDatetime &&
            startPeriodUnix <= scatterMaxDatetime
          ) {
            return true;
          }

          return false;
        });

        const isFullVolumeGraph = event.type !== BRUSH_EVENT_TYPE;

        updateVolumeGraph(zoomData, isFullVolumeGraph);
        scatterRef.current.emit('brush:remove', {});
      });
      scatterRef.current.on('brush:remove', () => {
        setTimeout(() => {
          const currentSelectedData = getPointShape();
          if (currentSelectedData) {
            updateScatterSelected(currentSelectedData, true);
          }
        }, 600);
      });

      volumeRef.current.on(`element:${ChartEvent.POINTER_MOVE}`, (event) => {
        if (event.data.data) {
          const startPeriod = event.data.data.datetime;
          updateScatterHighlight(startPeriod);
        }
      });
      volumeRef.current.on('tooltip:hide', () => {
        updateScatterHighlight(null);
      });
      volumeRef.current.on('element:click', (event) => {
        const eventData = event.data.data;

        if (eventData) {
          updateScatterSelected(eventData);
        }
      });

      scatterRef.current.render();
    }

    volumeRef.current.render().then(() => {
      setLineShape();
    });
  }, [withScatterGraph, isReadyChartsForRender]);

  if (isFetchingVolumeData || isFetchingScatterData) {
    return <PositionLoader />;
  }

  return (
    <>
      {isShownScatterGraph && (
        <Typography color={TYPOGRAPHY_COLOR.textSecondary} sx={{ ml: 4 }}>
          {GRAPH_NAME.volume}
        </Typography>
      )}

      <div className={classes.volumeGraphContainer}>
        <div
          ref={volumeContainerRef}
          className={clsx(classes.graph, 'volume')}
        />

        {percentThreshold !== 0 ? (
          <div className='greyContainer txt-grey-13-700'>
            Threshold coefficient: {percentThreshold}%
          </div>
        ) : null}
      </div>

      {isShownScatterGraph && (
        <>
          <Typography color={TYPOGRAPHY_COLOR.textSecondary} sx={{ ml: 4 }}>
            {GRAPH_NAME.scatter}
          </Typography>
          <div
            ref={scatterContainerRef}
            className={clsx(classes.graph, 'scatter')}
          />
          <Typography
            variant={TYPOGRAPHY_VARIANT.body2}
            color={TYPOGRAPHY_COLOR.textSecondary}
            className={classes.actionInfo}
            sx={{ mt: 1 }}
          >
            {brushActionInfo}
          </Typography>
        </>
      )}
    </>
  );
};

export { Volume };
