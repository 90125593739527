import React from 'react';
import { useBooleanToggle } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { TableInfo } from './TableInfo.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const TableInfoWidget = ({ tableInfo }) => {
  const { tableInfo: tableInfoWidget } = getWidgetLabel(tableInfo?.type);

  const {
    booleanState: existingDivider,
    handleChangeBooleanState: handleChangeExistingDivider,
  } = useBooleanToggle(true);

  return (
    <Widget
      label={tableInfoWidget.title}
      secondaryLabel={tableInfoWidget.subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      existingDivider={existingDivider}
    >
      <TableInfo
        {...tableInfo}
        onChangeExistingData={handleChangeExistingDivider}
      />
    </Widget>
  );
};

export { TableInfoWidget };
