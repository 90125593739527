import { useMemo } from 'hooks/hooks.js';
import { numberFormat } from 'utils/helpers/helpers.js';

const CostLabel = ({ value }) => {
  const cost = useMemo(() => {
    if (!value) {
      return '< $0.01';
    }

    return `$${numberFormat(value)}`;
  }, [value]);

  return <div>{cost}</div>;
};

export { CostLabel };
