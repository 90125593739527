import React from 'react';
import {
  useLocation,
  useState,
  useEffect,
  useMemo,
  useHistory,
} from 'hooks/hooks.js';
import { StyledDropdown } from 'Components/components.js';
import {
  filterAlertStateOptions,
  filterLookerAlertStateOptions,
  filterStatusStateOptions,
} from 'Pages/Incidents/libs/helpers/helpers.js';
import {
  INCIDENT_FILTER_OPTIONS,
  SHORT_INCIDENT_FILTER,
  STATUS_FILTER_OPTIONS,
  LOOKER_STATUS_FILTER_OPTIONS,
} from 'constants/constants.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';
import {
  INCIDENT_STATUS_FILTER,
  INCIDENT_TYPE_FILTER,
  LOOKER_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  TABLE_ALERT_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
  TECHNOLOGY_FILTER,
  LOOKER_INCIDENT_TYPE_FILTER,
} from 'Pages/Incidents/libs/constants/constants.js';
import { RegularMiniIcon } from 'assets/img/alert';
import { StatusIcon } from 'assets/img/dropdownIcons';
import { ReactComponent as TechnologyIcon } from 'assets/img/icons/tech.svg';

const getAfterLabelAlertText = (filterValue, filterOption, entity) =>
  filterValue === filterOption ? ` of ${entity}` : ` ${entity}`;

const Dropdowns = ({
  onChangeDropdownsValue = () => {},
  existingDropdown = [],
  defaultPipelineIncidentTypeOptions = [],
  technologyFilterOptions = [],
}) => {
  const location = useLocation();
  const history = useHistory();

  const filterOptions = filterStatusStateOptions(
    location.state?.filterIds !== undefined ? location.state?.filterIds : []
  );
  const filterAlertOptions = filterAlertStateOptions(
    location.state?.filterAlertIds !== undefined
      ? location.state?.filterAlertIds
      : []
  );
  const filterLookerAlertOptions = filterLookerAlertStateOptions(
    location.state?.filterLookerAlertIds !== undefined
      ? location.state?.filterLookerAlertIds
      : []
  );

  const [dropdownsValue, setDropdownsValue] = useState({
    [INCIDENT_TYPE_FILTER]: [STATUS_FILTER_OPTIONS[0]],
    [LOOKER_INCIDENT_TYPE_FILTER]: [LOOKER_STATUS_FILTER_OPTIONS[0]],
    [PIPELINE_INCIDENT_TYPE_FILTER]: [defaultPipelineIncidentTypeOptions[0]],
    [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[1], ALERT_FILTER_OPTIONS[2]],
    [LOOKER_ALERT_FILTER]: filterLookerAlertOptions.length
      ? filterLookerAlertOptions
      : [ALERT_FILTER_OPTIONS[0]],
    [TECHNOLOGY_FILTER]: [technologyFilterOptions[0]],
    [INCIDENT_STATUS_FILTER]: [INCIDENT_FILTER_OPTIONS[0]],
    [INCIDENT_STATUS_SHORT_FILTER]: [SHORT_INCIDENT_FILTER[0]],
  });

  const afterLabelText = {
    looker: getAfterLabelAlertText(
      dropdownsValue[LOOKER_ALERT_FILTER][0].value,
      ALERT_FILTER_OPTIONS[0].value,
      'dashboards'
    ),
    gbq: getAfterLabelAlertText(
      dropdownsValue[TABLE_ALERT_FILTER][0].value,
      ALERT_FILTER_OPTIONS[0].value,
      'tables'
    ),
  };

  const handleChangeDropdowns = (key, value) => {
    setDropdownsValue((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    if (filterAlertOptions.length) {
      handleChangeDropdowns(TABLE_ALERT_FILTER, filterAlertOptions);
      delete location.state.filterAlertIds;
      history.replace({ ...history.location, state: location.state });
    }
  }, [filterAlertOptions, history, location.state]);

  useEffect(() => {
    if (filterOptions.length) {
      handleChangeDropdowns(INCIDENT_TYPE_FILTER, filterOptions);
      delete location.state.filterIds;
      history.replace({ ...history.location, state: location.state });
    }
  }, [filterOptions, history, location.state]);

  const filteredDropdownsValue = useMemo(() => {
    return Object.keys(dropdownsValue)
      .filter((key) => existingDropdown.includes(key))
      .reduce((obj, key) => {
        obj[key] = dropdownsValue[key];
        return obj;
      }, {});
  }, [dropdownsValue, existingDropdown]);

  useEffect(() => {
    onChangeDropdownsValue(filteredDropdownsValue);
  }, [filteredDropdownsValue, onChangeDropdownsValue]);

  return (
    <div>
      {existingDropdown.includes(LOOKER_ALERT_FILTER) && (
        <StyledDropdown
          options={ALERT_FILTER_OPTIONS}
          defaultValue={dropdownsValue[LOOKER_ALERT_FILTER]}
          Icon={RegularMiniIcon}
          onSelect={(value) =>
            handleChangeDropdowns(LOOKER_ALERT_FILTER, value)
          }
          selectAllOption={ALERT_FILTER_OPTIONS[0]}
          afterLabel={afterLabelText.looker}
        />
      )}

      {existingDropdown.includes(TABLE_ALERT_FILTER) && (
        <StyledDropdown
          options={ALERT_FILTER_OPTIONS}
          defaultValue={dropdownsValue[TABLE_ALERT_FILTER]}
          Icon={RegularMiniIcon}
          onSelect={(value) => handleChangeDropdowns(TABLE_ALERT_FILTER, value)}
          selectAllOption={ALERT_FILTER_OPTIONS[0]}
          afterLabel={afterLabelText.gbq}
        />
      )}

      {existingDropdown.includes(TECHNOLOGY_FILTER) && (
        <StyledDropdown
          options={technologyFilterOptions}
          defaultValue={dropdownsValue[TECHNOLOGY_FILTER]}
          Icon={TechnologyIcon}
          onSelect={(value) => handleChangeDropdowns(TECHNOLOGY_FILTER, value)}
          selectAllOption={technologyFilterOptions[0]}
          multipleSelect={false}
        />
      )}

      {existingDropdown.includes(INCIDENT_TYPE_FILTER) && (
        <StyledDropdown
          options={STATUS_FILTER_OPTIONS}
          defaultValue={dropdownsValue[INCIDENT_TYPE_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(INCIDENT_TYPE_FILTER, value)
          }
          selectAllOption={STATUS_FILTER_OPTIONS[0]}
        />
      )}

      {existingDropdown.includes(LOOKER_INCIDENT_TYPE_FILTER) && (
        <StyledDropdown
          options={LOOKER_STATUS_FILTER_OPTIONS}
          defaultValue={dropdownsValue[LOOKER_INCIDENT_TYPE_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(LOOKER_INCIDENT_TYPE_FILTER, value)
          }
          selectAllOption={LOOKER_STATUS_FILTER_OPTIONS[0]}
        />
      )}

      {existingDropdown.includes(PIPELINE_INCIDENT_TYPE_FILTER) && (
        <StyledDropdown
          options={defaultPipelineIncidentTypeOptions}
          defaultValue={dropdownsValue[PIPELINE_INCIDENT_TYPE_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(PIPELINE_INCIDENT_TYPE_FILTER, value)
          }
          selectAllOption={defaultPipelineIncidentTypeOptions[0]}
          multipleSelect={true}
        />
      )}

      {existingDropdown.includes(INCIDENT_STATUS_FILTER) && (
        <StyledDropdown
          options={INCIDENT_FILTER_OPTIONS}
          defaultValue={dropdownsValue[INCIDENT_STATUS_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(INCIDENT_STATUS_FILTER, value)
          }
          afterLabel=' incidents'
        />
      )}

      {existingDropdown.includes(INCIDENT_STATUS_SHORT_FILTER) && (
        <StyledDropdown
          options={SHORT_INCIDENT_FILTER}
          defaultValue={dropdownsValue[INCIDENT_STATUS_SHORT_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(INCIDENT_STATUS_SHORT_FILTER, value)
          }
          afterLabel=' incidents'
        />
      )}
    </div>
  );
};

export { Dropdowns };
