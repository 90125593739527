import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { ResourceStatsWidget } from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(6),
  },

  largeStatsWidget: {
    width: '60%',
  },

  smallStatsWidget: {
    width: '40%',
  },
}));

const ResourceStats = ({ selectedTag, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.largeStatsWidget}>
        <ResourceStatsWidget />
      </div>

      <div className={classes.smallStatsWidget}>
        <ResourceStatsWidget selectedTag={selectedTag} />
      </div>
    </div>
  );
};

export { ResourceStats };
