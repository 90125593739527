import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useDashboardData = () => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching: isDashboardDataFetching, data } = useQuery(
    [QUERY_TYPES.dashboard, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled: !!startDateUtc && !!endDateUtc, refetchOnMount: 'always' }
  );

  const dashboardData = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { dashboardData, isDashboardDataFetching };
};

export { useDashboardData };
