import React, { createContext } from 'react';
import {
  useContext,
  useHistory,
  useLocation,
  useMemo,
  useState,
} from 'hooks/hooks.js';
import moment from 'moment';
import {
  convertLocalToUTCDate,
  defaultEndDate,
  defaultStartDate,
} from 'utils/helpers/helpers.js';

const DateContext = createContext(null);

const DateContextProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState(
    location.state?.startDate || defaultStartDate()
  );
  const [endDate, setEndDate] = useState(
    location.state?.endDate || defaultEndDate()
  );

  const startDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(startDate)).utc().format();
  }, [startDate]);
  const endDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(endDate)).utc().format();
  }, [endDate]);

  const onChooseDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    location.state = location.state || {};
    location.state.startDate = start;
    location.state.endDate = end;

    history.replace({
      pathname: location.pathname,
      state: location.state,
      search: searchParams.toString(),
    });
  };

  return (
    <DateContext.Provider
      value={{
        startDate,
        endDate,
        startDateUtc,
        endDateUtc,
        onChooseDates,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

const useDateContext = () => {
  const context = useContext(DateContext);

  if (!context) {
    throw new Error('useDateContext must be used within a DateContextProvider');
  }

  return context;
};

export { DateContextProvider, useDateContext };
