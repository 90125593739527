import { useQuery, useEffect } from 'hooks/hooks.js';
import { Freshness } from 'Components/components.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const PipelineDataFreshness = ({
  jobHash,
  metricType,
  onLoadingFreshnessDataChange,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.jobFreshness, jobHash, metricType, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
        from: startDateUtc,
        to: endDateUtc,
      });
    },
    {
      enabled: Boolean(jobHash && metricType && startDateUtc && endDateUtc),
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (onLoadingFreshnessDataChange) {
      onLoadingFreshnessDataChange(isFetching);
    }
  }, [isFetching, onLoadingFreshnessDataChange]);

  if (!isFetching && !data?.values.length) {
    return (
      <div className='txt-grey-13-500 text-center text-italic'>
        No data for graph
      </div>
    );
  }

  return (
    <Freshness
      data={data?.values}
      isFetchingData={isFetching}
      freshnessGraphWidth={712}
    />
  );
};

export { PipelineDataFreshness };
