import {
  useBqBillingData,
  useMemo,
  useQuery,
  useEffect,
  useState,
} from 'hooks/hooks.js';
import moment from 'moment';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useCostsDailyStats = () => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const { isWelcome } = useBqBillingData();

  const { isFetching: isFetchingCostsDailyStats, data } = useQuery(
    [QUERY_TYPES.costsDailyStats, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled: !isWelcome, cacheTime: 0 }
  );

  const costsDailyStats = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { isFetchingCostsDailyStats, costsDailyStats };
};

const useCostsDailyStatsPrevious = () => {
  const { isWelcome } = useBqBillingData();
  const { startDate, endDate } = useDateContext();
  const [startDateBefore, setStartDateBefore] = useState();
  const [endDateBefore, setEndDateBefore] = useState();

  useEffect(() => {
    if (startDate && endDate) {
      const startMoment = moment(startDate).utc();
      const endMoment = moment(endDate).utc();
      const diffMoment = endMoment.diff(startMoment);
      const diffMomentDays = Math.ceil(diffMoment / (1000 * 60 * 60 * 24));
      const startMomentBefore = moment(startMoment).subtract(
        diffMomentDays,
        'd'
      );
      const endMomentBefore = moment(startMomentBefore).add(diffMoment, 'ms');
      setStartDateBefore(startMomentBefore.toDate());
      setEndDateBefore(endMomentBefore.toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { isFetching: isFetchingCostsDailyStatsPrevious, data } = useQuery(
    [QUERY_TYPES.costsDailyStats, startDateBefore, endDateBefore],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    {
      enabled:
        startDateBefore !== undefined &&
        endDateBefore !== undefined &&
        !isWelcome,
      cacheTime: 0,
    }
  );

  const costsDailyStatsPrevious = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { isFetchingCostsDailyStatsPrevious, costsDailyStatsPrevious };
};

export { useCostsDailyStats, useCostsDailyStatsPrevious };
