import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './StatsWidget.styles.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import {
  CostsStatsGraphView,
  CostsStatsTableView,
} from './components/components.js';
import { getTotalPipelineDiff, numberFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { COSTS_STATS_VIEW_TYPE, PipelineTabs } from 'constants/constants.js';

const CostsStatsWidget = ({
  costsStats,
  costsStatsPrevious,
  className = '',
  viewType = COSTS_STATS_VIEW_TYPE.table,
}) => {
  const classes = useStyles();
  const totalAmount = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const totalAmountPrevious = useMemo(() => {
    return costsStatsPrevious.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStatsPrevious]);

  const totalAmountInfo = getTotalPipelineDiff(
    totalAmount,
    totalAmountPrevious
  );

  return (
    <section className={clsx(classes.widget, className)}>
      <section>
        <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
          Pipeline compute costs
        </Typography>

        <section className={classes.flexRawContainer}>
          <Link
            to={{
              pathname: AppRoutes.Pipelines.path,
              search: new URLSearchParams({
                tab: PipelineTabs.COSTS,
              }).toString(),
            }}
          >
            <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
              ${numberFormat(totalAmount)}
            </Typography>
          </Link>

          {totalAmountInfo !== null && (
            <PercentStatsBlock data={totalAmountInfo} />
          )}
        </section>
      </section>

      {viewType === COSTS_STATS_VIEW_TYPE.graph ? (
        <CostsStatsGraphView />
      ) : (
        <CostsStatsTableView
          costsStats={costsStats}
          costsStatsPrevious={costsStatsPrevious}
        />
      )}
    </section>
  );
};

export { CostsStatsWidget };
