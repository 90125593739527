const TABLE_TYPES = {
  dbTable: 'DB_TABLE',
  bqTable: 'BQ_TABLE',
  external: 'EXTERNAL',
  view: 'VIEW',
  sharded: 'SHARDED',
  partition: 'PARTITION',
  stream: 'STREAM',
};

export { TABLE_TYPES };
