import React from 'react';

const RemoveRuleModalBody = () => {
  return (
    <div className='txt-mainDark-13-500' style={{ textAlign: 'center' }}>
      Are you sure you want to delete data quality rule?
      <br /> This can't be undone.
    </div>
  );
};

export { RemoveRuleModalBody };
