import React from 'react';
import {
  useQuery,
  useEffect,
  useLocation,
  useMemo,
  useState,
} from 'hooks/hooks.js';
import { Chip } from '@mui/material';
import { useStyles } from './RightSideModal.style.js';
import { useModal } from 'context/context.js';
import { PositionLoader, Query } from 'Components/components.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { handleCopyText } from 'utils/helpers/helpers.js';
import { ReactComponent as CloseButton } from 'assets/img/icons/closeIconBgd.svg';
import { ReactComponent as CopyText } from 'assets/img/icons/copyIcon.svg';

const QueryModal = ({ error, type, mainBlockMargin = 24 }) => {
  const classes = useStyles({ mainBlockMargin });
  const { setModal } = useModal();
  const closeModal = () => setModal(null);
  const location = useLocation();
  const currentModalPath = useMemo(() => location.pathname, []);
  const [query, setQuery] = useState('');

  const { data } = useQuery(
    [`${QUERY_TYPES.incidents}/${error.id}/one`, type],
    ({ queryKey }) => {
      const [url, type] = queryKey;
      return fetcherGet(url, { type });
    },
    { enabled: !!error.id && !!type }
  );

  useEffect(() => {
    if (currentModalPath !== location.pathname) {
      setModal(null);
    }
  }, [currentModalPath, location.pathname, setModal]);

  useEffect(() => {
    if (data?.value) {
      if (data.value.data[0].query) {
        setQuery(data.value.data[0].query);
      } else if (data.value.data[0].sourceUris.length) {
        setQuery(data.value.data[0].sourceUris.join(',\n'));
      } else {
        setQuery('');
      }
    }
  }, [data]);

  return (
    <div className={classes.modalContainer} style={{ width: 824 }}>
      {!data?.value ? (
        <PositionLoader />
      ) : (
        <>
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              <div className='txt-mainDark-24-700'>Query</div>
              <CloseButton
                className={classes.cancelButton}
                onClick={closeModal}
              />
            </div>

            <div className='txt-mainDark-13-700' style={{ marginRight: 64 }}>
              {error.error}
            </div>
          </div>

          <div className={classes.copyContainer}>
            <Chip
              icon={<CopyText />}
              label='Copy query'
              variant='outlined'
              onClick={() => handleCopyText(query)}
            />
          </div>

          <div className={classes.mainBlock}>
            <Query query={query} />
          </div>
        </>
      )}
    </div>
  );
};

export { QueryModal };
