import React from 'react';
import {
  useState,
  useTableLabels,
  useCostsSourceTables,
  useBqBillingData,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import { Chip, CHIP_COLOR, TableLabelsChips } from 'Components/components.js';
import {
  CostLabel,
  DestinationTableCell,
  LoadingTableRaw,
} from 'Pages/Pipelines/components/components';
import { ResourceTableSourceRaw } from './ResourceTableSourceRaw.js';
import { getAlertColorType, getAlertLabel } from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE, PIPELINE_TYPE_DATA } from 'utils/constants.js';

const getDestinationTableIcon = (pipelineType) =>
  PIPELINE_TYPE_DATA[pipelineType]?.icon;

const ResourceTableRows = ({ destinationTable, selectedTag }) => {
  const { currentBqBillingData } = useBqBillingData();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const [isOpenTableRaw, setIsOpenTableRaw] = useState(false);

  const { sources, isFetchingSources } = useCostsSourceTables({
    dataset: destinationTable.dataset,
    table: destinationTable.table,
    jobTagNames: [selectedTag],
    pipelineType: destinationTable?.pipelineType,
    project: destinationTable?.project,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  const handleChangeOpen = () => {
    setIsOpenTableRaw((prevState) => !prevState);
  };

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          icon={getDestinationTableIcon(destinationTable?.pipelineType)}
        />
        <TableCell
          align='left'
          sx={{ alignContent: 'baseline' }}
          colSpan={isExistingReservationCell ? 5 : 4}
        >
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>
        <TableCell align='right'>
          <div className='txt-mainDark-13-700'>
            <CostLabel value={destinationTable?.costs} />
          </div>
        </TableCell>
      </TableRow>

      {isOpenTableRaw &&
        (isFetchingSources ? (
          <LoadingTableRaw />
        ) : (
          sources.length > 0 &&
          sources.map((item) => (
            <ResourceTableSourceRaw
              key={item.jobHash}
              sourceData={item}
              destinationTable={destinationTable}
            />
          ))
        ))}
    </>
  );
};

export { ResourceTableRows };
