import {
  useEffect,
  useHistory,
  useState,
  useMemo,
  useGetBigQueryIncidentGroups,
} from 'hooks/hooks.js';
import {
  IncidentTabs,
  INCIDENT_CHART_BY_TYPE,
  INCIDENT_STATUS,
  ANOMALY_TYPE,
} from 'constants/constants.js';
import { VolumeSensitiveSettingsModal } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { useModal } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import {
  CRITICAL_ALERT_TYPE,
  MUTED_ALERT_TYPE,
  PRIORITY_ALERT_TYPE,
  REGULAR_ALERT_TYPE,
} from 'utils/constants.js';

const EVENT_BUTTON =
  'Monitors page -> Volume widget -> Click -> Sensitive settings modal';

const AnomaliesWidget = ({
  anomalyType,
  label,
  secondaryLabel,
  tableInfo,
  incidentGroupsTypes,
  isExisting,
  onChangeExistingData,
  toPages,
}) => {
  const history = useHistory();
  const { setModal } = useModal();

  const Chart = useMemo(
    () => INCIDENT_CHART_BY_TYPE[anomalyType],
    [anomalyType]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [incidentsCount, setIncidentsCount] = useState(0);
  const [isShowButton, setIsShowButton] = useState(false);

  const { incidentsTotal } = useGetBigQueryIncidentGroups({
    page: 0,
    rowsPerPage: 1,
    dataset: tableInfo?.dataset,
    table: tableInfo?.table,
    searchTable: '',
    incidentGroupsTypes: [{ value: incidentGroupsTypes }],
    incidentGroupsAlert: [{ value: tableInfo?.alertType }],
    incidentGroupsStatuses: [{ value: INCIDENT_STATUS.ACTIVE }],
  });

  useEffect(() => {
    if (incidentsTotal) {
      setIncidentsCount(incidentsTotal);
    }
  }, [incidentsTotal]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (isExisting !== null && incidentsCount != null) {
      setIsLoading(false);
    }
  }, [isExisting, incidentsCount, isLoading]);

  useEffect(() => {
    setIsLoading(true);
    setIncidentsCount(null);
  }, [tableInfo?.dataset, tableInfo?.table]);

  const openSensitiveModal = () => {
    amplEvent(EVENT_BUTTON);
    setModal(
      <VolumeSensitiveSettingsModal
        table={tableInfo?.table}
        dataset={tableInfo?.dataset}
        onClose={() => setModal(null)}
      />
    );
  };

  const handleChangeButton = (value) => {
    setIsShowButton(value);
  };

  return (
    <Widget
      label={label}
      secondaryLabel={secondaryLabel}
      incidentCount={incidentsCount}
      count={true}
      toPage={
        incidentsCount
          ? toPages(
              AppRoutes.Incidents.path,
              {
                ...history.location.state,
                filterIds: [incidentGroupsTypes],
                filterAlertIds: [
                  CRITICAL_ALERT_TYPE,
                  PRIORITY_ALERT_TYPE,
                  REGULAR_ALERT_TYPE,
                  MUTED_ALERT_TYPE,
                ],
              },
              'See all',
              label,
              IncidentTabs.GBQ
            )
          : null
      }
      existingDivider={isExisting}
      initialSettings={{ size: 'L', height: 'auto' }}
      isShowButton={anomalyType === ANOMALY_TYPE.VOLUME && isShowButton}
      onWidgetButtonClick={openSensitiveModal}
      widgetButtonText='Settings'
    >
      <Chart
        table={tableInfo?.table}
        dataset={tableInfo?.dataset}
        onChangeExistingData={onChangeExistingData}
        onChangeExistingButton={handleChangeButton}
        withScatterGraph={true}
      />
    </Widget>
  );
};

export { AnomaliesWidget };
