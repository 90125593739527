import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100vh',
    background: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    '&.slide-in': {
      animation: '$slide-in 0.3s ease-out forwards',
    },
    '&.slide-out': {
      animation: '$slide-out 0.3s ease-out forwards',
    },
    '& .paginationContainer': {
      padding: '0 32px 32px 0',
    },
  },
  headerContainer: {
    padding: theme.spacing(6),
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButton: {
    cursor: 'pointer',
  },
  copyContainer: {
    margin: '32px 32px 0 0',
    display: 'flex',
    justifyContent: 'end',
    '& .MuiChip-root': {
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      '&.MuiChip-clickable': {
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    '& .MuiChip-label': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      color: theme.palette.common.secondaryDark,
    },
  },
  mainBlock: {
    flexGrow: 1,
    margin: (props) => props.mainBlockMargin,
    borderRadius: 8,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },

  '@keyframes slide-in': {
    '0%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },

  '@keyframes slide-out': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
  },
}));
