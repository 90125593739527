import React from 'react';
import {
  useSelector,
  useState,
  useEffect,
  useMemo,
  useDispatch,
  useCallback,
  useLocation,
  useGetLookerIncidentGroups,
} from 'hooks/hooks.js';
import { Col, Row } from 'react-bootstrap';
import { getUniqueDashboards, getUniqueLooks } from 'slices/actions.js';
import { UnconnectedLooker } from 'Components/components.js';
import { LookerSearch } from 'Pages/Dictionary/components/DictionaryLooker/components/components.js';
import { Dropdowns } from '../GlobalPageComponents/components.js';
import { LookerIncidentCard } from './components/components.js';
import {
  AMPL_PAGE_EVENT,
  LOOKER_STATUS_FILTER_OPTIONS,
  DATA_STATUS,
  IncidentTabs,
  SHORT_INCIDENT_FILTER,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import { isStringOfNumbersOnly } from 'utils/helpers/helpers.js';
import { formatIncidents } from 'Pages/Incidents/libs/utils/utils.js';
import {
  useConfiguration,
  useDateContext,
  useMessages,
} from 'context/context.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';
import {
  LOOKER_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  LOOKER_SEARCH_OPTIONS,
  LookerTabDropdowns,
  TABLE_ALERT_FILTER,
  DEFAULT_SEARCH_MESSAGE,
  LOOKER_INCIDENT_TYPE_FILTER,
} from 'Pages/Incidents/libs/constants/constants.js';

const TAB_EVENT = `${AMPL_PAGE_EVENT.incidents} -> tab: ${IncidentTabs.LOOKER} ->`;

const LookerTab = ({
  handleChangeTotalIncidents,
  handleChangeTitleTotalIncidents,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeOpenBackdrop,
  onChangeExistingDatePicker,
}) => {
  const { startDate, endDate } = useDateContext();
  const { isLookerConnected } = useConfiguration();
  const [dashboardNameSearchOptions, setDashboardNameSearchOptions] = useState(
    []
  );
  const [searchDashboardName, setSearchDashboardName] = useState('');
  const [searchDashboardId, setSearchDashboardId] = useState('');
  const [lookNameSearchOptions, setLookNameSearchOptions] = useState([]);
  const [searchLookName, setSearchLookName] = useState('');
  const [searchLookId, setSearchLookId] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const { setMessage } = useMessages();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [dropdownsValue, setDropdownsValue] = useState({
    [LOOKER_INCIDENT_TYPE_FILTER]: [LOOKER_STATUS_FILTER_OPTIONS[0]],
    [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[1], ALERT_FILTER_OPTIONS[2]],
    [LOOKER_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [INCIDENT_STATUS_SHORT_FILTER]: [SHORT_INCIDENT_FILTER[0]],
  });

  const incidentIdFromUrl = searchParams.get(SEARCH_PARAMS.ID);
  const incidentTypeFromUrl = searchParams.get(SEARCH_PARAMS.TYPE);

  const defaultIncidentIdAndType = useMemo(() => {
    return incidentIdFromUrl && incidentTypeFromUrl
      ? {
          id: incidentIdFromUrl,
          type: incidentTypeFromUrl,
        }
      : {
          id: null,
          type: null,
        };
  }, [incidentIdFromUrl, incidentTypeFromUrl]);

  const [incidentIdAndType, setIncidentIdAndType] = useState(
    defaultIncidentIdAndType
  );

  const tableInfoByIncident = (incident) => {
    return {
      uuid: incident.tableUuid,
      dataset: incident.dataset,
      table: incident.table,
      type: incident.tableType,
      alertType: incident.alertType,
    };
  };

  const handleDashboardNameSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchDashboardName(value || '');

      if (value.length) {
        amplEvent(`${TAB_EVENT} search by dashboard name: ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleDashboardIdSearch = useCallback(
    (value) => {
      if (!isStringOfNumbersOnly(value) && value.length) {
        setMessage('Enter a numeric value');
        return;
      }

      handleChangePage({}, 0);
      setSearchDashboardId(value || '');

      if (value.length) {
        amplEvent(`${TAB_EVENT} search by dashboard id: ${value}`);
      }
    },
    [handleChangePage, setMessage]
  );

  const handleLookNameSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchLookName(value || '');

      if (value.length) {
        amplEvent(`${TAB_EVENT} search by look name: ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleLookIdSearch = useCallback(
    (value) => {
      if (!isStringOfNumbersOnly(value) && value.length) {
        setMessage('Enter a numeric value');
        return;
      }

      handleChangePage({}, 0);
      setSearchLookId(value || '');

      if (value.length) {
        amplEvent(`${TAB_EVENT} search by look id: ${value}`);
      }
    },
    [handleChangePage, setMessage]
  );

  useEffect(() => {
    const id = searchParams.get(SEARCH_PARAMS.ID);
    const type = searchParams.get(SEARCH_PARAMS.TYPE);

    if (id && type) {
      setIncidentIdAndType({ id, type });
      onChangeExistingDatePicker(false);
    } else {
      setIncidentIdAndType({ id: null, type: null });
      onChangeExistingDatePicker(true);
    }
  }, [searchParams, onChangeExistingDatePicker]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [handleChangePage, startDate, endDate]);

  const {
    uniqueDashboards,
    uniqueDashboardsStatus,
    uniqueLooks,
    uniqueLooksStatus,
  } = useSelector((state) => ({
    uniqueDashboards: state.looker.uniqueDashboards,
    uniqueDashboardsStatus: state.looker.uniqueDashboardsStatus,
    uniqueLooks: state.looker.uniqueLooks,
    uniqueLooksStatus: state.looker.uniqueLooksStatus,
  }));

  const isLoadingUniqueDashboards = useMemo(() => {
    return uniqueDashboardsStatus === DATA_STATUS.pending;
  }, [uniqueDashboardsStatus]);

  const isLoadingUniqueLooks = useMemo(() => {
    return uniqueLooksStatus === DATA_STATUS.pending;
  }, [uniqueLooksStatus]);

  useEffect(() => {
    if (uniqueDashboardsStatus === DATA_STATUS.idle) {
      dispatch(getUniqueDashboards());
    }
  }, [dispatch, uniqueDashboardsStatus]);

  useEffect(() => {
    if (uniqueLooksStatus === DATA_STATUS.idle) {
      dispatch(getUniqueLooks());
    }
  }, [dispatch, uniqueLooksStatus]);

  useEffect(() => {
    if (uniqueDashboards.length) {
      setDashboardNameSearchOptions(
        uniqueDashboards.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueDashboards]);

  useEffect(() => {
    if (uniqueLooks.length) {
      setLookNameSearchOptions(
        uniqueLooks.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueLooks]);

  const {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
  } = useGetLookerIncidentGroups({
    page,
    rowsPerPage,
    incidentGroupsTypes: dropdownsValue[LOOKER_INCIDENT_TYPE_FILTER],
    incidentGroupsAlert: dropdownsValue[TABLE_ALERT_FILTER],
    incidentGroupsStatuses: dropdownsValue[INCIDENT_STATUS_SHORT_FILTER],
    lookerAlertTypes: dropdownsValue[LOOKER_ALERT_FILTER],
    incidentIdAndType,
    searchDashboardId,
    searchDashboardName,
    searchLookId,
    searchLookName,
  });

  useEffect(() => {
    handleChangeTitleTotalIncidents(incidentsTotal);
    handleChangeTotalIncidents(incidentsGroupPagination);
  }, [
    handleChangeTitleTotalIncidents,
    handleChangeTotalIncidents,
    incidentsGroupPagination,
    incidentsTotal,
  ]);

  useEffect(() => {
    onChangeOpenBackdrop(isIncidentGroupsFetching);
  }, [isIncidentGroupsFetching, onChangeOpenBackdrop]);

  const formattedData = useMemo(() => {
    if (!incidentGroups.length) {
      return {};
    }
    return formatIncidents(incidentGroups);
  }, [incidentGroups]);

  const emptySearchMessage = useMemo(() => {
    if (!incidentGroups.length && !isIncidentGroupsFetching) {
      const searchConditions = [
        {
          condition: searchDashboardName.length,
          message: `${DEFAULT_SEARCH_MESSAGE} dashboard name`,
        },
        {
          condition: searchDashboardId.length,
          message: `${DEFAULT_SEARCH_MESSAGE} dashboard ID`,
        },
        {
          condition: searchLookName.length,
          message: `${DEFAULT_SEARCH_MESSAGE} look name`,
        },
        {
          condition: searchLookId.length,
          message: `${DEFAULT_SEARCH_MESSAGE} look ID`,
        },
      ];

      const foundingCondition = searchConditions.find((item) => item.condition);
      return foundingCondition?.message || '';
    }

    return '';
  }, [
    incidentGroups.length,
    isIncidentGroupsFetching,
    searchDashboardId,
    searchDashboardName,
    searchLookId,
    searchLookName,
  ]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  if (!isLookerConnected) {
    return <UnconnectedLooker />;
  }

  return (
    <>
      {(!incidentIdAndType.id || !incidentIdAndType.type) && (
        <>
          <Dropdowns
            existingDropdown={LookerTabDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
          />

          <LookerSearch
            customSearchOptions={LOOKER_SEARCH_OPTIONS}
            dashboardNameSearchOptions={dashboardNameSearchOptions}
            handleDashboardNameSearch={handleDashboardNameSearch}
            isDashboardNameSearchOptionsLoading={isLoadingUniqueDashboards}
            handleDashboardIdSearch={handleDashboardIdSearch}
            lookNameSearchOptions={lookNameSearchOptions}
            handleLookNameSearch={handleLookNameSearch}
            isLookNameSearchOptionsLoading={isLoadingUniqueLooks}
            handleLookIdSearch={handleLookIdSearch}
          />
        </>
      )}

      {emptySearchMessage.length > 0 && (
        <p className='txt-mainDark-16-500 text-center'>{emptySearchMessage}</p>
      )}

      {Object.keys(formattedData).map((date) => (
        <Row key={date}>
          <Col xs={12}>
            <p className='txt-mainDark-20-700 mb-3'>{date}</p>
          </Col>
          {formattedData[date].map((incident) => (
            <Col key={incident.groupKey} xs={12} className='mb-3'>
              <LookerIncidentCard
                groupIncident={incident}
                incidentIdAndType={incidentIdAndType}
                incidentStatuses={dropdownsValue[INCIDENT_STATUS_SHORT_FILTER]}
                tableInfo={tableInfoByIncident(incident)}
                searchDashboardName={searchDashboardName}
                searchLookName={searchLookName}
                searchDashboardId={searchDashboardId}
                searchLookId={searchLookId}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export { LookerTab };
