import React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useHistory,
} from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import G6 from '@antv/g6';
import { makeStyles } from '@mui/styles';
import { shortFrequencyBySeconds } from 'utils/helpers/helpers.js';
import { dataTransform } from './utilsOpenGraph.js';
import {
  getMaxTextLength,
  getNodeTypeImage,
  getNodeTypeName,
} from './libs/helpers/helpers.js';
import { Button, BUTTON_COLOR, Loader } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { useModal } from 'context/context.js';
import {
  GraphStyles,
  GraphShapeNames,
  GraphDefaultTypes,
  GraphDefaultModes,
  NodeTypes,
} from './enums/enums.js';
import {
  CRITICAL_ALERT_TYPE,
  MH_JOB_CHANGE_PREFIX,
  MH_PIPELINE_PREFIX,
  MUTED_ALERT_TYPE,
  PIPELINE_TYPE,
  PIPELINE_TYPE_DATA,
  PRIORITY_ALERT_TYPE,
} from 'utils/constants.js';
import { LineageTabs } from 'constants/constants.js';
import theme from 'theme.js';
import backgroundPoints from 'assets/img/backgrounds/points.svg';
import Critical from 'assets/img/alert/critical.svg';
import Priority from 'assets/img/alert/priority.svg';
import Regular from 'assets/img/alert/regular.svg';
import Mute from 'assets/img/alert/mute.svg';

const TAGS_HEIGHT = 18;

const TITLE_CONTAINER_WIDTH = 164;
const TABLE_WIDTH =
  GraphStyles.FONT_LEFT_WITH_ICON +
  TITLE_CONTAINER_WIDTH +
  GraphStyles.FONT_LEFT;
const TABLE_HEIGHT = 94;

const PIPELINE_NODE_TITLE_CONTAINER_WIDTH = 120;
const PIPELINE_NODE_WIDTH =
  GraphStyles.FONT_LEFT_WITH_ICON +
  PIPELINE_NODE_TITLE_CONTAINER_WIDTH +
  GraphStyles.FONT_LEFT;

const TOP_BOX_GROUP_HEIGHT = 56;

const PROJECT_NAME_BOX_SHIFT = 24;

const ALERT_TYPE_IMAGE_SIZE = 8;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
  },

  backgroundPattern: {
    backgroundImage: `url(${backgroundPoints})`,
  },

  hoverPattern: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    backgroundColor: 'rgba(5, 12, 46, 0.2)',
    borderRadius: theme.spacing(3),
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const { registerEdge, registerNode } = G6;

registerEdge(GraphDefaultTypes.DEFAULT_EDGE_OPEN, {
  draw(cfg, group) {
    const edge = group.cfg.item;
    const sourceNode = edge.getSource().getModel();
    const targetNode = edge.getTarget().getModel();

    let sourceY = 15 + PROJECT_NAME_BOX_SHIFT;
    let targetY = 15 + PROJECT_NAME_BOX_SHIFT;
    let shape;

    const startPoint = { ...cfg.startPoint };
    const endPoint = { ...cfg.endPoint };

    startPoint.y = startPoint.y + sourceY;
    endPoint.y = endPoint.y + targetY;

    if (sourceNode.id !== targetNode.id) {
      shape = group.addShape('path', {
        attrs: {
          stroke: theme.palette.divider,
          path: [
            ['M', startPoint.x, startPoint.y],
            [
              'C',
              endPoint.x / 3 + (2 / 3) * startPoint.x,
              startPoint.y,
              endPoint.x / 3 + (2 / 3) * startPoint.x,
              endPoint.y,
              endPoint.x,
              endPoint.y,
            ],
          ],
          endArrow: true,
        },
        name: GraphShapeNames.PATH_SHAPE,
      });
    }

    return shape;
  },
});

registerNode(GraphDefaultTypes.DEFAULT_NODE_OPEN, {
  draw(cfg, group) {
    const itemModel = group.cfg.item.getModel();
    const { nodeTypeData } = itemModel;
    const {
      pipelineType = null,
      attrsNumber: columnsNumber = 0,
      datasetType = null,
      frequency = null,
      jobTags = [],
      alertType = null,
    } = nodeTypeData || {};

    const {
      isAnomalyTable,
      isGrayTable,
      isCurrentTable,
      isPipeline,
      mainNodeProject,
    } = itemModel?.visibilityProps || {};

    const nodeProject = itemModel.project || null;

    const isExistingProjectNameBox =
      nodeProject && mainNodeProject !== nodeProject;
    const isExistingTagsBlock = jobTags?.length > 0;
    const isExistingShowMoreTagsBlock = jobTags?.length > 1;

    const boxStyle = {
      stroke: theme.palette.common.white,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowBlur: 8,
      radius: 12,
    };

    const { tagsCollapsed = true, nodeType } = cfg;
    const isPipelineNode = nodeType === NodeTypes.PIPELINE;
    const isSourceUriNode = nodeType === NodeTypes.SOURCE_URI;
    const isDestinationUriNode = nodeType === NodeTypes.DESTINATION_URI;
    const isRoutineNode = nodeType === NodeTypes.ROUTINE;

    const isHiddenAttrs =
      isPipelineNode ||
      isSourceUriNode ||
      isDestinationUriNode ||
      isRoutineNode ||
      (isPipeline && isCurrentTable);

    const pipelineNodeHeight = !isExistingTagsBlock
      ? TOP_BOX_GROUP_HEIGHT
      : tagsCollapsed
      ? TOP_BOX_GROUP_HEIGHT +
        (isExistingShowMoreTagsBlock ? 2 : 1) * TAGS_HEIGHT
      : TOP_BOX_GROUP_HEIGHT + (jobTags.length + 1) * TAGS_HEIGHT;

    const nodeTypeText = getNodeTypeName(nodeType, datasetType);
    const subTitle = [nodeTypeText];

    if (frequency !== null && pipelineType !== PIPELINE_TYPE.BROWSER) {
      subTitle.push(shortFrequencyBySeconds(frequency));
    }

    const subTitleText = subTitle.join(' • ');

    const shape = group.addShape('rect', {
      attrs: {
        x: 0,
        y: -PROJECT_NAME_BOX_SHIFT,
        width: isPipelineNode ? PIPELINE_NODE_WIDTH : TABLE_WIDTH,
        height: 38,
        fill: theme.palette.secondary.light,
        radius: [12, 12, 0, 0],
        shadowColor: GraphStyles.SHADOW_COLOR,
      },
      visible: isExistingProjectNameBox,
      draggable: true,
      name: GraphShapeNames.PROJECT_NAME_BOX,
    });

    if (isExistingProjectNameBox) {
      const projectNameBoxGroup = group.addGroup({
        name: GraphShapeNames.PROJECT_NAME_BOX,
      });

      projectNameBoxGroup.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: -8,
          fill: theme.palette.text.secondary,
          text: getMaxTextLength(
            nodeProject.toUpperCase(),
            TABLE_WIDTH - GraphStyles.FONT_LEFT * 2,
            9
          ),
          fontSize: 9,
          fontWeight: 500,
          lineHeight: 11,
        },
        name: GraphShapeNames.PROJECT_NAME_TEXT,
      });
    }

    group.addShape('rect', {
      attrs: {
        x: 0,
        y: 0,
        width: isPipelineNode ? PIPELINE_NODE_WIDTH : TABLE_WIDTH,
        height: isPipelineNode
          ? pipelineNodeHeight
          : isHiddenAttrs
          ? TOP_BOX_GROUP_HEIGHT
          : TABLE_HEIGHT,
        ...boxStyle,
        fill: isPipelineNode
          ? theme.palette.secondary.light
          : theme.palette.common.white,
        stroke: isCurrentTable && theme.palette.primary.main,
        shadowColor: isCurrentTable
          ? theme.palette.common.white
          : GraphStyles.SHADOW_COLOR,
      },
      draggable: true,
      name: GraphShapeNames.MAIN_BOX,
    });

    /** table name */
    const pipelineNodeTitle = isPipelineNode
      ? PIPELINE_TYPE_DATA[pipelineType]?.title
      : '';

    const getNodeTitle = () => {
      if (pipelineNodeTitle.length) {
        return getMaxTextLength(
          pipelineNodeTitle,
          PIPELINE_NODE_TITLE_CONTAINER_WIDTH - GraphStyles.FONT_LEFT,
          11
        );
      }

      return getMaxTextLength(
        cfg.label,
        TITLE_CONTAINER_WIDTH - GraphStyles.FONT_LEFT,
        11
      );
    };

    const nodeTitle = getNodeTitle();

    group.addShape('text', {
      attrs: {
        x: GraphStyles.FONT_LEFT_WITH_ICON,
        y: 28,
        fill: theme.palette.text.primary,
        text: nodeTitle,
        fullTableName: cfg.id,
        fontSize: 11,
        fontWeight: 700,
        lineHeight: 14,
        cursor: 'pointer',
      },
      name: GraphShapeNames.TITLE_SHAPE,
    });

    group.addShape('text', {
      attrs: {
        x: GraphStyles.FONT_LEFT_WITH_ICON,
        y: 42,
        fill: theme.palette.text.secondary,
        text: subTitleText,
        fontFamily: theme.typography.fontFamily,
        fontSize: 9,
        fontWeight: 500,
        lineHeight: 14,
      },
      name: GraphShapeNames.SUBTITLE_SHAPE,
    });

    if (!isHiddenAttrs) {
      group.addShape('line', {
        attrs: {
          x1: 0,
          x2: TABLE_WIDTH,
          y1: 56,
          y2: 56,
          stroke: theme.palette.divider,
          lineWidth: 1,
        },
        draggable: true,
        name: GraphShapeNames.DIVIDER_SHAPE,
      });
    }

    const getNodeImageFill = () => {
      if (nodeType === NodeTypes.PIPELINE) {
        return theme.palette.common.white;
      }

      if (!isHiddenAttrs) {
        return isAnomalyTable
          ? theme.palette.error.main
          : isGrayTable
          ? theme.palette.secondary.light
          : theme.palette.secondary.main;
      }

      return theme.palette.secondary.main;
    };

    const nodeImageColor = isAnomalyTable
      ? theme.palette.error.main
      : isGrayTable
      ? theme.palette.text.secondary
      : theme.palette.primary.main;

    group.addShape('rect', {
      attrs: {
        x: GraphStyles.FONT_LEFT,
        y: 16,
        width: 24,
        height: 24,
        radius: 8,
        fill: getNodeImageFill(),
        opacity: isAnomalyTable ? 0.1 : 1,
      },
      name: GraphShapeNames.NODE_ICON,
    });

    group.addShape('image', {
      attrs: {
        x: GraphStyles.FONT_LEFT + 4,
        y: 16 + 4,
        img: getNodeTypeImage(
          nodeType,
          datasetType,
          pipelineType,
          nodeImageColor
        ),
      },
      name: GraphShapeNames.NODE_ICON,
    });

    /** node alert type images */
    if (
      alertType === CRITICAL_ALERT_TYPE ||
      alertType === PRIORITY_ALERT_TYPE
    ) {
      const getAlertTypeImageFill = () => {
        switch (alertType) {
          case CRITICAL_ALERT_TYPE:
            return '#FFEEAE';
          case PRIORITY_ALERT_TYPE:
            return '#FEFFD0';
          case MUTED_ALERT_TYPE:
            return '#EEE';
          default:
            return theme.palette.secondary.main;
        }
      };

      const getAlertTypeImage = () => {
        switch (alertType) {
          case CRITICAL_ALERT_TYPE:
            return Critical;
          case PRIORITY_ALERT_TYPE:
            return Priority;
          case MUTED_ALERT_TYPE:
            return Mute;
          default:
            return Regular;
        }
      };

      group.addShape('circle', {
        attrs: {
          x: 36,
          y: 16,
          r: 8,
          fill: getAlertTypeImageFill(),
          lineWidth: 0.75,
          stroke: 'rgba(5, 12, 46, 0.10)',
        },
        name: GraphShapeNames.NODE_ALERT_TYPE_ICON,
      });

      group.addShape('image', {
        attrs: {
          x: 36 - ALERT_TYPE_IMAGE_SIZE / 2,
          y: 16 - ALERT_TYPE_IMAGE_SIZE / 2,
          width: ALERT_TYPE_IMAGE_SIZE,
          height: ALERT_TYPE_IMAGE_SIZE,
          img: getAlertTypeImage(),
        },
        name: GraphShapeNames.NODE_ALERT_TYPE_ICON,
      });
    }

    group.addShape('text', {
      attrs: {
        x: GraphStyles.FONT_LEFT,
        y: 82,
        text: !isHiddenAttrs ? columnsNumber + ' columns' : '',
        width: TABLE_WIDTH,
        fill: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
        fontSize: 11,
        lineHeight: 14,
        fontWeight: 500,
      },
      name: GraphShapeNames.COLLAPSE,
    });

    /** tags block */
    if (jobTags?.length > 0 && isPipelineNode) {
      const totalJobTags = jobTags.length;
      const hiddenTotalJobTags = totalJobTags - 1;
      const visibleTagName =
        jobTags[0].name.length > GraphStyles.MAX_TAG_NAME_LENGTH
          ? jobTags[0].name.slice(0, GraphStyles.MAX_TAG_NAME_LENGTH) + '...'
          : jobTags[0].name;

      group.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: 62,
          text: visibleTagName,
          fill: theme.palette.text.secondary,
          fontFamily: theme.typography.fontFamily,
          fontSize: 11,
          lineHeight: 14,
          fontWeight: 500,
        },
        name: GraphShapeNames.VISIBLE_TAG_NAME,
      });

      if (isExistingShowMoreTagsBlock) {
        const hiddenJobTagsText =
          hiddenTotalJobTags === 1
            ? `+${hiddenTotalJobTags} tag`
            : `+${hiddenTotalJobTags} tags`;

        group.addShape('text', {
          attrs: {
            x: GraphStyles.FONT_LEFT,
            y: tagsCollapsed ? 78 : 78 + hiddenTotalJobTags * TAGS_HEIGHT,
            text: tagsCollapsed ? hiddenJobTagsText : 'Show less',
            fill: theme.palette.text.secondary,
            fillOpacity: 0.7,
            fontFamily: theme.typography.fontFamily,
            fontSize: 11,
            lineHeight: 14,
            fontWeight: 500,
            cursor: 'pointer',
          },
          name: tagsCollapsed
            ? GraphShapeNames.EXPAND_TAGS
            : GraphShapeNames.COLLAPSE_TAGS,
        });
      }

      if (!tagsCollapsed) {
        const hiddenJobTags = jobTags.slice(1);
        hiddenJobTags.forEach((jobTag, i) => {
          const { name } = jobTag;
          const label =
            name.length > GraphStyles.MAX_COLUMN_NAME_LENGTH
              ? name.slice(0, GraphStyles.MAX_COLUMN_NAME_LENGTH) + '...'
              : name;

          group.addShape('text', {
            attrs: {
              x: GraphStyles.FONT_LEFT_WITH_ICON,
              y: 78 + i * TAGS_HEIGHT,
              text: label,
              fill: theme.palette.text.secondary,
              fontFamily: theme.typography.fontFamily,
              fontSize: 11,
              lineHeight: 14,
              fontWeight: 500,
            },
            name: `tag-${i}`,
          });
        });
      }
    }

    return shape;
  },
  getAnchorPoints() {
    return [
      [0, 0],
      [1, 0],
    ];
  },
});

const OpenGraphPipes = ({
  data,
  isFetching = false,
  isSourceUri = false,
  containerHeight = 300,
  containerWidth = 708,
}) => {
  const history = useHistory();
  const containerRef = useRef(null);
  const chartRef = useRef(null);
  const classes = useStyles();
  const destinationNode = useMemo(() => {
    return data?.graph?.find((item) => item.destinationNode === true);
  }, [data]);
  const destinationTable = useMemo(() => {
    if (!destinationNode || destinationNode.nodeType !== NodeTypes.DATASET) {
      return null;
    }
    const [project, dataset, table] = destinationNode.id.split('.');
    return {
      project,
      dataset,
      table,
    };
  }, [destinationNode]);
  const isPipelineDestination = Boolean(
    destinationTable?.table.includes(MH_PIPELINE_PREFIX)
  );
  const upstream = useMemo(() => {
    return (
      data?.graph
        ?.filter((item) => item.upEdges.length)
        .map((item) => item.id) || []
    );
  }, [data]);
  const { setModal } = useModal();
  const isExistingLinkToLineage =
    destinationTable &&
    !destinationTable.table.includes(MH_PIPELINE_PREFIX) &&
    !destinationTable.table.includes(MH_JOB_CHANGE_PREFIX);

  const onTitleClick = useCallback(
    (evt) => {
      if (!destinationTable) {
        return;
      }
      const fullTableName = evt.target.attrs.fullTableName;
      const [currentProject, currentDataset, currentTable] =
        fullTableName.split('.');
      const { nodeType } = evt.item.getModel();

      if (
        nodeType !== NodeTypes.DATASET ||
        (isPipelineDestination && fullTableName === destinationNode.id) ||
        currentProject !== destinationNode.project
      ) {
        return;
      }

      history.push({
        pathname: AppRoutes.Monitors.path,
        search: new URLSearchParams({
          dataset: currentDataset,
          table: currentTable,
        }).toString(),
        state: { ...history.location.state },
      });
      setModal(null);
    },
    [
      history,
      isPipelineDestination,
      setModal,
      destinationNode,
      destinationTable,
    ]
  );

  useEffect(() => {
    if (
      chartRef.current &&
      (!destinationNode || isFetching || !data?.graph?.length)
    ) {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
      chartRef.current = null;
    }
  }, [data?.graph, destinationNode, isFetching]);

  // Updating
  useEffect(() => {
    if (data?.graph.length && chartRef.current && destinationNode) {
      chartRef.current.changeData(
        dataTransform(
          data?.graph,
          destinationNode,
          upstream,
          isPipelineDestination
        )
      );
    }
  }, [data?.graph, destinationNode, upstream, isPipelineDestination]);

  // First render
  useEffect(() => {
    if (destinationNode && data?.graph.length > 0 && !chartRef.current) {
      const chartData = dataTransform(
        data?.graph,
        destinationNode,
        upstream,
        isPipelineDestination
      );
      chartRef.current = new G6.Graph({
        container: containerRef.current,
        width: containerWidth,
        height: containerHeight,
        layout: {
          type: 'dagre',
          begin: [50, 50],
          rankdir: 'LR',
          align: 'UL',
          controlPoints: true,
          nodesep: 20,
          ranksep: 20,
        },
        data: chartData,
        fitView: true,
        fitCenter: true,
        minZoom: 0.8,
        maxZoom: data.graph.length <= 4 ? 1 : null,
        modes: {
          default: [GraphDefaultModes.DRAG_CANVAS, GraphDefaultModes.DRAG_NODE],
        },
        defaultEdge: {
          type: GraphDefaultTypes.DEFAULT_EDGE_OPEN,
        },
        defaultNode: {
          type: GraphDefaultTypes.DEFAULT_NODE_OPEN,
          size: [TABLE_WIDTH, TABLE_HEIGHT / 2],
          preRect: {
            show: false,
          },
        },
      });

      chartRef.current.on(`${GraphShapeNames.TITLE_SHAPE}:click`, onTitleClick);
      chartRef.current.render();
      chartRef.current.fitView();
    }
  }, [
    destinationNode,
    data?.graph,
    upstream,
    onTitleClick,
    containerHeight,
    containerWidth,
    isPipelineDestination,
    isSourceUri,
  ]);

  if (!data?.graph) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    );
  }

  const GraphComponent = (
    <div className={clsx(classes.container, classes.backgroundPattern)}>
      <div ref={containerRef} />
    </div>
  );

  if (isExistingLinkToLineage) {
    return (
      <Link
        to={{
          pathname: AppRoutes.Lineage.path,
          search: new URLSearchParams({
            tab: LineageTabs.PIPELINE,
            dataset: destinationTable?.dataset,
            table: destinationTable?.table,
          }).toString(),
        }}
        className='position-relative'
      >
        {GraphComponent}

        <div className={clsx(classes.hoverPattern, 'transitionEffect')}>
          <Button
            text='View in Pipeline Lineage'
            fullWidth={false}
            color={BUTTON_COLOR.secondary}
          />
        </div>
      </Link>
    );
  }

  return GraphComponent;
};

export { OpenGraphPipes };
