import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import { TableCell, TableRow } from '@mui/material';
import {
  OverflownText,
  PipelineDetails,
  RightSideModal,
} from 'Components/components.js';
import {
  JobTagsTableCell,
  SourceTableCell,
} from 'Pages/Pipelines/components/components.js';
import {
  bytesToSize,
  millisecondsToHoursTime,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import {
  DATE_FORMAT,
  FINISHED_ACTION_LABEL,
  FINISHED_ACTION_STATUS,
  RUNNING_ACTION_LABEL,
  RUNNING_ACTION_STATUS,
} from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { useModal } from 'context/context.js';

const { JOB_NAME_WIDTH, PRINCIPAL_EMAIL_WIDTH } = PipelineTableCellStyles;

const PipelineTableSourceRaw = ({
  destinationTable,
  sourceData,
  selectedTable,
  searchValue,
}) => {
  const { setModal } = useModal();

  const {
    tables,
    jobStatus,
    lastJobName,
    lastStartTime,
    lastDuration,
    principalEmail,
    jobTags,
    avgBytes,
    lastBytes,
    avgSlotsMs,
    lastSlotsMs,
    sourceUri,
    frequency,
    routines,
  } = sourceData;
  const { currentBqBillingData } = useBqBillingData();
  const isSlotsCellExists =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const isBytesCellExists =
    currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const jobStatusLabel = useMemo(() => {
    switch (jobStatus) {
      case RUNNING_ACTION_STATUS:
        return RUNNING_ACTION_LABEL;
      case FINISHED_ACTION_STATUS:
        return FINISHED_ACTION_LABEL;
      default:
        return 'N/A';
    }
  }, [jobStatus]);

  const sources = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  const onPipelineRawClick = () => {
    setModal(() => (
      <RightSideModal
        title='Pipeline details'
        subtitle={`Pipeline execution email: ${principalEmail}`}
        ModalBodyComponent={PipelineDetails}
        modalWidth={824}
        jobHash={sourceData.jobHash}
        jobTags={jobTags}
        lineageData={{
          destinationTable,
          tables: sources,
          sourceUri: sourceData.sourceUri,
          destinationUri: sourceData.destinationUri,
          pipeline: {
            pipelineType: destinationTable.pipelineType,
            sourceProject: sourceData.sourceProject,
          },
        }}
      />
    ));
  };

  return (
    <TableRow onClick={onPipelineRawClick}>
      <SourceTableCell
        sourceData={sources}
        sourceUri={sourceUri}
        selectedTable={selectedTable}
        searchValue={searchValue}
      />

      <TableCell>
        {jobStatusLabel}
        <OverflownText
          title={lastJobName}
          maxWidth={JOB_NAME_WIDTH}
          className='txt-grey-13-500'
        >
          {lastJobName}
        </OverflownText>
      </TableCell>

      <TableCell className='nowrapText'>
        {!lastStartTime.length ? (
          <span className='txt-grey-13-500'>N/A</span>
        ) : (
          moment(lastStartTime).utc().format(DATE_FORMAT.monthYearTime)
        )}
      </TableCell>

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      <TableCell align='right' className='nowrapText'>
        {frequency}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {lastDuration ? (
          millisecondsToHoursTime(lastDuration)
        ) : (
          <span className='txt-grey-13-500'>&mdash;</span>
        )}
      </TableCell>

      {isBytesCellExists && (
        <>
          <TableCell align='right' className='nowrapText'>
            {avgBytes ? (
              bytesToSize(avgBytes)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
          <TableCell align='right' className='nowrapText'>
            {lastBytes ? (
              bytesToSize(lastBytes)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
        </>
      )}

      {isSlotsCellExists && (
        <>
          <TableCell align='right' className='nowrapText'>
            {avgSlotsMs ? (
              millisecondsToTimeSlots(avgSlotsMs)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
          <TableCell align='right' className='nowrapText'>
            {lastSlotsMs ? (
              millisecondsToTimeSlots(lastSlotsMs)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export { PipelineTableSourceRaw };
