import React from 'react';
import {
  usePagination,
  useMemo,
  useState,
  useEffect,
  useLocation,
  useHistory,
  useCallback,
  useAutoChangeProject,
} from 'hooks/hooks.js';
import { useStyles } from './Incidents.styles.js';
import { BQTab, LookerTab, PipelineTab } from './components/components.js';
import {
  Pagination,
  Tabs,
  PageHeader,
  Backdrop,
} from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  IncidentTabs,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.incidents} -> Tab`;

const Incidents = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [existingDatePicker, setExistingDatePicker] = useState(true);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  useAutoChangeProject();

  const getCurrentTabFromUrl = () => {
    const tab = searchParams.get(SEARCH_PARAMS.TAB);
    if (tab && Object.values(IncidentTabs).includes(tab)) {
      return tab;
    }
    return IncidentTabs.GBQ;
  };

  const [currentTab, setCurrentTab] = useState(getCurrentTabFromUrl());
  const [totalIncidents, setTotalIncidents] = useState(0);
  const [titleTotalIncidents, setTitleTotalIncidents] = useState(0);
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);

  useEffect(() => amplEvent(AMPL_PAGE_EVENT.incidents), []);

  const handleChangeTotalIncidents = useCallback((value) => {
    setTotalIncidents(value);
  }, []);

  const handleChangeTab = useCallback(
    (selectedTab) => {
      if (searchParams.get(SEARCH_PARAMS.TAB) !== selectedTab) {
        searchParams.set(SEARCH_PARAMS.TAB, selectedTab);
        searchParams.delete(SEARCH_PARAMS.TABLE);
        searchParams.delete(SEARCH_PARAMS.DATASET);
        searchParams.delete(SEARCH_PARAMS.SEARCH);
        searchParams.delete(SEARCH_PARAMS.ID);
        searchParams.delete(SEARCH_PARAMS.TYPE);
        history.replace({
          pathname: location.pathname,
          search: searchParams.toString(),
          state: history.location.state,
        });
      }
      amplEvent(`${EVENT_CLICK} ${selectedTab}`);
      setCurrentTab(selectedTab);
    },
    [history, location.pathname, searchParams]
  );

  const handleChangeTitleTotalIncidents = useCallback((value) => {
    setTitleTotalIncidents(value);
  }, []);

  const handleChangeOpenBackdrop = useCallback((value) => {
    setIsOpenBackdrop(value);
  }, []);

  const handleChangeDateRangePicker = useCallback((value) => {
    setExistingDatePicker(value);
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case IncidentTabs.LOOKER:
        return (
          <LookerTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
      case IncidentTabs.PIPELINE:
        return (
          <PipelineTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
      default:
        return (
          <BQTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
    }
  };

  return (
    <>
      <PageHeader hasDateRangePicker={existingDatePicker}>
        Incidents ({numberFormat(titleTotalIncidents)})
      </PageHeader>

      <div className={classes.tableContainer}>
        <Tabs
          tabs={IncidentTabs}
          currentTab={currentTab}
          onTabClick={handleChangeTab}
        />

        {renderPage()}

        <Backdrop isOpen={isOpenBackdrop} isPageCentering={true} />
      </div>

      {!isOpenBackdrop && (
        <Pagination
          count={totalIncidents}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default Incidents;
