import React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useQuery,
  useHistory,
} from 'hooks/hooks.js';
import G6 from '@antv/g6';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { fetcherGet } from 'utils/utils.js';
import {
  convertLocalToUTCDate,
  defaultEndDate,
  defaultStartDate,
  getColumnTypeIcon,
  getFullTableName,
  numberFormat,
  shortFrequencyBySeconds,
} from 'utils/helpers/helpers.js';
import { dataTransform } from './utils.js';
import {
  Loader,
  Modal,
  PipelineDetails,
  PipelineDetailsSubtitle,
  RightSideModal,
  RoutineDetails,
} from 'Components/components.js';
import { normalMainWidth } from '../Layout/Layout.js';
import { AppRoutes } from 'app-routes.js';
import {
  GraphDefaultTypes,
  GraphShapeNames,
  GraphStyles,
  GraphStateNames,
  GraphDefaultModes,
  GraphModes,
  NodeTypes,
} from './enums/enums.js';
import { newLegend, newMinimap, newToolbar, newTooltip } from './plugins.js';
import {
  AMPL_PAGE_EVENT,
  DATE_FORMAT,
  LineageTabs,
  QUERY_TYPES,
} from 'constants/constants.js';
import {
  CRITICAL_ALERT_TYPE,
  MUTED_ALERT_TYPE,
  PIPELINE_TYPE,
  PIPELINE_TYPE_DATA,
  PRIORITY_ALERT_TYPE,
} from 'utils/constants.js';
import { amplEvent } from 'service/services.js';
import { useConfiguration, useModal, useUserInfo } from 'context/context.js';
import { ChangeProjectModalBody } from './libs/components/components.js';
import {
  getMaxTextLength,
  getNodeTypeImage,
  getNodeTypeName,
  getContainerWidthByText,
} from './libs/helpers/helpers.js';
import theme from 'theme.js';
import backgroundPoints from 'assets/img/backgrounds/points.svg';
import arrowUp from 'assets/img/icons/arrow-blue-up.svg';
import arrowDown from 'assets/img/icons/arrow-blue-down.svg';
import arrowRightIcon from 'assets/img/icons/sidebarIcons/arrowRight.svg';
import arrowLeftIcon from 'assets/img/icons/sidebarIcons/arrowLeft.svg';
import arrowLeftGreyIcon from 'assets/img/icons/sidebarIcons/arrowLeftGrey.svg';
import arrowRightGreyIcon from 'assets/img/icons/sidebarIcons/arrowRightGrey.svg';
import hiddenUpTablesArrowGrey from 'assets/img/icons/lineageIcons/arrow-up-grey.svg';
import hiddenUpTablesArrowBlue from 'assets/img/icons/lineageIcons/arrow-up-blue.svg';
import hiddenDownTablesArrowGrey from 'assets/img/icons/lineageIcons/arrow-down-grey.svg';
import hiddenDownTablesArrowBlue from 'assets/img/icons/lineageIcons/arrow-down-blue.svg';
import Critical from 'assets/img/alert/critical.svg';
import Priority from 'assets/img/alert/priority.svg';
import Regular from 'assets/img/alert/regular.svg';
import Mute from 'assets/img/alert/mute.svg';

const START_DATE = moment(convertLocalToUTCDate(defaultStartDate()))
  .utc()
  .format();
const END_DATE = moment(convertLocalToUTCDate(defaultEndDate())).utc().format();

const COLUMN_HEIGHT = 22;
const TAGS_HEIGHT = 18;

const TITLE_CONTAINER_WIDTH = 164;
const TABLE_WIDTH =
  GraphStyles.FONT_LEFT_WITH_ICON +
  TITLE_CONTAINER_WIDTH +
  GraphStyles.FONT_LEFT;
const TABLE_HEIGHT = 94;

const PIPELINE_NODE_TITLE_CONTAINER_WIDTH = 120;
const PIPELINE_NODE_WIDTH =
  GraphStyles.FONT_LEFT_WITH_ICON +
  PIPELINE_NODE_TITLE_CONTAINER_WIDTH +
  GraphStyles.FONT_LEFT;

const TOP_BOX_GROUP_HEIGHT = 56;
const PAGINATION_BLOCK_HEIGHT = 30;
const COLUMN_PAGINATION_LIMIT = 10;
const OFFSET_Y = 100;
const STREAM_BTN_TEXT_SIZE = 10;
const STREAM_BTN_SIZE = 20;
const PROJECT_NAME_BOX_SHIFT = 24;

const ALERT_TYPE_IMAGE_SIZE = 8;

const EDGE_LIMIT = 5;
const COLUMN_LIMIT = 10;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(4),
    backgroundImage: `url(${backgroundPoints})`,

    '& .viewButtonsContainer': {
      position: 'absolute',
      top: theme.spacing(4),
      left: theme.spacing(6),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,

      '& .viewButton': {
        padding: 7,
        color: theme.palette.common.black,
        fontSize: 13,
        lineHeight: '22px',
        fontWeight: 500,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        border: '1px solid transparent',

        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',

        '&:active': {
          boxShadow: '0 0 5px #BEC5E7',
        },

        '&.isActive': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
        },

        '&:first-child.isActive': {
          borderRight: `1px solid ${theme.palette.divider}`,
        },

        '&:last-child.isActive': {
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
      },
    },
  },
}));

const { registerBehavior, registerEdge, registerNode } = G6;

registerBehavior(GraphModes.TOGGLE_COLLAPSED, {
  getDefaultCfg() {
    return {
      multiple: true,
    };
  },
  getEvents() {
    return {
      COLUMN_HEIGHT,
      click: 'click',
    };
  },
  click(e) {
    e.stopPropagation();
    const { graph } = this;
    const item = e.item;
    const shape = e.shape;
    if (!item) {
      return;
    }

    const nodes = graph.getNodes();
    const node = nodes.find((it) => it.getID() === e.item.getID());
    if (!node) {
      return;
    }

    if (shape.get('name') === GraphShapeNames.COLLAPSE) {
      graph.updateItem(node, {
        collapsed: true,
        size: [TABLE_WIDTH, TABLE_HEIGHT / 2],
      });
      setTimeout(() => graph.layout(), 100);
    } else if (shape.get('name') === GraphShapeNames.EXPAND) {
      const model = e.item.getModel();
      graph.updateItem(node, {
        collapsed: false,
        size: [
          TABLE_WIDTH,
          TABLE_HEIGHT +
            model.attrs.length * COLUMN_HEIGHT * 2 +
            (model.totalAttrs > COLUMN_PAGINATION_LIMIT
              ? PAGINATION_BLOCK_HEIGHT
              : 0),
        ],
      });
      setTimeout(() => graph.layout(), 100);
    } else if (shape.get('name') === GraphShapeNames.COLLAPSE_TAGS) {
      graph.updateItem(node, {
        tagsCollapsed: true,
        size: [TABLE_WIDTH, TABLE_HEIGHT / 2],
      });
      setTimeout(() => graph.layout(), 100);
    } else if (shape.get('name') === GraphShapeNames.EXPAND_TAGS) {
      const model = e.item.getModel();
      const totalTags = model.nodeTypeData?.jobTags.length;

      graph.updateItem(node, {
        tagsCollapsed: false,
        size: [TABLE_WIDTH, TABLE_HEIGHT + (totalTags + 1) * TAGS_HEIGHT * 2],
      });
      setTimeout(() => graph.layout(), 100);
    }
  },
});

registerEdge(GraphDefaultTypes.DEFAULT_EDGE, {
  draw(cfg, group) {
    const edge = group.cfg.item;
    const sourceNode = edge.getSource().getModel();
    const targetNode = edge.getTarget().getModel();

    const sourceIndex = sourceNode.attrs.findIndex(
      (column) => column.key === cfg.sourceKey
    );

    let sourceY = 40;
    let targetY = 40;

    if (
      sourceNode.collapsed !== undefined &&
      !sourceNode.collapsed &&
      sourceIndex >= 0
    ) {
      sourceY = PROJECT_NAME_BOX_SHIFT + OFFSET_Y + sourceIndex * COLUMN_HEIGHT;
      sourceY = Math.min(sourceY);
    }

    if (
      sourceNode.collapsed !== undefined &&
      !sourceNode.collapsed &&
      sourceIndex === -1 &&
      cfg.sourceKey.length > 0 &&
      sourceNode.totalAttrs > COLUMN_PAGINATION_LIMIT
    ) {
      sourceY =
        PROJECT_NAME_BOX_SHIFT +
        OFFSET_Y +
        sourceNode.attrs.length * COLUMN_HEIGHT +
        PAGINATION_BLOCK_HEIGHT / 4;
      sourceY = Math.min(sourceY);
    }

    const targetIndex = targetNode.attrs.findIndex(
      (column) => column.key === cfg.targetKey
    );

    if (
      targetNode.collapsed !== undefined &&
      !targetNode.collapsed &&
      targetIndex >= 0
    ) {
      targetY = PROJECT_NAME_BOX_SHIFT + OFFSET_Y + targetIndex * COLUMN_HEIGHT;
      targetY = Math.min(targetY);
    }

    if (
      targetNode.collapsed !== undefined &&
      !targetNode.collapsed &&
      targetIndex === -1 &&
      cfg.targetKey.length > 0 &&
      targetNode.totalAttrs > COLUMN_PAGINATION_LIMIT
    ) {
      targetY =
        PROJECT_NAME_BOX_SHIFT +
        OFFSET_Y +
        targetNode.attrs.length * COLUMN_HEIGHT +
        PAGINATION_BLOCK_HEIGHT / 4;
      targetY = Math.min(targetY);
    }

    const startPoint = { ...cfg.startPoint };
    const endPoint = { ...cfg.endPoint };

    startPoint.y = startPoint.y + sourceY;
    endPoint.y = endPoint.y + targetY;

    let shape;

    if (sourceNode.id !== targetNode.id) {
      shape = group.addShape('path', {
        attrs: {
          stroke: theme.palette.divider,
          path: [
            ['M', startPoint.x, startPoint.y],
            [
              'C',
              endPoint.x / 3 + (2 / 3) * startPoint.x,
              startPoint.y,
              endPoint.x / 3 + (2 / 3) * startPoint.x,
              endPoint.y,
              endPoint.x,
              endPoint.y,
            ],
          ],
          endArrow: {
            path: G6.Arrow.triangleRect(8, 8, 8, 0, 0),
            fill: theme.palette.divider,
          },
        },
        name: GraphShapeNames.PATH_SHAPE,
      });
    } else if (!sourceNode.collapsed) {
      let gap = Math.abs((startPoint.y - endPoint.y) / 3);
      if (startPoint['index'] === 1) {
        gap = -gap;
      }
      shape = group.addShape('path', {
        attrs: {
          stroke: theme.palette.divider,
          path: [
            ['M', startPoint.x, startPoint.y],
            [
              'C',
              startPoint.x - gap,
              startPoint.y,
              startPoint.x - gap,
              endPoint.y,
              startPoint.x,
              endPoint.y,
            ],
          ],
          endArrow: {
            path: G6.Arrow.triangleRect(8, 8, 8, 0, 0),
            fill: theme.palette.divider,
          },
        },
        name: GraphShapeNames.PATH_SHAPE,
      });
    }

    return shape;
  },

  afterDraw(cfg, group) {
    const edge = group.cfg.item;
    edge.toBack();
  },
});

registerNode(GraphDefaultTypes.DEFAULT_NODE, {
  draw(cfg, group) {
    const itemModel = group.cfg.item.getModel();
    const { totalAttrs, collOffset = 0, nodeTypeData } = itemModel;
    const {
      pipelineType = null,
      attrsNumber: columnsNumber = 0,
      datasetType = null,
      frequency = null,
      jobTags = [],
      lastStartTime = null,
      alertType = null,
    } = nodeTypeData || {};

    const {
      isCurrentTable,
      isDownstream,
      isUpstream,
      isNextUpstreamHidden,
      isNextDownstreamHidden,
      isAnomalyTable,
      isGrayTable,
      total,
      totalUpstreamEdges,
      totalDownstreamEdges,
      upEdgeOffset,
      downEdgeOffset,
      inEdgeLength = [],
      outEdgeLength = [],
      mainNodeProject,
      hidedDownEdgesNumber,
      hidedUpEdgesNumber,
    } = itemModel?.visibilityProps || {};
    const isPipelineNode = itemModel?.nodeType === NodeTypes.PIPELINE;

    const isAddDownstreamBtnVisible =
      outEdgeLength > 0 &&
      isNextDownstreamHidden &&
      !isPipelineNode &&
      (isCurrentTable || isDownstream);
    const isAddUpstreamBtnVisible =
      inEdgeLength > 0 &&
      isNextUpstreamHidden &&
      !isPipelineNode &&
      (isCurrentTable || isUpstream);

    const isRemoveDownstreamBtnVisible =
      outEdgeLength > 0 &&
      !isNextDownstreamHidden &&
      !isPipelineNode &&
      (isCurrentTable || isDownstream);
    const isRemoveUpstreamBtnVisible =
      inEdgeLength > 0 &&
      !isNextUpstreamHidden &&
      !isPipelineNode &&
      (isCurrentTable || isUpstream);

    const boxStyle = {
      stroke: theme.palette.common.white,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowBlur: 8,
      radius: 12,
    };

    const {
      attrs: columns = [],
      collapsed = true,
      tagsCollapsed = true,
      nodeType,
    } = cfg;

    const isDatasetNode = nodeType === NodeTypes.DATASET;
    const isRoutineNode = nodeType === NodeTypes.ROUTINE;

    const nodeProject =
      (isDatasetNode && cfg.id.split('.')[0]) ||
      (isPipelineNode && cfg.project) ||
      (isRoutineNode && cfg.project) ||
      null;

    const isExistingProjectNameBox =
      nodeProject && mainNodeProject !== nodeProject;
    const isExistingTagsBlock = jobTags?.length > 0;
    const isExistingShowMoreTagsBlock = jobTags?.length > 1;

    const tableNodeHeight = collapsed
      ? TABLE_HEIGHT
      : TABLE_HEIGHT +
        columns.length * COLUMN_HEIGHT +
        (totalAttrs > COLUMN_PAGINATION_LIMIT ? PAGINATION_BLOCK_HEIGHT : 0);

    const pipelineNodeHeight = !isExistingTagsBlock
      ? TOP_BOX_GROUP_HEIGHT
      : tagsCollapsed
      ? TOP_BOX_GROUP_HEIGHT +
        (isExistingShowMoreTagsBlock ? 2 : 1) * TAGS_HEIGHT
      : TOP_BOX_GROUP_HEIGHT + (jobTags.length + 1) * TAGS_HEIGHT;

    const relationStats = [];
    const totalUpstr = total?.upstream
      ? total.upstream.tables +
        total.upstream.sourceUris +
        total.upstream.routines
      : 0;
    const totalDownstr = total?.downstream
      ? total.downstream.tables + total.downstream.destinationUris
      : 0;
    const totalReports = total?.downstream.reports || 0;
    if (total?.upstreamEdges > 0 && totalUpstr > 0) {
      relationStats.push(
        `${numberFormat(
          total.upstream.tables +
            total.upstream.sourceUris +
            +total.upstream.routines
        )} upstr`
      );
    }
    if (totalDownstr > 0) {
      relationStats.push(
        `${numberFormat(
          total.downstream.tables + total.downstream.destinationUris
        )} downstr`
      );
    }
    if (totalReports > 0) {
      relationStats.push(`${numberFormat(total.downstream.reports)} reports`);
    }

    const nodeTypeText = getNodeTypeName(nodeType, datasetType);
    const subTitle = [nodeTypeText, ...relationStats];

    if (frequency !== null && pipelineType !== PIPELINE_TYPE.BROWSER) {
      subTitle.push(shortFrequencyBySeconds(frequency));
    }

    if (lastStartTime !== null && pipelineType === PIPELINE_TYPE.BROWSER) {
      subTitle.push(
        moment(lastStartTime).utc().format(DATE_FORMAT.monthYearTime)
      );
    }

    const subTitleText = subTitle.join(' • ');
    const subtitleWidth = getContainerWidthByText(
      subTitleText,
      isPipelineNode
        ? PIPELINE_NODE_TITLE_CONTAINER_WIDTH
        : TITLE_CONTAINER_WIDTH
    );

    const getNodeWidth = () => {
      if (isPipelineNode) {
        return PIPELINE_NODE_TITLE_CONTAINER_WIDTH > subtitleWidth
          ? PIPELINE_NODE_WIDTH
          : subtitleWidth +
              GraphStyles.FONT_LEFT_WITH_ICON +
              GraphStyles.FONT_LEFT;
      }

      return TITLE_CONTAINER_WIDTH > subtitleWidth
        ? TABLE_WIDTH
        : subtitleWidth + GraphStyles.FONT_LEFT_WITH_ICON;
    };

    const nodeWidth = getNodeWidth();

    /** project name block */
    const shape = group.addShape('rect', {
      attrs: {
        x: 0,
        y: -PROJECT_NAME_BOX_SHIFT,
        width: nodeWidth,
        height: 38,
        fill: theme.palette.secondary.light,
        radius: [12, 12, 0, 0],
        shadowColor: GraphStyles.SHADOW_COLOR,
      },
      visible: isExistingProjectNameBox,
      draggable: true,
      name: GraphShapeNames.PROJECT_NAME_BOX,
    });

    if (isExistingProjectNameBox) {
      const projectNameBoxGroup = group.addGroup({
        name: GraphShapeNames.PROJECT_NAME_BOX,
      });

      projectNameBoxGroup.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: -8,
          fill: theme.palette.text.secondary,
          text: getMaxTextLength(
            nodeProject.toUpperCase(),
            TABLE_WIDTH - GraphStyles.FONT_LEFT * 2,
            9
          ),
          fontSize: 9,
          fontWeight: 500,
          lineHeight: 11,
        },
        name: GraphShapeNames.PROJECT_NAME_TEXT,
      });
    }

    group.addShape('rect', {
      attrs: {
        x: 0,
        y: 0,
        width: nodeWidth,
        height: isDatasetNode
          ? tableNodeHeight
          : isPipelineNode
          ? pipelineNodeHeight
          : TOP_BOX_GROUP_HEIGHT,
        ...boxStyle,
        fill: isPipelineNode
          ? theme.palette.secondary.light
          : theme.palette.common.white,
        stroke: isCurrentTable && theme.palette.primary.main,
        shadowColor: isCurrentTable
          ? theme.palette.common.white
          : GraphStyles.SHADOW_COLOR,
      },
      draggable: true,
      name: GraphShapeNames.MAIN_BOX,
    });

    group.addShape('rect', {
      attrs: {
        x: 0,
        y: 0,
        width: nodeWidth,
        height: TOP_BOX_GROUP_HEIGHT,
        fill: 'transparent',
        cursor: isPipelineNode || isRoutineNode ? 'pointer' : 'default',
      },
      draggable: true,
      name: GraphShapeNames.TOP_BOX_GROUP,
    });

    /** hidden tables block */
    if (!isPipelineNode) {
      const hiddenBlockText =
        hidedDownEdgesNumber > 0 && isUpstream
          ? `${numberFormat(hidedDownEdgesNumber)} hidden downstreams  |  Open`
          : hidedUpEdgesNumber > 0 && isDownstream
          ? `${numberFormat(hidedUpEdgesNumber)} hidden upstreams  |  Open`
          : '';

      const hiddenBlockWidth =
        getContainerWidthByText(hiddenBlockText, TITLE_CONTAINER_WIDTH, 11) +
        16;

      const existingHiddenDownEdges = hidedDownEdgesNumber > 0 && isUpstream;
      const existingHiddenUpEdges = hidedUpEdgesNumber > 0 && isDownstream;

      const hiddenTablesGroup =
        (existingHiddenDownEdges || existingHiddenUpEdges) && !isCurrentTable
          ? group.addGroup({
              name: GraphShapeNames.HIDDEN_TABLES_GROUP,
            })
          : null;

      hiddenTablesGroup &&
        hiddenTablesGroup.addShape('rect', {
          attrs: {
            x: existingHiddenDownEdges ? 120 : -120,
            y: -40,
            width: hiddenBlockWidth,
            height: 42,
            fill: 'transparent',
            radius: 12,
            opacity: 0,
          },
          draggable: true,
          name: GraphShapeNames.TRANSPARENT_TABLES_BOX,
        });

      hiddenTablesGroup &&
        hiddenTablesGroup.addShape('rect', {
          attrs: {
            x: existingHiddenDownEdges ? 120 : -120,
            y: -40,
            width: hiddenBlockWidth,
            height: 30,
            fill: theme.palette.text.primary,
            radius: 12,
            cursor: 'pointer',
            opacity: 0,
          },
          draggable: true,
          name: GraphShapeNames.HIDDEN_TABLES_BOX,
        });

      hiddenTablesGroup &&
        hiddenTablesGroup.addShape('text', {
          attrs: {
            x: existingHiddenDownEdges ? 132 : -108,
            y: -20,
            fill: theme.palette.common.white,
            text: hiddenBlockText,
            fontSize: 11,
            fontWeight: 500,
            lineHeight: 14,
            cursor: 'pointer',
            opacity: 0,
          },
          name: GraphShapeNames.HIDDEN_TABLES_TEXT,
        });

      hiddenTablesGroup &&
        hiddenTablesGroup.addShape('image', {
          attrs: {
            x: existingHiddenDownEdges ? TABLE_WIDTH : -40,
            y: -10,
            img: existingHiddenDownEdges
              ? hiddenDownTablesArrowGrey
              : hiddenUpTablesArrowGrey,
            opacity: 1,
          },
          name: GraphShapeNames.HIDDEN_TABLES_ARROW_GREY,
        });

      hiddenTablesGroup &&
        hiddenTablesGroup.addShape('image', {
          attrs: {
            x: existingHiddenDownEdges ? TABLE_WIDTH : -40,
            y: -10,
            img: existingHiddenDownEdges
              ? hiddenDownTablesArrowBlue
              : hiddenUpTablesArrowBlue,
            opacity: 0,
          },
          name: GraphShapeNames.HIDDEN_TABLES_ARROW_BLUE,
        });
    }

    const topBoxGroup = group.addGroup({
      name: GraphShapeNames.TOP_BOX_GROUP,
    });

    /** table name */
    const pipelineNodeTitle = isPipelineNode
      ? PIPELINE_TYPE_DATA[pipelineType]?.title
      : '';

    const getNodeTitle = () => {
      if (pipelineNodeTitle.length) {
        return getMaxTextLength(
          pipelineNodeTitle,
          PIPELINE_NODE_TITLE_CONTAINER_WIDTH - GraphStyles.FONT_LEFT,
          11
        );
      }

      return getMaxTextLength(
        cfg.label,
        TITLE_CONTAINER_WIDTH - GraphStyles.FONT_LEFT,
        11
      );
    };

    const nodeTitle = getNodeTitle();

    topBoxGroup.addShape('text', {
      attrs: {
        x: GraphStyles.FONT_LEFT_WITH_ICON,
        y: 28,
        fill: theme.palette.text.primary,
        text: nodeTitle,
        fullTableName: cfg.id,
        nodeType: nodeType,
        fontSize: 11,
        fontWeight: 700,
        lineHeight: 14,
        cursor: 'pointer',
      },
      name: GraphShapeNames.TITLE_SHAPE,
    });

    topBoxGroup.addShape('text', {
      attrs: {
        x: GraphStyles.FONT_LEFT_WITH_ICON,
        y: 42,
        fill: theme.palette.text.secondary,
        text: subTitleText,
        fontFamily: theme.typography.fontFamily,
        fontSize: 9,
        fontWeight: 500,
        lineHeight: 14,
        cursor: isPipelineNode || isRoutineNode ? 'pointer' : 'default',
      },
      name: GraphShapeNames.SUBTITLE_SHAPE,
    });

    topBoxGroup.addShape('line', {
      attrs: {
        x1: 0,
        x2: nodeWidth,
        y1: TOP_BOX_GROUP_HEIGHT,
        y2: TOP_BOX_GROUP_HEIGHT,
        stroke: isDatasetNode ? theme.palette.divider : 'transparent',
        lineWidth: 1,
      },
      draggable: true,
      name: GraphShapeNames.DIVIDER_SHAPE,
    });

    /** node images */
    const getNodeImageFill = () => {
      if (nodeType === NodeTypes.DATASET) {
        return isAnomalyTable
          ? theme.palette.error.main
          : isGrayTable
          ? theme.palette.secondary.light
          : theme.palette.secondary.main;
      }

      if (nodeType === NodeTypes.PIPELINE) {
        return theme.palette.common.white;
      }

      return theme.palette.secondary.main;
    };

    const nodeImageColor = isAnomalyTable
      ? theme.palette.error.main
      : isGrayTable
      ? theme.palette.text.secondary
      : theme.palette.primary.main;

    topBoxGroup.addShape('rect', {
      attrs: {
        x: GraphStyles.FONT_LEFT,
        y: 16,
        width: 24,
        height: 24,
        radius: 8,
        fill: getNodeImageFill(),
        opacity: isAnomalyTable ? 0.1 : 1,
        cursor: isPipelineNode || isRoutineNode ? 'pointer' : 'default',
      },
      name: GraphShapeNames.NODE_ICON,
    });

    topBoxGroup.addShape('image', {
      attrs: {
        x: GraphStyles.FONT_LEFT + 4,
        y: 16 + 4,
        width: 16,
        height: 16,
        img: getNodeTypeImage(
          nodeType,
          datasetType,
          pipelineType,
          nodeImageColor
        ),
        cursor: isPipelineNode || isRoutineNode ? 'pointer' : 'default',
      },
      name: GraphShapeNames.NODE_ICON,
    });

    /** node alert type images */
    if (
      alertType === CRITICAL_ALERT_TYPE ||
      alertType === PRIORITY_ALERT_TYPE
    ) {
      const getAlertTypeImageFill = () => {
        switch (alertType) {
          case CRITICAL_ALERT_TYPE:
            return '#FFEEAE';
          case PRIORITY_ALERT_TYPE:
            return '#FEFFD0';
          case MUTED_ALERT_TYPE:
            return '#EEE';
          default:
            return theme.palette.secondary.main;
        }
      };

      const getAlertTypeImage = () => {
        switch (alertType) {
          case CRITICAL_ALERT_TYPE:
            return Critical;
          case PRIORITY_ALERT_TYPE:
            return Priority;
          case MUTED_ALERT_TYPE:
            return Mute;
          default:
            return Regular;
        }
      };

      topBoxGroup.addShape('circle', {
        attrs: {
          x: 36,
          y: 16,
          r: 8,
          fill: getAlertTypeImageFill(),
          lineWidth: 0.75,
          stroke: 'rgba(5, 12, 46, 0.10)',
        },
        name: GraphShapeNames.NODE_ALERT_TYPE_ICON,
      });

      topBoxGroup.addShape('image', {
        attrs: {
          x: 36 - ALERT_TYPE_IMAGE_SIZE / 2,
          y: 16 - ALERT_TYPE_IMAGE_SIZE / 2,
          width: ALERT_TYPE_IMAGE_SIZE,
          height: ALERT_TYPE_IMAGE_SIZE,
          img: getAlertTypeImage(),
        },
        name: GraphShapeNames.NODE_ALERT_TYPE_ICON,
      });
    }

    /** tags block */
    if (jobTags?.length > 0 && isPipelineNode) {
      const totalJobTags = jobTags.length;
      const hiddenTotalJobTags = totalJobTags - 1;
      const visibleTagName =
        jobTags[0].name.length > GraphStyles.MAX_TAG_NAME_LENGTH
          ? jobTags[0].name.slice(0, GraphStyles.MAX_TAG_NAME_LENGTH) + '...'
          : jobTags[0].name;

      group.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: 62,
          text: visibleTagName,
          fill: theme.palette.text.secondary,
          fontFamily: theme.typography.fontFamily,
          fontSize: 11,
          lineHeight: 14,
          fontWeight: 500,
          cursor: 'pointer',
        },
        name: `${GraphShapeNames.VISIBLE_TAG_NAME}/${jobTags[0].name}`,
      });

      if (isExistingShowMoreTagsBlock) {
        const hiddenJobTagsText =
          hiddenTotalJobTags === 1
            ? `+${hiddenTotalJobTags} tag / label`
            : `+${hiddenTotalJobTags} tags / labels`;

        group.addShape('text', {
          attrs: {
            x: GraphStyles.FONT_LEFT,
            y: tagsCollapsed ? 78 : 78 + hiddenTotalJobTags * TAGS_HEIGHT,
            text: tagsCollapsed ? hiddenJobTagsText : 'Show less',
            fill: theme.palette.text.secondary,
            fillOpacity: 0.7,
            fontFamily: theme.typography.fontFamily,
            fontSize: 11,
            lineHeight: 14,
            fontWeight: 500,
            cursor: 'pointer',
          },
          name: tagsCollapsed
            ? GraphShapeNames.EXPAND_TAGS
            : GraphShapeNames.COLLAPSE_TAGS,
        });
      }

      if (!tagsCollapsed) {
        const hiddenJobTags = jobTags.slice(1);
        hiddenJobTags.forEach((jobTag, i) => {
          const { name } = jobTag;
          const label =
            name.length > GraphStyles.MAX_TAG_NAME_LENGTH
              ? name.slice(0, GraphStyles.MAX_TAG_NAME_LENGTH) + '...'
              : name;

          group.addShape('text', {
            attrs: {
              x: GraphStyles.FONT_LEFT,
              y: 78 + i * TAGS_HEIGHT,
              text: label,
              fill: theme.palette.text.secondary,
              fontFamily: theme.typography.fontFamily,
              fontSize: 11,
              lineHeight: 14,
              fontWeight: 500,
              cursor: 'pointer',
            },
            name: `${GraphShapeNames.VISIBLE_TAG_NAME}/${name}`,
          });
        });
      }
    }

    /** collapse rect */
    /** + location */
    group.addShape('rect', {
      visible: isAddDownstreamBtnVisible,
      attrs: {
        x: nodeWidth + GraphStyles.FONT_LEFT,
        y: 56,
        width: STREAM_BTN_SIZE,
        height: STREAM_BTN_SIZE,
        radius: 4,
        fill: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.ADD_DOWNSTREAM_BTN,
    });
    group.addShape('text', {
      visible: isAddDownstreamBtnVisible,
      attrs: {
        x: nodeWidth + GraphStyles.FONT_LEFT + 6,
        y: 71,
        text: '+',
        width: STREAM_BTN_TEXT_SIZE,
        height: STREAM_BTN_TEXT_SIZE,
        fill: theme.palette.primary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.ADD_DOWNSTREAM_BTN_TEXT,
    });
    group.addShape('rect', {
      visible: isAddUpstreamBtnVisible,
      attrs: {
        x: -(STREAM_BTN_SIZE + GraphStyles.FONT_LEFT),
        y: 56,
        width: STREAM_BTN_SIZE,
        height: STREAM_BTN_SIZE,
        radius: 4,
        fill: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.ADD_UPSTREAM_BTN,
    });
    group.addShape('text', {
      visible: isAddUpstreamBtnVisible,
      attrs: {
        x: -(GraphStyles.FONT_LEFT + 13),
        y: 71,
        text: '+',
        width: STREAM_BTN_TEXT_SIZE,
        height: STREAM_BTN_TEXT_SIZE,
        fill: theme.palette.primary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.ADD_UPSTREAM_BTN_TEXT,
    });

    group.addShape('rect', {
      visible: isRemoveDownstreamBtnVisible,
      attrs: {
        x: nodeWidth + GraphStyles.FONT_LEFT,
        y: 56,
        width: STREAM_BTN_SIZE,
        height: STREAM_BTN_SIZE,
        radius: 4,
        fill: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.REMOVE_DOWNSTREAM_BTN,
    });
    group.addShape('text', {
      visible: isRemoveDownstreamBtnVisible,
      attrs: {
        x: nodeWidth + GraphStyles.FONT_LEFT + 8,
        y: 71,
        text: '-',
        width: STREAM_BTN_TEXT_SIZE,
        height: STREAM_BTN_TEXT_SIZE,
        fill: theme.palette.primary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.REMOVE_DOWNSTREAM_BTN_TEXT,
    });
    if (
      (isRemoveDownstreamBtnVisible || isPipelineNode) &&
      downEdgeOffset > 0
    ) {
      group.addShape('image', {
        attrs: {
          x: isPipelineNode
            ? nodeWidth + GraphStyles.FONT_LEFT - 8
            : nodeWidth + GraphStyles.FONT_LEFT + 4,
          y: isPipelineNode ? 32 : 42,
          width: 12,
          height: 6,
          cursor: 'pointer',
          img: arrowUp,
        },
        name: GraphShapeNames.DOWNSTREAM_PREV_BTN,
        subtreeID: itemModel.id,
      });
    }
    if (
      (isRemoveDownstreamBtnVisible || isPipelineNode) &&
      downEdgeOffset + EDGE_LIMIT < totalDownstreamEdges
    ) {
      group.addShape('image', {
        attrs: {
          x: isPipelineNode
            ? nodeWidth + GraphStyles.FONT_LEFT - 8
            : nodeWidth + GraphStyles.FONT_LEFT + 4,
          y: isPipelineNode ? 46 : 84,
          width: 12,
          height: 6,
          cursor: 'pointer',
          img: arrowDown,
        },
        name: GraphShapeNames.DOWNSTREAM_NEXT_BTN,
        subtreeID: itemModel.id,
      });
    }

    group.addShape('rect', {
      visible: isRemoveUpstreamBtnVisible,
      attrs: {
        x: -(STREAM_BTN_SIZE + GraphStyles.FONT_LEFT),
        y: 56,
        width: STREAM_BTN_SIZE,
        height: STREAM_BTN_SIZE,
        radius: 4,
        fill: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.REMOVE_UPSTREAM_BTN,
    });
    group.addShape('text', {
      visible: isRemoveUpstreamBtnVisible,
      attrs: {
        x: -(GraphStyles.FONT_LEFT + 12),
        y: 71,
        text: '-',
        width: STREAM_BTN_TEXT_SIZE,
        height: STREAM_BTN_TEXT_SIZE,
        fill: theme.palette.primary.main,
        cursor: 'pointer',
      },
      name: GraphShapeNames.REMOVE_UPSTREAM_BTN_TEXT,
    });
    if ((isRemoveUpstreamBtnVisible || isPipelineNode) && upEdgeOffset > 0) {
      group.addShape('image', {
        attrs: {
          x: isPipelineNode
            ? -(GraphStyles.FONT_LEFT + 4)
            : -(GraphStyles.FONT_LEFT * 2 + 4),
          y: isPipelineNode ? 32 : 42,
          width: 12,
          height: 6,
          cursor: 'pointer',
          img: arrowUp,
        },
        name: GraphShapeNames.UPSTREAM_PREV_BTN,
        subtreeID: itemModel.id,
      });
    }

    if (
      (isRemoveUpstreamBtnVisible || isPipelineNode) &&
      upEdgeOffset + EDGE_LIMIT < totalUpstreamEdges
    ) {
      group.addShape('image', {
        attrs: {
          x: isPipelineNode
            ? -(GraphStyles.FONT_LEFT + 4)
            : -(GraphStyles.FONT_LEFT * 2 + 4),
          y: isPipelineNode ? 46 : 84,
          width: 12,
          height: 6,
          cursor: 'pointer',
          img: arrowDown,
        },
        name: GraphShapeNames.UPSTREAM_NEXT_BTN,
        subtreeID: itemModel.id,
      });
    }

    /** columns block */
    if (totalAttrs > 0) {
      group.addShape('image', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: 74,
          img: collapsed ? arrowDown : arrowUp,
        },
        name: GraphShapeNames.NODE_ICON,
      });

      group.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT * 2 + 4,
          y: 82,
          text: totalAttrs + ' columns',
          width: nodeWidth,
          fill: theme.palette.primary.main,
          fontFamily: theme.typography.fontFamily,
          fontSize: 11,
          lineHeight: 14,
          fontWeight: 500,
          cursor: 'pointer',
        },
        name: collapsed ? GraphShapeNames.EXPAND : GraphShapeNames.COLLAPSE,
      });
    }

    if (columnsNumber > 0 && columns.length === 0) {
      group.addShape('text', {
        attrs: {
          x: GraphStyles.FONT_LEFT,
          y: 82,
          text: columnsNumber + ' columns',
          width: nodeWidth,
          fill: theme.palette.text.secondary,
          fontFamily: theme.typography.fontFamily,
          fontSize: 11,
          lineHeight: 14,
          fontWeight: 500,
        },
        name: GraphShapeNames.DISABLE_COLUMNS,
      });
    }

    if (collapsed) {
      return shape;
    }

    const columnsListContainer = group.addGroup({});
    const paginationContainer = group.addGroup({});
    columns.forEach((column, i) => {
      const { key = '', type, hasConnection } = column;
      const label =
        key.length > GraphStyles.MAX_COLUMN_NAME_LENGTH
          ? key.slice(0, GraphStyles.MAX_COLUMN_NAME_LENGTH) + '...'
          : key;

      columnsListContainer.addShape('rect', {
        attrs: {
          x: 8,
          y: i * COLUMN_HEIGHT + OFFSET_Y - 11,
          width: nodeWidth - 16,
          height: 22,
          fill: 'transparent',
          lineWidth: 0.5,
          stroke: 'transparent',
          radius: 2,
          cursor: hasConnection && 'pointer',
        },
        draggable: true,
        name: `column-${i}-container`,
      });

      const columnsListContainerGroup = columnsListContainer.addGroup({
        name: `column-${i}-container`,
      });

      columnsListContainerGroup.addShape('text', {
        attrs: {
          x: type ? GraphStyles.FONT_LEFT + 40 : GraphStyles.FONT_LEFT,
          y: i * COLUMN_HEIGHT + OFFSET_Y + 6,
          text: label,
          fontSize: 11,
          fill: hasConnection
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
          fontFamily: 'Inter, Arial, sans-serif !important',
          cursor: hasConnection && 'pointer',
        },
        name: `column-${i}`,
      });

      if (type) {
        columnsListContainerGroup.addShape('image', {
          attrs: {
            x: GraphStyles.FONT_LEFT,
            y: i * COLUMN_HEIGHT + OFFSET_Y - 6,
            img: getColumnTypeIcon(type),
            cursor: hasConnection && 'pointer',
          },
          name: `column-${i}-icon`,
        });
      }
    });
    if (totalAttrs > COLUMN_PAGINATION_LIMIT) {
      let textX = 98;
      if (collOffset >= 10 && collOffset < 100) {
        textX = 95;
      }
      if (collOffset >= 100 && collOffset < 1000) {
        textX = 90;
      }
      if (collOffset >= 1000) {
        textX = 84;
      }
      paginationContainer.addShape('text', {
        attrs: {
          x: textX,
          y: columns.length * COLUMN_HEIGHT + OFFSET_Y + 10,
          text: `${collOffset} - ${collOffset + COLUMN_PAGINATION_LIMIT}`,
          fontSize: 11,
          fontWeight: 500,
          fill: theme.palette.text.primary,
          fontFamily: 'Inter, Arial, sans-serif !important',
        },
        name: GraphShapeNames.PAGINATION_TEXT,
      });
      if (collOffset === 0) {
        paginationContainer.addShape('image', {
          attrs: {
            x: 58,
            y: columns.length * COLUMN_HEIGHT + OFFSET_Y,
            img: arrowLeftGreyIcon,
            cursor: 'not-allowed',
          },
          name: GraphShapeNames.PAGINATION_LEFT_GREY,
        });
      } else {
        paginationContainer.addShape('image', {
          attrs: {
            x: 58,
            y: columns.length * COLUMN_HEIGHT + OFFSET_Y,
            img: arrowLeftIcon,
            cursor: 'pointer',
          },
          name: GraphShapeNames.PAGINATION_LEFT,
        });
      }
      if (collOffset + COLUMN_PAGINATION_LIMIT >= totalAttrs) {
        paginationContainer.addShape('image', {
          attrs: {
            x: 158,
            y: columns.length * COLUMN_HEIGHT + OFFSET_Y,
            img: arrowRightGreyIcon,
            cursor: 'not-allowed',
          },
          name: GraphShapeNames.PAGINATION_RIGHT_GREY,
        });
      } else {
        paginationContainer.addShape('image', {
          attrs: {
            x: 158,
            y: columns.length * COLUMN_HEIGHT + OFFSET_Y,
            img: arrowRightIcon,
            cursor: 'pointer',
          },
          name: GraphShapeNames.PAGINATION_RIGHT,
        });
      }
    }

    return shape;
  },

  setState(name, value, item) {
    const group = item.get('group');
    const itemModel = group.cfg.item.getModel();
    const { isCurrentTable } = itemModel?.visibilityProps || {};
    const mainBox = group.find(
      (e) => e.get('name') === GraphShapeNames.MAIN_BOX
    );
    const hiddenTablesBox = group.find(
      (e) => e.get('name') === GraphShapeNames.HIDDEN_TABLES_BOX
    );
    const hiddenTablesText = group.find(
      (e) => e.get('name') === GraphShapeNames.HIDDEN_TABLES_TEXT
    );
    const hiddenTablesArrowGrey = group.find(
      (e) => e.get('name') === GraphShapeNames.HIDDEN_TABLES_ARROW_GREY
    );
    const hiddenTablesArrowBlue = group.find(
      (e) => e.get('name') === GraphShapeNames.HIDDEN_TABLES_ARROW_BLUE
    );
    const edges = group.cfg.item.getEdges();

    if (name === GraphStateNames.TOP_BOX_GROUP_HOVER) {
      if (value) {
        item.toFront();
      }

      mainBox.attr({
        stroke:
          value || isCurrentTable ? theme.palette.primary.main : 'transparent',
        shadowColor:
          value || isCurrentTable
            ? theme.palette.common.white
            : GraphStyles.SHADOW_COLOR,
      });

      hiddenTablesBox && hiddenTablesBox.attr({ opacity: value ? 1 : 0 });
      hiddenTablesText && hiddenTablesText.attr({ opacity: value ? 1 : 0 });
      hiddenTablesArrowGrey &&
        hiddenTablesArrowGrey.attr({ opacity: value ? 0 : 1 });
      hiddenTablesArrowBlue &&
        hiddenTablesArrowBlue.attr({ opacity: value ? 1 : 0 });

      edges.forEach((edge) => {
        const pathBox = edge._cfg.group.find(
          (e) => e.get('name') === GraphShapeNames.PATH_SHAPE
        );
        pathBox.attr({
          stroke: value ? theme.palette.primary.main : theme.palette.divider,
          endArrow: {
            path: G6.Arrow.triangleRect(8, 8, 8, 0, 0),
            fill: value ? theme.palette.primary.main : theme.palette.divider,
          },
        });
        if (value) {
          edge.toFront();
        } else {
          edge.toBack();
        }
      });
    }

    for (let i = 0; i < COLUMN_PAGINATION_LIMIT; i++) {
      if (name === `column-${i}-container-hover`) {
        const currentAttr = itemModel.attrs[i];
        if (currentAttr && currentAttr?.hasConnection) {
          const columnContainer = group.find(
            (e) => e.get('name') === `column-${i}-container`
          );

          columnContainer.attr({
            stroke: value ? theme.palette.primary.main : 'transparent',
          });

          const columnEdges = edges.filter((edge) => {
            return (
              (edge._cfg.model.source === itemModel.id &&
                edge._cfg.model.sourceKey === currentAttr.key) ||
              (edge._cfg.model.target === itemModel.id &&
                edge._cfg.model.targetKey === currentAttr.key)
            );
          });
          columnEdges.forEach((edge) => {
            const pathBox = edge._cfg.group.find((e) => {
              return e.get('name') === GraphShapeNames.PATH_SHAPE;
            });

            pathBox.attr({
              stroke: value
                ? theme.palette.primary.main
                : theme.palette.divider,
              endArrow: {
                path: G6.Arrow.triangleRect(8, 8, 8, 0, 0),
                fill: value
                  ? theme.palette.primary.main
                  : theme.palette.divider,
              },
            });
            if (value) {
              edge.toFront();
            } else {
              edge.toBack();
            }
          });
        }
      }
    }
  },

  getAnchorPoints() {
    return [
      [0, 0],
      [1, 0],
    ];
  },
});

const viewButtonsContainer = document.createElement('div');
viewButtonsContainer.classList.add('viewButtonsContainer');

const columnViewButton = document.createElement('button');
columnViewButton.innerText = 'Column view';
columnViewButton.classList.add('viewButton', 'isActive');

const pipelineViewButton = document.createElement('button');
pipelineViewButton.innerText = 'Pipeline view';
pipelineViewButton.classList.add('viewButton');

viewButtonsContainer.appendChild(columnViewButton);
viewButtonsContainer.appendChild(pipelineViewButton);

const GraphPipes = ({
  table,
  dataset,
  setExistingLineage,
  containerHeight,
  containerWidth = normalMainWidth,
  isOpened = true,
  onTableChange = () => {},
  onLineageViewChange = () => {},
  selectedLineageView = null,
}) => {
  const history = useHistory();
  const { setModal } = useModal();
  const { isLookerConnected } = useConfiguration();
  const containerRef = useRef(null);
  const chartRef = useRef(null);
  const classes = useStyles();
  const [nodes, setNodes] = useState([]);
  const [upstream, setUpstream] = useState([]);
  const [downstream, setDownstream] = useState([]);
  const { currentProject: mainNodeProject } = useUserInfo();
  const [defaultUpStreams, setDefaultUpStreams] = useState(null);
  const [defaultDownStreams, setDefaultDownStreams] = useState(null);

  /* Lineage view type */
  const isPipelineView = useMemo(() => {
    return selectedLineageView === LineageTabs.PIPELINE;
  }, [selectedLineageView]);

  const lineageUrl = useMemo(() => {
    if (isPipelineView) {
      return QUERY_TYPES.lineagePipelines;
    }
    return QUERY_TYPES.nodes;
  }, [isPipelineView]);

  const { isFetching, data } = useQuery(
    [lineageUrl, table, dataset, isLookerConnected],
    ({ queryKey }) => {
      const [url, table, dataset, isLookerConnected] = queryKey;
      return fetcherGet(url, {
        project: mainNodeProject,
        table,
        dataset,
        withLookerReports: isLookerConnected,
        from: START_DATE,
        to: END_DATE,
      });
    },
    {
      enabled: Boolean(
        table && dataset && mainNodeProject && isLookerConnected !== undefined
      ),
      cacheTime: 0,
    }
  );
  const mainNodeId = useMemo(() => {
    if (!table || !dataset || !mainNodeProject) {
      return null;
    }
    return `${mainNodeProject}.${dataset}.${table}`;
  }, [mainNodeProject, table, dataset]);

  const showChangeProjectModal = useCallback(
    (path, project, dataset, table) => {
      setModal(() => (
        <Modal
          onAgree={() => {
            navigateToPage(path, project, dataset, table);
          }}
          titlePosition='center'
          ModalBodyComponent={() => ChangeProjectModalBody(project)}
          agreeButtonText='Open'
        />
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setModal]
  );

  const navigateToPage = useCallback(
    (path, project, dataset, table) => {
      history.push({
        pathname: path,
        search: new URLSearchParams({
          project,
          dataset,
          table,
        }).toString(),
        state: { ...history.location.state },
      });

      amplEvent(
        `${AMPL_PAGE_EVENT.lineage} -> ${getFullTableName(
          table,
          dataset
        )} -> table click to ${path}`
      );
    },
    [history]
  );

  const onHiddenTablesBoxClick = useCallback(
    (evt) => {
      evt.stopPropagation();

      const currentNode = evt.item.getModel();
      const { id } = currentNode;
      const [currentProject, currentDataset, currentTable] = id.split('.');

      if (mainNodeProject !== currentProject) {
        showChangeProjectModal(
          AppRoutes.Lineage.path,
          currentProject,
          currentDataset,
          currentTable
        );

        return;
      }

      fetcherGet(QUERY_TYPES.tables, {
        dataset: currentDataset,
        table: currentTable,
        limit: 1,
        page: 1,
      }).then((data) => {
        if (data?.values?.length) {
          onTableChange(data.values[0]);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainNodeProject]
  );

  const onNodeClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      const node = evt.item.getModel();

      if (
        !node ||
        (node.nodeType !== NodeTypes.PIPELINE &&
          node.nodeType !== NodeTypes.ROUTINE)
      ) {
        return;
      }

      if (node.nodeType === NodeTypes.PIPELINE) {
        const { principalEmail, pipelineType, jobTags } = node.nodeTypeData;
        const jobHash = node.id;

        setModal(() => (
          <RightSideModal
            title='Pipeline details'
            subtitle={`Pipeline execution email: ${principalEmail}`}
            ModalBodyComponent={PipelineDetails}
            SubtitleComponent={PipelineDetailsSubtitle}
            modalWidth={824}
            jobHash={jobHash}
            jobTags={jobTags}
            pipelineType={pipelineType}
          />
        ));
      }

      if (node.nodeType === NodeTypes.ROUTINE) {
        const [dataset, routineName] = node.label.split('.');

        setModal(() => (
          <RightSideModal
            title='Stored procedure details'
            subtitle={`Persistent function ID: ${node.label}`}
            ModalBodyComponent={RoutineDetails}
            modalWidth={824}
            project={node.project}
            dataset={dataset}
            routineName={routineName}
          />
        ));
      }
    },
    [setModal]
  );

  const onCanvasDblclick = useCallback((e) => {
    e.stopPropagation();
    chartRef.current.zoom(1.2, { x: e.canvasX, y: e.canvasY }, true, {
      duration: 400,
    });
  }, []);

  const onTitleClick = useCallback(
    (evt) => {
      const fullTableName = evt.target.attrs.fullTableName;
      const nodeType = evt.target.attrs.nodeType;
      const [currentProject, currentDataset, currentTable] =
        fullTableName.split('.');

      if (nodeType === NodeTypes.DATASET) {
        if (mainNodeProject !== currentProject) {
          showChangeProjectModal(
            AppRoutes.Monitors.path,
            currentProject,
            currentDataset,
            currentTable
          );

          return;
        }

        navigateToPage(
          AppRoutes.Monitors.path,
          currentProject,
          currentDataset,
          currentTable
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainNodeProject]
  );

  const getDownEdgesRequestPromises = useCallback(
    (targets) => {
      const promises = targets.map((target) => {
        const targetSplit = target.split('.');

        const currProject = targetSplit[0];
        const currDataset = targetSplit[1];
        const currTable = targetSplit[2];
        return fetcherGet(lineageUrl, {
          project: currProject,
          table: currTable,
          dataset: currDataset,
          isUpstream: false,
          withLookerReports: isLookerConnected,
          from: START_DATE,
          to: END_DATE,
        });
      });

      return promises;
    },
    [isLookerConnected, lineageUrl]
  );

  const getDownPipelineEdgesRequestPromises = useCallback(
    (relationTable, targets) => {
      const tableSplit = relationTable.split('.');
      const currProject = tableSplit[0];
      const currDataset = tableSplit[1];
      const currTable = tableSplit[2];
      const promises = targets.map((target) => {
        return fetcherGet(QUERY_TYPES.lineagePipelineNodes, {
          project: currProject,
          table: currTable,
          dataset: currDataset,
          jobHash: target,
          isUpstream: false,
          isDownstream: true,
          from: START_DATE,
          to: END_DATE,
        });
      });

      return promises;
    },
    []
  );

  const getUpEdgesRequestPromises = useCallback(
    (sources) => {
      const promises = sources.map((source) => {
        const sourceSplit = source.split('.');

        const currProject = sourceSplit[0];
        const currDataset = sourceSplit[1];
        const currTable = sourceSplit[2];
        return fetcherGet(lineageUrl, {
          project: currProject,
          table: currTable,
          dataset: currDataset,
          isDownstream: false,
          isUpstream: true,
          from: START_DATE,
          to: END_DATE,
        });
      });

      return promises;
    },
    [lineageUrl]
  );

  const getUpPipelineEdgesRequestPromises = useCallback(
    (relationTable, sources) => {
      const tableSplit = relationTable.split('.');
      const currProject = tableSplit[0];
      const currDataset = tableSplit[1];
      const currTable = tableSplit[2];
      const promises = sources.map((source) => {
        return fetcherGet(QUERY_TYPES.lineagePipelineNodes, {
          project: currProject,
          table: currTable,
          dataset: currDataset,
          jobHash: source,
          isDownstream: false,
          from: START_DATE,
          to: END_DATE,
        });
      });

      return promises;
    },
    []
  );

  const handleChangeData = useCallback(
    (values, level = 1) => {
      if (!values.length) {
        return;
      }
      const newPipelinesUpstreamSources = [];
      const newPipelinesDownstreamTargets = [];
      const newPipelinesUpstreamRequests = {};
      const newPipelinesDownstreamRequests = {};

      setNodes((prev) => {
        const newNodes = values
          .map((data) => {
            if (isPipelineView) {
              if (data.mainNode?.nodeType === NodeTypes.PIPELINE) {
                if (data.downEdges.length) {
                  newPipelinesDownstreamTargets.push(
                    ...data.downEdges
                      .filter(
                        (edge) => edge.targetNodeType === NodeTypes.DATASET
                      )
                      .map((edge) => edge.target)
                  );
                } else if (data.upEdges.length) {
                  newPipelinesUpstreamSources.push(
                    ...data.upEdges
                      .filter(
                        (edge) => edge.targetNodeType === NodeTypes.DATASET
                      )
                      .map((edge) => edge.source)
                  );
                }
              }
              if (data.mainNode?.nodeType === NodeTypes.DATASET) {
                if (data.upEdges.length) {
                  for (const edge of data.upEdges) {
                    if (edge.targetNodeType === NodeTypes.PIPELINE) {
                      if (!newPipelinesUpstreamRequests[edge.target]) {
                        newPipelinesUpstreamRequests[edge.target] = [];
                      }
                      newPipelinesUpstreamRequests[edge.target].push(
                        edge.source
                      );
                    }
                  }
                }
                if (data.downEdges.length) {
                  for (const edge of data.downEdges) {
                    if (edge.targetNodeType === NodeTypes.PIPELINE) {
                      if (!newPipelinesDownstreamRequests[edge.source]) {
                        newPipelinesDownstreamRequests[edge.source] = [];
                      }
                      newPipelinesDownstreamRequests[edge.source].push(
                        edge.target
                      );
                    }
                  }
                }
              }
            }

            const nodes = data.nodes.map((it) => ({
              ...it,
              upEdges: [],
              downEdges: [],
              upEdgeOffset: 0,
              downEdgeOffset: 0,
              collOffset: 0,
              parents: [data.mainNode.id],
              total: undefined,
            }));
            nodes.push({
              ...data.mainNode,
              upEdges: data.upEdges,
              downEdges: data.downEdges,
              upEdgeOffset: 0,
              downEdgeOffset: 0,
              collOffset: 0,
              parents: [],
              total: data.total,
            });

            return nodes;
          })
          .flat();

        const uniqueNodes = {};
        prev.forEach((node) => {
          uniqueNodes[node.id] = node;
        });
        newNodes.forEach((node) => {
          const parentsOfParents = [];
          node.parents.forEach((parent) => {
            const parentNode = uniqueNodes[parent];
            if (parentNode) {
              parentsOfParents.push(...parentNode.parents);
            }
          });

          if (uniqueNodes[node.id]) {
            const existedNode = uniqueNodes[node.id];
            const upEdges = existedNode.upEdges.length
              ? existedNode.upEdges
              : node.upEdges;
            const downEdges = existedNode.downEdges.length
              ? existedNode.downEdges
              : node.downEdges;
            const parents = [
              ...new Set(
                existedNode.parents.concat(node.parents, parentsOfParents)
              ),
            ];
            const total = existedNode.total ? existedNode.total : node.total;
            uniqueNodes[node.id] = {
              ...node,
              upEdges,
              downEdges,
              parents,
              total,
            };
          } else {
            const parents = [...new Set(node.parents.concat(parentsOfParents))];
            uniqueNodes[node.id] = { ...node, parents };
          }
        });
        return Object.values(uniqueNodes);
      });

      const maxLevel = 2;
      if (level <= maxLevel && newPipelinesUpstreamSources.length) {
        const promises = getUpEdgesRequestPromises(newPipelinesUpstreamSources);
        if (promises.length) {
          Promise.all(promises).then((values) => {
            handleChangeData(values, level + 1);
          });
        }
      }
      if (level <= maxLevel && newPipelinesDownstreamTargets.length) {
        const promises = getDownEdgesRequestPromises(
          newPipelinesDownstreamTargets
        );
        if (promises.length) {
          Promise.all(promises).then((values) => {
            handleChangeData(values, level + 1);
          });
        }
      }
    },
    [isPipelineView, getUpEdgesRequestPromises, getDownEdgesRequestPromises]
  );

  const onAddDownstreamClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      const pipelineTargets = isPipelineView
        ? evt.item._cfg.model.visibilityProps.downEdges
            .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
            .map(({ target }) => target)
        : [];
      const newPipelineDownstream =
        evt.item._cfg.model.visibilityProps.downEdges
          .slice(0, EDGE_LIMIT)
          .filter((edge) => {
            return edge.targetNodeType === NodeTypes.PIPELINE;
          })
          .map((edge) => edge.target);

      setDownstream((prev) => [
        ...new Set([...prev, evt.item._cfg.id, ...newPipelineDownstream]),
      ]);

      const targets = evt.item._cfg.model.visibilityProps.downEdges
        .filter((edge) => edge.targetNodeType === NodeTypes.DATASET)
        .map(({ target }) => target);
      const promises = getDownEdgesRequestPromises(targets);
      const promisesPipelines = getDownPipelineEdgesRequestPromises(
        evt.item._cfg.id,
        pipelineTargets
      );
      const allPromises = [...promises, ...promisesPipelines];
      if (allPromises.length) {
        Promise.all(allPromises).then((values) => {
          handleChangeData(values);
        });
      }
    },
    [
      getDownEdgesRequestPromises,
      getDownPipelineEdgesRequestPromises,
      handleChangeData,
      isPipelineView,
    ]
  );

  const onAddUpstreamClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      const pipelineSources = isPipelineView
        ? evt.item._cfg.model.visibilityProps.upEdges
            .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
            .map(({ source }) => source)
        : [];
      const newPipelineUpstream = evt.item._cfg.model.visibilityProps.upEdges
        .slice(0, EDGE_LIMIT)
        .filter((edge) => {
          return edge.targetNodeType === NodeTypes.PIPELINE;
        })
        .map((edge) => edge.source);

      setUpstream((prev) => [
        ...new Set([...prev, evt.item._cfg.id, ...newPipelineUpstream]),
      ]);

      const sources = evt.item._cfg.model.visibilityProps.upEdges
        .filter((edge) => edge.targetNodeType === NodeTypes.DATASET)
        .map(({ source }) => source);
      const promises = getUpEdgesRequestPromises(sources);
      const promisesPipelines = getUpPipelineEdgesRequestPromises(
        evt.item._cfg.id,
        pipelineSources
      );
      const allPromises = [...promises, ...promisesPipelines];
      if (allPromises.length) {
        Promise.all(allPromises).then((values) => {
          handleChangeData(values);
        });
      }
    },
    [
      getUpEdgesRequestPromises,
      getUpPipelineEdgesRequestPromises,
      handleChangeData,
      isPipelineView,
    ]
  );

  const onNextDownstreamClick = useCallback((evt) => {
    evt.stopPropagation();
    const from =
      evt.item._cfg.model.visibilityProps.downEdgeOffset + EDGE_LIMIT;
    const to = from + EDGE_LIMIT;
    const newPipelineDownstream = evt.item._cfg.model.visibilityProps.downEdges
      .slice(from, to)
      .filter((edge) => {
        return edge.targetNodeType === NodeTypes.PIPELINE;
      })
      .map((edge) => edge.target);

    setNodes((prev) => {
      const sourcesWithParent = prev
        .filter((node) => node.parents.includes(evt.item._cfg.id))
        .map((node) => node.id);

      setDownstream((prevDownstream) => {
        return [
          ...new Set([
            ...prevDownstream.filter(
              (id) => id === evt.item._cfg.id || !sourcesWithParent.includes(id)
            ),
            ...newPipelineDownstream,
          ]),
        ];
      });

      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.downEdgeOffset += EDGE_LIMIT;
          return newNode;
        }
        if (sourcesWithParent.includes(node.id) && node.downEdgeOffset) {
          node.downEdgeOffset = 0;
        }

        return node;
      });
    });
  }, []);

  const onPrevDownstreamClick = useCallback((evt) => {
    evt.stopPropagation();
    const from =
      evt.item._cfg.model.visibilityProps.downEdgeOffset - EDGE_LIMIT;
    const to = from + EDGE_LIMIT;
    const newPipelineDownstream = evt.item._cfg.model.visibilityProps.downEdges
      .slice(from, to)
      .filter((edge) => {
        return edge.targetNodeType === NodeTypes.PIPELINE;
      })
      .map((edge) => edge.target);

    setNodes((prev) => {
      const sourcesWithParent = prev
        .filter((node) => node.parents.includes(evt.item._cfg.id))
        .map((node) => node.id);

      setDownstream((prevDownstream) => {
        return [
          ...new Set([
            ...prevDownstream.filter(
              (id) => id === evt.item._cfg.id || !sourcesWithParent.includes(id)
            ),
            ...newPipelineDownstream,
          ]),
        ];
      });

      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.downEdgeOffset -= EDGE_LIMIT;
          return newNode;
        }
        if (sourcesWithParent.includes(node.id) && node.downEdgeOffset) {
          node.downEdgeOffset = 0;
        }

        return node;
      });
    });
  }, []);

  const onNextUpstreamClick = useCallback((evt) => {
    evt.stopPropagation();
    const from = evt.item._cfg.model.visibilityProps.upEdgeOffset + EDGE_LIMIT;
    const to = from + EDGE_LIMIT;
    const newPipelineUpstream = evt.item._cfg.model.visibilityProps.upEdges
      .slice(from, to)
      .filter((edge) => {
        return edge.targetNodeType === NodeTypes.PIPELINE;
      })
      .map((edge) => edge.source);

    setNodes((prev) => {
      const sourcesWithParent = prev
        .filter((node) => node.parents.includes(evt.item._cfg.id))
        .map((node) => node.id);

      setUpstream((prevUpstream) => {
        return [
          ...new Set([
            ...prevUpstream.filter(
              (id) => id === evt.item._cfg.id || !sourcesWithParent.includes(id)
            ),
            ...newPipelineUpstream,
          ]),
        ];
      });

      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.upEdgeOffset += EDGE_LIMIT;
          return newNode;
        }
        if (sourcesWithParent.includes(node.id)) {
          node.upEdgeOffset = 0;
          node.collOffset = 0;
        }

        return node;
      });
    });
  }, []);

  const onPrevUpstreamClick = useCallback((evt) => {
    evt.stopPropagation();
    const from = evt.item._cfg.model.visibilityProps.upEdgeOffset - EDGE_LIMIT;
    const to = from + EDGE_LIMIT;
    const newPipelineUpstream = evt.item._cfg.model.visibilityProps.upEdges
      .slice(from, to)
      .filter((edge) => {
        return edge.targetNodeType === NodeTypes.PIPELINE;
      })
      .map((edge) => edge.source);

    setNodes((prev) => {
      const sourcesWithParent = prev
        .filter((node) => node.parents.includes(evt.item._cfg.id))
        .map((node) => node.id);

      setUpstream((prevUpstream) => {
        return [
          ...new Set([
            ...prevUpstream.filter(
              (id) => id === evt.item._cfg.id || !sourcesWithParent.includes(id)
            ),
            ...newPipelineUpstream,
          ]),
        ];
      });

      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.upEdgeOffset -= EDGE_LIMIT;
          return newNode;
        }
        if (sourcesWithParent.includes(node.id)) {
          node.upEdgeOffset = 0;
          node.collOffset = 0;
        }

        return node;
      });
    });
  }, []);

  const onRemoveDownstreamClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      // remove all if click on the root element
      setNodes((prev) => {
        const sourcesWithParent = prev
          .filter((node) => node.parents.includes(evt.item._cfg.id))
          .map((node) => node.id);

        setDownstream((prevDownstream) => {
          if (evt.item._cfg.id === mainNodeId) {
            return [];
          }
          return prevDownstream.filter(
            (id) => !sourcesWithParent.includes(id) && id !== evt.item._cfg.id
          );
        });

        return prev.map((node) => {
          if (sourcesWithParent.includes(node.id)) {
            node.upEdgeOffset = 0;
            node.collOffset = 0;
          }

          return node;
        });
      });
    },
    [mainNodeId]
  );

  const onRemoveUpstreamClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      // remove all if click on the root element
      setNodes((prev) => {
        const sourcesWithParent = prev
          .filter((node) => node.parents.includes(evt.item._cfg.id))
          .map((node) => node.id);

        setUpstream((prevUpstream) => {
          if (evt.item._cfg.id === mainNodeId) {
            return [];
          }
          return prevUpstream.filter(
            (id) => !sourcesWithParent.includes(id) && id !== evt.item._cfg.id
          );
        });

        return prev.map((node) => {
          if (sourcesWithParent.includes(node.id)) {
            node.upEdgeOffset = 0;
            node.collOffset = 0;
          }

          return node;
        });
      });
    },
    [mainNodeId]
  );

  const onPaginationLeftClick = useCallback((evt) => {
    evt.stopPropagation();
    setNodes((prev) => {
      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.collOffset -= COLUMN_PAGINATION_LIMIT;
          return newNode;
        }

        return node;
      });
    });
  }, []);

  const onPaginationRightClick = useCallback((evt) => {
    evt.stopPropagation();
    setNodes((prev) => {
      return prev.map((node) => {
        if (node.id === evt.item._cfg.id) {
          const newNode = { ...node };
          newNode.collOffset += COLUMN_PAGINATION_LIMIT;
          return newNode;
        }

        return node;
      });
    });
  }, []);

  const requestAllEdges = useCallback(
    (upEdges, downEdges) => {
      const promisesUp = getUpEdgesRequestPromises(upEdges);
      const promisesDown = getDownEdgesRequestPromises(downEdges);
      const promises = [...promisesUp, ...promisesDown];
      if (promises.length) {
        Promise.all(promises).then((values) => {
          handleChangeData(values);
        });
      }
    },
    [getDownEdgesRequestPromises, getUpEdgesRequestPromises, handleChangeData]
  );

  const requestAllPipelineEdges = useCallback(
    (relatedTable, upEdges, downEdges, upPipelineEdges, downPipelineEdges) => {
      const promisesUp = getUpEdgesRequestPromises(upEdges);
      const promisesDown = getDownEdgesRequestPromises(downEdges);
      const promisesUpPipelines = getDownPipelineEdgesRequestPromises(
        relatedTable,
        downPipelineEdges
      );
      const promisesDownPipelines = getUpPipelineEdgesRequestPromises(
        relatedTable,
        upPipelineEdges
      );

      const promises = [
        ...promisesUp,
        ...promisesDown,
        ...promisesUpPipelines,
        ...promisesDownPipelines,
      ];
      if (promises.length) {
        Promise.all(promises).then((values) => {
          handleChangeData(values);
        });
      }
    },
    [
      getDownEdgesRequestPromises,
      getUpEdgesRequestPromises,
      getDownPipelineEdgesRequestPromises,
      getUpPipelineEdgesRequestPromises,
      handleChangeData,
    ]
  );

  useEffect(() => {
    if (data?.mainNode) {
      const nodes = data.nodes.map((it) => ({
        ...it,
        upEdges: [],
        downEdges: [],
        upEdgeOffset: 0,
        downEdgeOffset: 0,
        collOffset: 0,
        parents: [data.mainNode.id],
        total: undefined,
      }));
      nodes.push({
        ...data.mainNode,
        upEdges: data.upEdges,
        downEdges: data.downEdges,
        upEdgeOffset: 0,
        downEdgeOffset: 0,
        collOffset: 0,
        parents: [],
        total: data.total,
      });
      const defaultStreams = isOpened ? [data.mainNode.id] : [];
      const upStreams = [...defaultStreams];
      const downStreams = [...defaultStreams];
      if (isOpened && isPipelineView) {
        const upPipelineStreams = data.upEdges
          .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
          .map((edge) => edge.source);
        upStreams.push(...upPipelineStreams.slice(0, EDGE_LIMIT));
        const downPipelineStreams = data.downEdges
          .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
          .map((edge) => edge.target);
        downStreams.push(...downPipelineStreams.slice(0, EDGE_LIMIT));
      }
      setNodes(nodes);
      setDefaultUpStreams(upStreams);
      setDefaultDownStreams(downStreams);
      setUpstream(upStreams);
      setDownstream(downStreams);
    }
  }, [data, isOpened, isPipelineView]);

  // Destroy after removing table
  useEffect(() => {
    if (
      chartRef.current &&
      (!mainNodeId || isFetching || !data?.nodes.length)
    ) {
      setNodes([]);
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
      chartRef.current = null;
    }
  }, [data?.nodes, mainNodeId, isFetching]);

  // Updating
  useEffect(() => {
    if (nodes.length && chartRef.current) {
      chartRef.current.changeData(
        dataTransform(
          nodes,
          mainNodeId,
          upstream,
          downstream,
          EDGE_LIMIT,
          COLUMN_LIMIT,
          isLookerConnected === true
        )
      );
    }
  }, [nodes, mainNodeId, upstream, downstream, isLookerConnected]);

  useEffect(() => {
    if (
      mainNodeId &&
      nodes.length > 0 &&
      !chartRef.current &&
      containerRef?.current &&
      isOpened
    ) {
      containerRef.current.appendChild(viewButtonsContainer);
    }
  }, [isOpened, mainNodeId, nodes.length]);

  useEffect(() => {
    const handleChangeViewType = (value) => {
      onLineageViewChange(value);
    };

    columnViewButton.addEventListener('click', () =>
      handleChangeViewType(LineageTabs.COLUMN)
    );
    pipelineViewButton.addEventListener('click', () =>
      handleChangeViewType(LineageTabs.PIPELINE)
    );

    return () => {
      columnViewButton.removeEventListener('click', handleChangeViewType);
      pipelineViewButton.removeEventListener('click', handleChangeViewType);
    };
  }, [onLineageViewChange]);

  useEffect(() => {
    if (pipelineViewButton && columnViewButton) {
      if (isPipelineView) {
        pipelineViewButton.classList.add('isActive');
        columnViewButton.classList.remove('isActive');
      } else {
        columnViewButton.classList.add('isActive');
        pipelineViewButton.classList.remove('isActive');
      }
    }
  }, [isPipelineView]);

  useEffect(() => {
    if (data?.nodes && setExistingLineage) {
      setExistingLineage(data?.nodes.length > 0);
    } else if (isFetching) {
      setExistingLineage(true);
    }
  }, [data?.nodes, setExistingLineage, isFetching]);

  // First render
  useEffect(() => {
    if (
      mainNodeId &&
      nodes.length > 0 &&
      !chartRef.current &&
      containerRef?.current &&
      defaultUpStreams !== null &&
      defaultDownStreams !== null
    ) {
      const chartData = dataTransform(
        nodes,
        mainNodeId,
        defaultUpStreams,
        defaultDownStreams,
        EDGE_LIMIT,
        COLUMN_LIMIT,
        isLookerConnected === true
      );
      chartRef.current = new G6.Graph({
        layout: {
          type: 'dagre',
          begin: [50, 50],
          rankdir: 'LR',
          align: 'UL', // UR or DR Looks better
          controlPoints: true,
          nodesepFunc: (node) => {
            const nodeProject =
              (node.nodeType === NodeTypes.DATASET && node.id.split('.')[0]) ||
              (node.nodeType === NodeTypes.PIPELINE && node.project) ||
              (node.nodeType === NodeTypes.ROUTINE && node.project) ||
              null;

            if (
              (nodeProject && nodeProject !== mainNodeProject) ||
              node.nodeType === NodeTypes.DATASET
            ) {
              return 20;
            }

            return 12;
          },
          ranksep: isPipelineView ? 25 : 50,
        },
        container: containerRef.current,
        data: chartData,
        width: containerWidth,
        height:
          containerRef?.current?.parentElement.clientHeight || containerHeight,
        fitView: true,
        fitCenter: true,
        animate: true,
        minZoom: 0.05,
        maxZoom: 1,
        groupByTypes: false,
        modes: {
          default: [
            GraphDefaultModes.DRAG_CANVAS,
            GraphDefaultModes.DRAG_NODE,
            isOpened && GraphModes.TOGGLE_COLLAPSED,
            isOpened && GraphDefaultModes.ZOOM_CANVAS,
          ],
        },
        defaultEdge: {
          type: GraphDefaultTypes.DEFAULT_EDGE,
        },
        defaultNode: {
          type: GraphDefaultTypes.DEFAULT_NODE,
          size: [TABLE_WIDTH, TABLE_HEIGHT / 2],
          preRect: {
            show: false,
          },
        },
        plugins: [
          isOpened && newToolbar(),
          isOpened && newTooltip(),
          isOpened && newLegend(defaultStartDate(), defaultEndDate()),
          isOpened && newMinimap(),
        ],
      });

      chartRef.current.on('canvas:dblclick', onCanvasDblclick);

      chartRef.current.on(
        `${GraphShapeNames.TOP_BOX_GROUP}:mouseenter`,
        (e) => {
          chartRef.current.setItemState(
            e.item,
            GraphStateNames.TOP_BOX_GROUP_HOVER,
            true
          );
        }
      );
      chartRef.current.on(
        `${GraphShapeNames.TOP_BOX_GROUP}:mouseleave`,
        (e) => {
          chartRef.current.setItemState(
            e.item,
            GraphStateNames.TOP_BOX_GROUP_HOVER,
            false
          );
        }
      );

      chartRef.current.on(
        `${GraphShapeNames.HIDDEN_TABLES_GROUP}:mouseenter`,
        (e) => {
          chartRef.current.setItemState(
            e.item,
            GraphStateNames.TOP_BOX_GROUP_HOVER,
            true
          );
        }
      );
      chartRef.current.on(
        `${GraphShapeNames.HIDDEN_TABLES_GROUP}:mouseleave`,
        (e) => {
          chartRef.current.setItemState(
            e.item,
            GraphStateNames.TOP_BOX_GROUP_HOVER,
            false
          );
        }
      );

      for (let i = 0; i < COLUMN_PAGINATION_LIMIT; i++) {
        chartRef.current.on(`column-${i}-container:mouseenter`, (e) => {
          chartRef.current.setItemState(
            e.item,
            `column-${i}-container-hover`,
            true
          );
        });
        chartRef.current.on(`column-${i}-container:mouseleave`, (e) => {
          chartRef.current.setItemState(
            e.item,
            `column-${i}-container-hover`,
            false
          );
        });
      }
      chartRef.current.on(
        `${GraphShapeNames.TOP_BOX_GROUP}:click`,
        onNodeClick
      );
      chartRef.current.on(`${GraphShapeNames.TITLE_SHAPE}:click`, onTitleClick);
      chartRef.current.on(
        `${GraphShapeNames.ADD_DOWNSTREAM_BTN}:click`,
        onAddDownstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.ADD_DOWNSTREAM_BTN_TEXT}:click`,
        onAddDownstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.ADD_UPSTREAM_BTN}:click`,
        onAddUpstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.ADD_UPSTREAM_BTN_TEXT}:click`,
        onAddUpstreamClick
      );

      chartRef.current.on(
        `${GraphShapeNames.REMOVE_DOWNSTREAM_BTN}:click`,
        onRemoveDownstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.REMOVE_DOWNSTREAM_BTN_TEXT}:click`,
        onRemoveDownstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.REMOVE_UPSTREAM_BTN}:click`,
        onRemoveUpstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.REMOVE_UPSTREAM_BTN_TEXT}:click`,
        onRemoveUpstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.UPSTREAM_NEXT_BTN}:click`,
        onNextUpstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.UPSTREAM_PREV_BTN}:click`,
        onPrevUpstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.DOWNSTREAM_NEXT_BTN}:click`,
        onNextDownstreamClick
      );
      chartRef.current.on(
        `${GraphShapeNames.DOWNSTREAM_PREV_BTN}:click`,
        onPrevDownstreamClick
      );

      chartRef.current.on(
        `${GraphShapeNames.PAGINATION_LEFT}:click`,
        onPaginationLeftClick
      );
      chartRef.current.on(
        `${GraphShapeNames.PAGINATION_RIGHT}:click`,
        onPaginationRightClick
      );
      chartRef.current.on(
        `${GraphShapeNames.HIDDEN_TABLES_BOX}:click`,
        onHiddenTablesBoxClick
      );
      chartRef.current.on(
        `${GraphShapeNames.HIDDEN_TABLES_TEXT}:click`,
        onHiddenTablesBoxClick
      );

      chartRef.current.render();
      chartRef.current.fitView();
      chartRef.current.updateBehavior(
        GraphDefaultModes.ZOOM_CANVAS,
        { sensitivity: 0.5 },
        'default'
      );

      if (isOpened) {
        const mainNode = chartData.nodes.find((item) => item.id === mainNodeId);
        if (mainNode) {
          const mainNodeUpEdges = mainNode.upEdges
            .filter((edge) => edge.targetNodeType === NodeTypes.DATASET)
            .map((edge) => edge.source);
          const mainNodeDownEdges = mainNode.downEdges
            .filter((edge) => edge.targetNodeType === NodeTypes.DATASET)
            .map((edge) => edge.target);
          if (!isPipelineView) {
            requestAllEdges(mainNodeUpEdges, mainNodeDownEdges);
          } else {
            const mainNodeUpPipelineEdges = mainNode.upEdges
              .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
              .map((edge) => edge.source);
            const mainNodeDownPipelineEdges = mainNode.downEdges
              .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
              .map((edge) => edge.target);

            requestAllPipelineEdges(
              mainNodeId,
              mainNodeUpEdges,
              mainNodeDownEdges,
              mainNodeUpPipelineEdges,
              mainNodeDownPipelineEdges
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainNodeId,
    nodes,
    isLookerConnected,
    onAddDownstreamClick,
    onAddUpstreamClick,
    onRemoveDownstreamClick,
    onRemoveUpstreamClick,
    isOpened,
    onCanvasDblclick,
    onTitleClick,
    containerHeight,
    containerWidth,
    onNextUpstreamClick,
    onPrevUpstreamClick,
    onNextDownstreamClick,
    onPrevDownstreamClick,
    onPaginationLeftClick,
    onPaginationRightClick,
    onHiddenTablesBoxClick,
    isPipelineView,
    defaultUpStreams,
    defaultDownStreams,
  ]);

  return (
    <div className={classes.container}>
      {!data?.nodes ? <Loader /> : <div ref={containerRef} />}
    </div>
  );
};

export { GraphPipes };
