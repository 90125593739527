import {
  useGetVolumeData,
  useGetVolumeRawData,
  useEffect,
  useMemo,
  useQuery,
  useGetVolumeThreshold,
  useTableAnalysis,
} from 'hooks/hooks.js';
import { Volume } from './Volume.js';
import { ScatterGraph } from './ScatterGraph.js';
import { fetcherGet } from 'utils/utils.js';
import { getUnixDateByStr } from 'utils/helpers/helpers.js';
import { getAreaData, getAreaDataWithThresholdCoefficient } from './utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { EXISTING_DATA_TYPES } from 'Pages/Monitors/libs/constants.js';

const MILLISECONDS_IN_SECONDS = 1000;

const TableDataVolume = ({
  table,
  dataset,
  onChangeExistingData,
  onChangeExistingButton,
  currentDatetime = null,
  withScatterGraph = false,
  customPercentThreshold = null,
}) => {
  const isEditingThresholdCoefficient = customPercentThreshold !== null;

  const { percentThreshold } = useTableAnalysis({
    dataset,
    table,
  });

  const { isFetching: isFetchingVolumeData, data } = useGetVolumeData({
    table,
    dataset,
    isEnabled: !isEditingThresholdCoefficient,
  });

  const { metricVolumeThreshold } = useGetVolumeThreshold({
    dataset,
    table,
    isEnabled: isEditingThresholdCoefficient,
  });

  const { isFetching: isFetchingScatterData, data: metrics } =
    useGetVolumeRawData({
      dataset,
      table,
      isEnabled: withScatterGraph,
    });

  const { isFetching: isFrequencyFetching, data: frequency } = useQuery(
    [QUERY_TYPES.tableFrequency, dataset, table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
      });
    },
    { enabled: Boolean(dataset && table) }
  );

  const frequencyMilliseconds = useMemo(() => {
    return frequency?.value * MILLISECONDS_IN_SECONDS || null;
  }, [frequency?.value]);

  const volumeData = useMemo(() => {
    if (isEditingThresholdCoefficient && metricVolumeThreshold.length) {
      return getAreaDataWithThresholdCoefficient(
        metricVolumeThreshold,
        customPercentThreshold
      );
    }
    if (data?.values.length > 0) {
      return getAreaData(data?.values);
    }

    return [];
  }, [
    data?.values,
    isEditingThresholdCoefficient,
    metricVolumeThreshold,
    customPercentThreshold,
  ]);

  const scatterData = useMemo(() => {
    if (volumeData.length && metrics?.values) {
      return [
        { datetime: volumeData.at(0).datetime, value: null },
        ...metrics.values,
        {
          datetime:
            getUnixDateByStr(volumeData.at(-1).datetime) +
            frequencyMilliseconds,
          value: null,
        },
      ];
    }

    return [];
  }, [frequencyMilliseconds, metrics?.values, volumeData]);

  const isShownScatterGraph = useMemo(() => {
    return metrics?.values.length > 0 && withScatterGraph;
  }, [metrics?.values.length, withScatterGraph]);

  // Destroy after removing table
  const isDestroyingGraph = useMemo(() => {
    return !table || isFetchingVolumeData || !volumeData.length;
  }, [isFetchingVolumeData, table, volumeData.length]);

  useEffect(() => {
    if (onChangeExistingButton) {
      onChangeExistingButton(data?.values?.length > 0 || false);
    }

    if (!onChangeExistingData) return;

    const hasValues = data?.values?.length > 0 || metrics?.values?.length > 0;
    const isFetching =
      isFetchingVolumeData || isFrequencyFetching || isFetchingScatterData;

    if (hasValues || isFetching) {
      onChangeExistingData(EXISTING_DATA_TYPES.volume, true);
    } else {
      onChangeExistingData(EXISTING_DATA_TYPES.volume, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.values,
    metrics?.values,
    isFetchingVolumeData,
    isFrequencyFetching,
    isFetchingScatterData,
  ]);

  return (
    <>
      {!volumeData.length && metrics?.values.length ? (
        <ScatterGraph
          scatterData={metrics.values}
          isFetchingScatterData={isFetchingScatterData}
        />
      ) : (
        <Volume
          currentDatetime={currentDatetime}
          volumeData={volumeData}
          frequencyMilliseconds={frequencyMilliseconds}
          isFetchingVolumeData={isFetchingVolumeData}
          withScatterGraph={withScatterGraph}
          scatterData={scatterData}
          isFetchingScatterData={isFetchingScatterData}
          isShownScatterGraph={isShownScatterGraph}
          isDestroyingGraph={isDestroyingGraph}
          percentThreshold={percentThreshold}
          customPercentThreshold={customPercentThreshold}
        />
      )}
    </>
  );
};

export { TableDataVolume };
