import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from '../StatsWidget.styles.js';
import { CostsStatsGraph } from './CostsStatsGraph.js';
import { AppRoutes } from 'app-routes.js';
import { PipelineTabs } from 'constants/constants.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const CostsStatsGraphView = () => {
  const classes = useStyles();

  return (
    <>
      <div className='mt-3'>
        <Link
          to={{
            pathname: AppRoutes.Pipelines.path,
            search: new URLSearchParams({
              tab: PipelineTabs.COSTS,
            }).toString(),
          }}
        >
          See breakdown
          <ArrowRight className={classes.linkIcon} />
        </Link>
      </div>

      <CostsStatsGraph />
    </>
  );
};

export { CostsStatsGraphView };
