import { useEffect, useQuery, useState } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useJobVolume = (jobHash, metricType, enabled = true) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const [volumeData, setVolumeData] = useState([]);

  const { data, isFetching: isLoadingVolumeData } = useQuery(
    [QUERY_TYPES.jobVolume, jobHash, metricType, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
        from: startDateUtc,
        to: endDateUtc,
      });
    },
    {
      enabled: Boolean(
        jobHash && metricType && startDateUtc && endDateUtc && enabled
      ),
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.values) {
      setVolumeData(data.values);
    }
  }, [data?.values]);

  return { volumeData, isLoadingVolumeData };
};

export { useJobVolume };
