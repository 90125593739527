import React from 'react';
import {
  useEffect,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useMemo,
  useLocation,
  useHistory,
  useGetProjects,
  useCostsStatsPrevious,
  useCostsStats,
  useCostsDeadEndTables,
  useDashboardData,
} from 'hooks/hooks.js';
import { useStyles } from './Dashboard.styles.js';
import {
  AMPL_PAGE_EVENT,
  COSTS_STATS_VIEW_TYPE,
  QUERY_TYPES,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import {
  OnboardingInfo,
  AddProjectModal,
  IncidentsStats,
  PriorityTablesBanner,
} from './components/components.js';
import { useModal, useUserInfo } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  CostsStats,
  DemoBanner,
  PageHeader,
  Backdrop,
} from 'Components/components.js';

const Dashboard = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { setModal } = useModal();
  const [onBoarding, setOnBoarding] = useState(false);
  const [isShowCosts, setIsShowCosts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, isCurrentProjectDemo, currentProject } = useUserInfo();
  const queryClient = useQueryClient();
  const { projects, isLoadingProjects } = useGetProjects();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const isExistingParams = useMemo(() => {
    return params.has(SEARCH_PARAMS.CREATE_PROJECT);
  }, [params]);

  useEffect(() => amplEvent(AMPL_PAGE_EVENT.dashboard), []);

  const { isFetching: isProjectStatusFetching, data: projectStatus } = useQuery(
    [QUERY_TYPES.projectStatus],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    if (projectStatus?.value) {
      setOnBoarding(projectStatus.value.isShow);
    }
  }, [projectStatus]);

  const { mutateAsync: hideOnboarding } = useMutation(
    () => {
      setOnBoarding(false);
      return fetcherPost(QUERY_TYPES.projectStatus, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_TYPES.projectStatus);
      },
    }
  );

  const { isDashboardDataFetching, dashboardData } = useDashboardData();
  const { costsStats, isCostsStatsFetching } = useCostsStats();
  const { costsStatsPrevious } = useCostsStatsPrevious();
  const { deadEndTablesStats } = useCostsDeadEndTables({
    enabled: isShowCosts,
  });

  const { isFetching: isBqBillingDataFetching, data: bqBillingData } = useQuery(
    [QUERY_TYPES.bqBillingData, user.info.tenant],
    ({ queryKey }) => {
      const [url, tenant] = queryKey;
      return fetcherGet(url, { tenant });
    }
  );
  useEffect(() => {
    if (bqBillingData?.values) {
      const projectBqBillingData = bqBillingData.values.find(
        (item) => item.project === currentProject
      );
      if (projects && !isCurrentProjectDemo) {
        setIsShowCosts(
          bqBillingData.values.length === projects.length &&
            !!projectBqBillingData &&
            costsStats.length > 0
        );
      }
    }
    if (isCurrentProjectDemo) {
      setIsShowCosts(true);
    }
  }, [
    bqBillingData?.values,
    projects,
    isCurrentProjectDemo,
    currentProject,
    costsStats,
  ]);

  useEffect(() => {
    if (
      isLoadingProjects ||
      isDashboardDataFetching ||
      isProjectStatusFetching ||
      isBqBillingDataFetching
    ) {
      setIsLoading(true);
    }
    if (
      !isLoadingProjects &&
      !isDashboardDataFetching &&
      !isProjectStatusFetching &&
      !isBqBillingDataFetching
    ) {
      setIsLoading(false);
    }
  }, [
    isDashboardDataFetching,
    isProjectStatusFetching,
    isLoadingProjects,
    isBqBillingDataFetching,
  ]);

  const isShowDemoBanner = useMemo(() => {
    // return (
    //   !isCurrentProjectDemo &&
    //   projectStatus?.value &&
    //   projectStatus?.value.dataPatternReady === false
    // );
    return false;
  }, []);

  if (isExistingParams) {
    setModal(() => <AddProjectModal projectName={currentProject} />);
    params.delete(SEARCH_PARAMS.CREATE_PROJECT);
    history.replace({
      search: params.toString(),
    });
  }

  return (
    <div className={classes.pageContainer}>
      {isShowDemoBanner && <DemoBanner />}
      <PageHeader>Dashboard</PageHeader>

      <main className={classes.mainContainer}>
        <PriorityTablesBanner />

        {onBoarding && (
          <OnboardingInfo
            projectStatus={projectStatus?.value}
            closeHandler={hideOnboarding}
          />
        )}

        <IncidentsStats dashboardData={dashboardData} />

        {isShowCosts && (
          <CostsStats
            costsStats={costsStats}
            costsStatsPrevious={costsStatsPrevious}
            deadEndTablesStats={deadEndTablesStats}
            viewType={COSTS_STATS_VIEW_TYPE.graph}
          />
        )}
      </main>

      <Backdrop isOpen={isLoading} />
    </div>
  );
};

export default Dashboard;
