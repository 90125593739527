import React from 'react';
import {
  useState,
  useCallback,
  useEffect,
  usePipelineDestinationTables,
  usePipelineStats,
  useMemo,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import {
  Backdrop,
  Button,
  BUTTON_COLOR,
  PipelineStats,
} from 'Components/components.js';
import { PipelineTable } from './components/components.js';
import { Dropdowns, Search } from '../components.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';
import {
  COST_EFFICIENCY_FILTER_OPTIONS,
  DEAD_END_TABLES,
  PIPELINE_STATUS_FILTER_OPTIONS,
  PIPELINE_TIME_PERIOD_OPTIONS,
} from 'constants/constants.js';
import {
  ACTION_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  INITIAL_TECHNOLOGY_OPTION,
  PipelineDropdowns,
  SEARCH_DESTINATION_TABLE,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_DESTINATION,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
  TIME_PERIOD_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const PipelineTab = ({
  searchValues,
  handleChangeSearchValues,
  page,
  rowsPerPage,
  handleChangePage,
  onReceivePaginationTotal,
}) => {
  const classes = useStyles();

  const [dropdownsValue, setDropdownsValue] = useState({
    [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [ACTION_STATUS_FILTER]: [PIPELINE_STATUS_FILTER_OPTIONS[0]],
    [TIME_PERIOD_FILTER]: [PIPELINE_TIME_PERIOD_OPTIONS.at(-1)],
    [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
    [COST_EFFICIENCY_FILTER]: [COST_EFFICIENCY_FILTER_OPTIONS[0]],
  });

  const isDeadEndTablesSelected = useMemo(() => {
    return dropdownsValue[COST_EFFICIENCY_FILTER][0].value === DEAD_END_TABLES;
  }, [dropdownsValue]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const {
    destinationTables,
    destinationTablesNumber,
    isFetchingDestinationTables,
    refetchDestinationTables,
  } = usePipelineDestinationTables(
    dropdownsValue[TIME_PERIOD_FILTER][0].value.start,
    dropdownsValue[TIME_PERIOD_FILTER][0].value.end,
    page,
    rowsPerPage,
    searchValues[SELECTED_DESTINATION]?.table,
    searchValues[SELECTED_DESTINATION]?.dataset,
    searchValues[SEARCH_DESTINATION_TABLE],
    searchValues[SELECTED_TABLE]?.table,
    searchValues[SELECTED_TABLE]?.dataset,
    searchValues[SEARCH_TABLE],
    searchValues[SELECTED_EMAIL],
    searchValues[SEARCH_EMAIL],
    searchValues[SELECTED_TAGS],
    searchValues[SELECTED_LABEL]?.key,
    searchValues[SELECTED_LABEL]?.value,
    searchValues[SEARCH_LABEL],
    dropdownsValue[ACTION_STATUS_FILTER],
    dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    dropdownsValue[TECHNOLOGY_FILTER][0].value,
    isDeadEndTablesSelected
  );

  const { pipelineStats, refetchPipelineStats } = usePipelineStats(
    dropdownsValue[TIME_PERIOD_FILTER][0].value.start,
    dropdownsValue[TIME_PERIOD_FILTER][0].value.end
  );

  const handleRefreshPage = () => {
    handleChangePage({}, 0);
    refetchDestinationTables();
    refetchPipelineStats();
  };

  useEffect(() => {
    onReceivePaginationTotal(destinationTablesNumber);
  }, [destinationTablesNumber, onReceivePaginationTotal]);

  return (
    <>
      <PipelineStats
        pipelineStats={pipelineStats}
        className={classes.statsContainer}
      />

      <div className={classes.mainContainer}>
        <Dropdowns
          onChangeDropdownsValue={handleChangeDropdownsValue}
          pipelineStats={pipelineStats}
          existingDropdown={PipelineDropdowns}
        />

        <div className={classes.searchContainer}>
          <Search
            searchValues={searchValues}
            onChangeSearchValues={handleChangeSearchValues}
          />

          <Button
            onClick={handleRefreshPage}
            color={BUTTON_COLOR.secondary}
            text='Refresh'
            fullWidth={false}
            startIcon={<RefreshIcon />}
          />
        </div>

        <PipelineTable
          destinationTables={destinationTables}
          isFetchingDestinationTables={isFetchingDestinationTables}
          startDate={dropdownsValue[TIME_PERIOD_FILTER][0].value.start}
          endDate={dropdownsValue[TIME_PERIOD_FILTER][0].value.end}
          searchValues={searchValues}
        />

        <Backdrop isOpen={isFetchingDestinationTables} />
      </div>
    </>
  );
};

export { PipelineTab };
