import { useMemo, useQuery } from 'hooks/hooks.js';
import { Freshness } from 'Components/components.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const TableDataFreshness = ({ table, dataset, onChangeExistingData }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.freshness, table, dataset, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, table, dataset, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
        from: startDate,
        to: endDate,
      });
    },
    { enabled: Boolean(table && dataset), cacheTime: 0 }
  );

  // Destroy after removing table
  const isDestroyingGraph = useMemo(() => {
    return !table || isFetching;
  }, [isFetching, table]);

  return (
    <Freshness
      data={data?.values}
      isFetchingData={isFetching}
      onChangeExistingData={onChangeExistingData}
      isDestroyingGraph={isDestroyingGraph}
    />
  );
};

export { TableDataFreshness };
