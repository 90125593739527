import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useGetLookerIncidentGroups = ({
  page,
  rowsPerPage,
  dataset = '',
  table = '',
  searchTable = '',
  incidentGroupsTypes,
  incidentGroupsAlert,
  lookerAlertTypes,
  incidentGroupsStatuses,
  incidentIdAndType,
  searchDashboardId,
  searchLookId,
  searchDashboardName,
  searchLookName,
  enabled = true,
  cacheTime = 0,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching: isIncidentGroupsFetching, data } = useQuery(
    [
      QUERY_TYPES.lookerIncidentGroups,
      startDateUtc,
      endDateUtc,
      page,
      rowsPerPage,
      dataset,
      table,
      searchTable,
      incidentGroupsTypes,
      incidentGroupsAlert,
      lookerAlertTypes,
      incidentGroupsStatuses,
      searchDashboardId,
      searchLookId,
      searchDashboardName,
      searchLookName,
      incidentIdAndType,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const incidentTypes = [];
      incidentTypes.push(...incidentGroupsTypes.map((item) => item.value));

      const incidentStatuses = [];
      if (
        incidentGroupsStatuses.length > 1 ||
        (incidentGroupsStatuses.length &&
          incidentGroupsStatuses[0].value !== '')
      ) {
        incidentStatuses.push(
          ...incidentGroupsStatuses.map((item) => item.value)
        );
      }

      const incidentAlerts = [];
      if (
        incidentGroupsAlert.length > 1 ||
        (incidentGroupsAlert.length && incidentGroupsAlert[0].value !== '')
      ) {
        incidentAlerts.push(...incidentGroupsAlert.map((item) => item.value));
      }

      const incidentLookerAlerts = [];
      if (lookerAlertTypes.length > 1 || lookerAlertTypes[0].value !== '') {
        incidentLookerAlerts.push(
          ...lookerAlertTypes.map((item) => item.value)
        );
      }

      const params = new URLSearchParams();
      params.append('from', startDateUtc);
      params.append('to', endDateUtc);
      params.append('page', page + 1);
      params.append('limit', rowsPerPage);
      params.append('dataset', dataset || '');
      params.append('table', table || '');
      params.append('search', searchTable || '');
      params.append('searchDashboardName', searchDashboardName);
      params.append('searchLookName', searchLookName);
      if (searchDashboardId) {
        params.append('dashboardId', searchDashboardId);
      }
      if (searchLookId) {
        params.append('lookId', searchLookId);
      }

      if (incidentIdAndType?.id && incidentIdAndType?.type) {
        params.append('id', incidentIdAndType.id);
        params.append('type', incidentIdAndType.type);
      } else {
        for (const incidentType of incidentTypes) {
          params.append('incidentTypeGroups', incidentType);
        }
        for (const incidentAlert of incidentAlerts) {
          params.append('alertTypes', incidentAlert);
        }
        for (const incidentStatus of incidentStatuses) {
          params.append('incidentStatuses', incidentStatus);
        }
        for (const incidentLookerAlert of incidentLookerAlerts) {
          params.append('lookerAlertTypes', incidentLookerAlert);
        }
      }
      return fetcherGet(url, params);
    },
    { cacheTime, enabled }
  );

  const incidentGroups = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const incidentsTotal = useMemo(() => {
    return data?.incidentPagination.total || 0;
  }, [data?.incidentPagination.total]);

  const incidentsGroupPagination = useMemo(() => {
    return data?.groupPagination.total || 0;
  }, [data?.groupPagination.total]);

  return {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
  };
};

export { useGetLookerIncidentGroups };
