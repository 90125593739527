import { useEffect, useMemo, useQuery, useState } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useCostsStatsPrevious = (enabled = true) => {
  const { startDate, endDate } = useDateContext();
  const [startDateBefore, setStartDateBefore] = useState();
  const [endDateBefore, setEndDateBefore] = useState();

  useEffect(() => {
    if (startDate && endDate) {
      const startMoment = moment(startDate).utc();
      const endMoment = moment(endDate).utc();
      const diffMoment = endMoment.diff(startMoment);
      const diffMomentDays = Math.ceil(diffMoment / (1000 * 60 * 60 * 24));
      const startMomentBefore = moment(startMoment).subtract(
        diffMomentDays,
        'd'
      );
      const endMomentBefore = moment(startMomentBefore).add(diffMoment, 'ms');
      setStartDateBefore(startMomentBefore.toDate());
      setEndDateBefore(endMomentBefore.toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { data } = useQuery(
    [QUERY_TYPES.jobProjectCost, startDateBefore, endDateBefore],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    {
      enabled:
        startDateBefore !== undefined && endDateBefore !== undefined && enabled,
    }
  );

  const costsStatsPrevious = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { costsStatsPrevious };
};

export { useCostsStatsPrevious };
