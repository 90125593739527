import { NodeTypes } from './enums/enums.js';

export const dataTransform = (
  data,
  destinationTable,
  upstream = [],
  isPipeline = false
) => {
  const nodes = [...data];
  const anomalyTables = nodes.reduce((acc, curr) => {
    if (
      curr.nodeType === NodeTypes.DATASET &&
      curr.nodeTypeData.isAnomaly !== null
    ) {
      acc[curr.id] = curr.nodeTypeData.isAnomaly;
    }

    return acc;
  }, {});
  const anomalyPipelines = nodes.reduce((acc, curr) => {
    if (curr.nodeType === NodeTypes.PIPELINE) {
      acc[curr.id] = curr.upEdges.some((edge) => {
        return !!anomalyTables[edge.source];
      });
    }

    return acc;
  }, {});

  const edges = nodes
    .map((it) => {
      return it.upEdges
        .concat(it.downEdges)
        .map((it) => {
          const finalEdges = [];
          for (const key in it.keys) {
            finalEdges.push({
              source: it.source,
              target: it.target,
              targetNodeType: it.targetNodeType,
              sourceKey: key,
              targetKey: it.keys[key],
            });
          }
          return finalEdges;
        })
        .flat();
    })
    .flat();

  const allowedUpstream = [
    ...new Set([
      ...upstream,
      ...edges
        .filter(({ target }) => upstream.includes(target))
        .map(({ source }) => source),
    ]),
  ];

  const withEdges = nodes.map((item) => {
    const inEdge = edges
      .filter(({ target }) => target === item.id)
      .map((i) => ({ ...i })); // This is required to crate new instance
    const outEdge = edges
      .filter(({ source }) => source === item.id)
      .map((i) => ({ ...i })); // This is required to crate new instance
    const isUpstream = allowedUpstream.includes(item.id);
    const isCurrentTable = item.id === destinationTable.id;
    const isNextUpstreamHidden = false;
    const isNextDownstreamHidden = false;
    const isAnomalyTable = anomalyTables[item.id];
    const isAnomalySource = inEdge.some(
      (item) => anomalyTables[item.source] || anomalyPipelines[item.source]
    );
    const isGrayTable = isAnomalySource && !isAnomalyTable;
    const mainNodeProject = destinationTable.project;

    return {
      ...item,
      visibilityProps: {
        isUpstream,
        isCurrentTable,
        isNextUpstreamHidden,
        isNextDownstreamHidden,
        inEdge,
        outEdge,
        isAnomalyTable,
        isGrayTable,
        isPipeline,
        mainNodeProject,
      },
    };
  });

  const filteredNodes = withEdges.filter(
    ({ id }) => id === destinationTable.id || allowedUpstream.includes(id)
  );
  const filteredEdges = edges.filter(
    ({ source, target }) =>
      filteredNodes.some(({ id }) => id === source) &&
      filteredNodes.some(({ id }) => id === target)
  );

  return {
    nodes: filteredNodes,
    edges: filteredEdges,
  };
};
