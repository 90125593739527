import { useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext } from 'context/context.js';

const useCostsStats = (enabled = true) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching: isCostsStatsFetching, data } = useQuery(
    [QUERY_TYPES.jobProjectCost, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
      });
    },
    { enabled }
  );

  const costsStats = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { costsStats, isCostsStatsFetching };
};

export { useCostsStats };
