import { useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';

const useGetIsAnomalyTable = (dataset, table) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  return useQuery(
    [QUERY_TYPES.tablesIsAnomaly, dataset, table, startDateUtc, endDateUtc],
    async ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      const response = await fetcherGet(url, {
        dataset,
        table,
        from: startDate,
        to: endDate,
      });
      return response?.value;
    },
    {
      enabled: !!dataset && !!table,
      staleTime: 5 * 60 * 1000,
    }
  );
};

export { useGetIsAnomalyTable };
