import React from 'react';
import {
  useCostsDailyStats,
  useCostsDailyStatsPrevious,
  useEffect,
  useMemo,
  useRef,
} from 'hooks/hooks.js';
import moment from 'moment/moment';
import { makeStyles } from '@mui/styles';
import { Chart } from '@antv/g2';
import { PositionLoader } from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import projectTheme from 'theme.js';

const CURRENT_PERIOD_COLOR = projectTheme.palette.primary.main;
const PREVIOUS_PERIOD_COLOR = projectTheme.palette.divider;
const GRAPH_HEIGHT = '268px';

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD');
};

const useStyles = makeStyles((theme) => ({
  graph: {
    width: '100%',
    height: GRAPH_HEIGHT,
    '& canvas': {
      height: `${GRAPH_HEIGHT} !important`,
    },

    '& .g2-tooltip': {
      padding: '8px !important',
      fontFamily: 'Inter, Arial, sans-serif !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      color: '#545454 !important',
      backgroundColor: 'rgba(255, 255, 255, 0.9) !important',
      border: '1px solid #e2e2e2 !important',
      borderRadius: '12px !important',
      boxShadow: 'rgb(174, 174, 174) 0px 0px 10px !important',

      '&-title, &-list-item': {
        fontFamily: 'Inter, Arial, sans-serif !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        color: '#545454 !important',
      },
    },
  },

  legend: {
    display: 'flex',
    gap: theme.spacing(4),
    '& .legend-item': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      color: '#7A7A7A',

      '& .legend-color': {
        width: theme.spacing(6),
        height: theme.spacing(0.5),

        '&.currentPeriod': {
          backgroundColor: CURRENT_PERIOD_COLOR,
        },

        '&.previousPeriod': {
          border: `1px dashed ${PREVIOUS_PERIOD_COLOR}`,
        },
      },
    },
  },
}));

const CostsStatsGraph = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const graphRef = useRef(null);

  const { isFetchingCostsDailyStats, costsDailyStats } = useCostsDailyStats();
  const { isFetchingCostsDailyStatsPrevious, costsDailyStatsPrevious } =
    useCostsDailyStatsPrevious();

  useEffect(() => {
    return () => {
      if (graphRef.current) {
        graphRef.current.destroy();
        graphRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (
      graphRef.current &&
      (isFetchingCostsDailyStats || isFetchingCostsDailyStatsPrevious)
    ) {
      graphRef.current.destroy();
      graphRef.current = null;
    }
  }, [isFetchingCostsDailyStatsPrevious, isFetchingCostsDailyStats]);

  const isReadyChartsForRender = useMemo(() => {
    return costsDailyStats.length > 0 && !graphRef.current;
  }, [costsDailyStats]);

  useEffect(() => {
    if (!isReadyChartsForRender) {
      return;
    }

    const createChart = () => {
      if (graphRef.current) {
        graphRef.current.destroy();
      }

      graphRef.current = new Chart({
        container: containerRef.current,
        autoFit: true,
        padding: 12,
        paddingLeft: 16,
        theme: {
          color: projectTheme.palette.primary.main,
        },
      });

      graphRef.current
        .data(costsDailyStats)
        .encode('x', ({ datetime }) => new Date(datetime))
        .encode('y', 'costs')
        .scale('y', { nice: true })
        .axis('x', {
          labelAutoRotate: false,
          labelFormatter: (data) => getDatetimeFormat(data),
          line: true,
          grid: null,
        })
        .axis('y', {
          title: null,
          labelFormatter: '~s',
          gridLineDash: null,
          gridStrokeWidth: 1,
          gridStroke: projectTheme.palette.divider,
          gridStrokeOpacity: 0.75,
        });

      graphRef.current
        .line()
        .style({ lineWidth: 2, stroke: CURRENT_PERIOD_COLOR })
        .tooltip({
          title: null,
          items: [
            ({ costs, datetime }) => ({
              name: getDatetimeFormat(datetime),
              value: numberFormat(costs),
            }),
          ],
        });

      if (costsDailyStatsPrevious.length > 0) {
        graphRef.current
          .line()
          .data(costsDailyStatsPrevious)
          .encode('x', ({ datetime }) => new Date(datetime))
          .encode('y', 'costs')
          .scale('x', { independent: true })
          .axis('x', null)
          .style({
            lineWidth: 2,
            stroke: PREVIOUS_PERIOD_COLOR,
            lineDash: [5, 5],
          })
          .tooltip({
            title: null,
            items: [
              ({ costs, datetime }) => {
                if (costs !== null) {
                  return {
                    name: getDatetimeFormat(datetime),
                    value: numberFormat(costs),
                  };
                }
                return {
                  name: '',
                  value: '',
                  color: 'transparent',
                };
              },
            ],
          });
      }

      graphRef.current.render();
    };

    createChart();

    const resizeObserver = new ResizeObserver(() => {
      if (graphRef.current) {
        graphRef.current.forceFit();
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (graphRef.current) {
        graphRef.current.destroy();
      }
    };
  }, [costsDailyStats, costsDailyStatsPrevious, isReadyChartsForRender]);

  return (
    <>
      <div ref={containerRef} className={classes.graph}>
        {(isFetchingCostsDailyStats || isFetchingCostsDailyStatsPrevious) && (
          <PositionLoader />
        )}
      </div>
      <Legend />
    </>
  );
};

const Legend = () => {
  const classes = useStyles();

  return (
    <section className={classes.legend}>
      <div className='legend-item'>
        <div className='legend-color currentPeriod' />
        <div>Current period</div>
      </div>
      <div className='legend-item'>
        <div className='legend-color previousPeriod' />
        <div>Previous period</div>
      </div>
    </section>
  );
};

export { CostsStatsGraph };
