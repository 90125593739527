import { TABLE_TYPES, ROUTINE_TYPE } from 'constants/constants.js';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/lineageIcons/node-icons/external-table.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/lineageIcons/node-icons/view.svg';
import { ReactComponent as DbTableIcon } from 'assets/img/icons/lineageIcons/node-icons/table.svg';
import { ReactComponent as RoutineIcon } from 'assets/img/icons/lineageIcons/node-icons/routine.svg';
import { ReactComponent as PartitionIcon } from 'assets/img/icons/projectTreeIcons/partition.svg';

export const REFERENCED_TYPE = {
  [TABLE_TYPES.external]: { icon: ExternalIcon },
  [TABLE_TYPES.view]: { icon: ViewIcon },
  [TABLE_TYPES.dbTable]: { icon: DbTableIcon },
  [TABLE_TYPES.bqTable]: { icon: DbTableIcon },
  [TABLE_TYPES.sharded]: { icon: DbTableIcon },
  [TABLE_TYPES.partition]: { icon: PartitionIcon },
  [ROUTINE_TYPE.routine]: { icon: RoutineIcon },
  default: { icon: DbTableIcon },
};
