import React from 'react';
import {
  useMemo,
  useOpenTableRawBySearch,
  useCostsSourceTables,
  useTableLabels,
  useBqBillingData,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import { Chip, CHIP_COLOR, TableLabelsChips } from 'Components/components.js';
import { getAlertColorType, getAlertLabel } from 'utils/helpers/helpers.js';
import {
  CostLabel,
  DestinationTableCell,
  LoadingTableRaw,
} from '../components.js';
import SourceTableRow from './SourceTableRow.js';
import {
  BQ_BILLING_TYPE,
  PIPELINE_TYPE,
  PIPELINE_TYPE_DATA,
} from 'utils/constants.js';

const getDestinationTableIcon = (pipelineType) =>
  PIPELINE_TYPE_DATA[pipelineType]?.icon;

const ColumnDetails = ({
  destinationTable,
  searchValue,
  selectedTable,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  selectedPipelineType,
  pipelineSubtypes,
  isAlternativePlanSelected,
}) => {
  const { currentBqBillingData } = useBqBillingData();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const { isOpenTableRaw, handleChangeOpen } = useOpenTableRawBySearch(
    searchValue,
    selectedTable,
    destinationTable
  );

  const { sources, isFetchingSources } = useCostsSourceTables({
    dataset: destinationTable.dataset,
    table: destinationTable.table,
    sourceTable: selectedTable?.table,
    sourceDataset: selectedTable?.dataset,
    searchValue,
    principalEmail,
    searchPrincipalEmail,
    jobTagNames,
    pipelineType: destinationTable?.pipelineType,
    pipelineSubtypes,
    isAlternativePlanSelected,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  const isJobTypeCellExists = useMemo(() => {
    return selectedPipelineType === PIPELINE_TYPE.BQ_DTS;
  }, [selectedPipelineType]);

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          searchTable={selectedTable}
          searchValue={searchValue}
          icon={getDestinationTableIcon(destinationTable?.pipelineType)}
        />
        <TableCell
          align='left'
          sx={{ alignContent: 'baseline' }}
          colSpan={isExistingReservationCell ? 7 : 6}
        >
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {destinationTable?.isAltPlanSavings && (
              <Chip
                label='Alternative plan option'
                color={CHIP_COLOR.success}
              />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>

        {isJobTypeCellExists && <TableCell />}
        <TableCell align='right'>
          <div className='txt-mainDark-13-700'>
            <CostLabel value={destinationTable?.costs} />
          </div>
        </TableCell>
      </TableRow>

      {isOpenTableRaw &&
        (isFetchingSources ? (
          <LoadingTableRaw />
        ) : (
          sources.map((source) => (
            <SourceTableRow
              key={source.jobHash}
              source={source}
              destinationTable={destinationTable}
              isJobTypeCellExists={isJobTypeCellExists}
              selectedTable={selectedTable}
              searchValue={searchValue}
            />
          ))
        ))}
    </>
  );
};

export default ColumnDetails;
