import React from 'react';
import { useBqBillingData, useMemo } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { TableCell, TableRow } from '@mui/material';
import { ALTERNATIVE_PLAN_CELL, DATE_FORMAT } from 'constants/constants.js';
import {
  ComparisonValues,
  OverflownText,
  PipelineDetails,
  RightSideModal,
} from 'Components/components.js';
import { CostLabel, JobTagsTableCell, SourceTableCell } from '../components.js';
import { useModal } from 'context/context.js';
import { bytesToSize, millisecondsToTimeSlots } from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE, PIPELINE_SUBTYPE_LABELS } from 'utils/constants.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const { PRINCIPAL_EMAIL_WIDTH, RESERVATION_WIDTH } = PipelineTableCellStyles;

const SourceTableRow = ({
  source,
  destinationTable,
  isJobTypeCellExists,
  selectedTable,
  searchValue,
}) => {
  const {
    jobHash,
    tables,
    sourceUri,
    destinationUri,
    principalEmail,
    jobTags,
    frequency,
    costs,
    lastActivity,
    pipelineSubtype,
    sourceProject,
    reservation,
    routines,
    totalBytes,
    totalSlotsMs,
    possibleCosts,
  } = source;
  const { setModal } = useModal();
  const { bqBillingData, currentBqBillingData } = useBqBillingData();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;

  const sourceData = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  const sourcePaymentType = bqBillingData?.values?.find(
    (item) => item.project === sourceProject
  )?.paymentType;
  const isOnDemand = sourcePaymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const currentPlanValue = isOnDemand
    ? bytesToSize(totalBytes)
    : millisecondsToTimeSlots(totalSlotsMs);

  const alternativePlanValue = isOnDemand
    ? millisecondsToTimeSlots(totalSlotsMs)
    : bytesToSize(totalBytes);

  const onSourceRawClick = () => {
    setModal(() => (
      <RightSideModal
        title='Pipeline details'
        subtitle={`Pipeline execution email: ${principalEmail}`}
        ModalBodyComponent={PipelineDetails}
        modalWidth={824}
        jobHash={jobHash}
        jobTags={jobTags}
        lineageData={{
          destinationTable,
          tables: sourceData,
          sourceUri: sourceUri,
          destinationUri: destinationUri,
          pipeline: {
            pipelineType: destinationTable?.pipelineType,
            sourceProject,
          },
        }}
      />
    ));
  };

  return (
    <TableRow onClick={onSourceRawClick}>
      <SourceTableCell
        sourceData={sourceData}
        sourceUri={sourceUri}
        selectedTable={selectedTable}
        searchValue={searchValue}
      />

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      {isExistingReservationCell && (
        <TableCell align='left' className='nowrapText'>
          {reservation?.length ? (
            <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
              {reservation}
            </OverflownText>
          ) : (
            <span className='txt-grey-13-500'>N/A</span>
          )}
        </TableCell>
      )}

      {isJobTypeCellExists && (
        <TableCell>
          {PIPELINE_SUBTYPE_LABELS[pipelineSubtype] || 'N/A'}
        </TableCell>
      )}

      <TableCell align='right' className='nowrapText'>
        {frequency}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {lastActivity ? (
          moment(lastActivity).utc().format(DATE_FORMAT.monthYearTime)
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>
      <TableCell align='right' className='nowrapText withBackground'>
        {currentPlanValue || <span className='txt-grey-13-500'>N/A</span>}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {alternativePlanValue || <span className='txt-grey-13-500'>N/A</span>}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
        {possibleCosts > 0 && (
          <ComparisonValues
            primaryValue={costs}
            secondaryValue={possibleCosts}
            tip={ALTERNATIVE_PLAN_CELL}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default SourceTableRow;
