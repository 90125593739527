import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const DEFAULT_ROWS_PER_PAGE = 5;

const useGetOneIncident = ({
  id,
  incidentType,
  filterStatuses,
  filterIdAndType,
  incidentsPage,
  enabled = true,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const { isFetching: isFetchingIncidentData, data } = useQuery(
    [
      `${QUERY_TYPES.incidents}/${id}`,
      startDateUtc,
      endDateUtc,
      filterStatuses,
      filterIdAndType,
      incidentsPage,
      DEFAULT_ROWS_PER_PAGE,
    ],
    ({ queryKey }) => {
      const [
        url,
        startDate,
        endDate,
        filterStatuses,
        filterIdAndType,
        page,
        limit,
      ] = queryKey;
      const params = new URLSearchParams();
      const incidentStatuses = [];

      if (!filterIdAndType?.id || !filterIdAndType?.type) {
        if (
          filterStatuses.length > 1 ||
          (filterStatuses.length && filterStatuses[0].value !== '')
        ) {
          incidentStatuses.push(...filterStatuses.map((item) => item.value));
        }
      }

      params.append('from', startDate);
      params.append('to', endDate);
      params.append('type', incidentType);
      for (const incidentStatus of incidentStatuses) {
        params.append('incidentStatuses', incidentStatus);
      }
      params.set('page', page + 1);
      params.set('limit', limit);

      return fetcherGet(url, params);
    },
    { enabled }
  );

  const incidentDataTotal = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  const incidentData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { incidentData, incidentDataTotal, isFetchingIncidentData };
};

export { useGetOneIncident };
