import React from 'react';
import {
  useMemo,
  useState,
  useEffect,
  useLocation,
  useHistory,
} from 'hooks/hooks.js';
import { StyledDropdown } from 'Components/components.js';
import { ALERT_FILTER_OPTIONS, PIPELINE_TYPE_DATA } from 'utils/constants.js';
import {
  ALTERNATIVE_PLAN_OPTION,
  COST_EFFICIENCY_FILTER_OPTIONS,
  PIPELINE_STATUS_FILTER_OPTIONS,
  PIPELINE_TIME_PERIOD_OPTIONS,
  PipelineTabs,
} from 'constants/constants.js';
import {
  ACTION_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  INITIAL_TECHNOLOGY_OPTION,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
  TIME_PERIOD_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { RegularMiniIcon } from 'assets/img/alert';
import { StatusIcon } from 'assets/img/dropdownIcons';
import { ReactComponent as ClockIcon } from 'assets/img/icons/clock.svg';
import { ReactComponent as TechnologyIcon } from 'assets/img/icons/tech.svg';
import { ReactComponent as CostStatsIcon } from 'assets/img/icons/cost-stats.svg';

const filterOptions = (options, stateValue) =>
  options.filter(({ value }) => stateValue.includes(value));

const Dropdowns = ({
  onChangeDropdownsValue,
  pipelineStats,
  existingDropdown = [],
  currentTab = null,
}) => {
  const location = useLocation();
  const history = useHistory();

  const technologyFilterOptions = [
    INITIAL_TECHNOLOGY_OPTION,
    ...pipelineStats.map((item) => ({
      value: item.pipelineType,
      label: PIPELINE_TYPE_DATA[item.pipelineType]?.title,
    })),
  ];

  const costEfficiencyFilterOptions = [
    ...COST_EFFICIENCY_FILTER_OPTIONS,
    ...(currentTab === PipelineTabs.COSTS
      ? [{ value: ALTERNATIVE_PLAN_OPTION, label: 'Alternative plan option' }]
      : []),
  ];

  const pipelineTechnologyStateOption = filterOptions(
    technologyFilterOptions,
    location.state?.filterPipelineTechnologyIds || []
  );

  const pipelineEfficiencyStateOption = filterOptions(
    costEfficiencyFilterOptions,
    location.state?.filterPipelineEfficiencyIds || []
  );

  const [dropdownsValue, setDropdownsValue] = useState({
    [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [ACTION_STATUS_FILTER]: [PIPELINE_STATUS_FILTER_OPTIONS[0]],
    [TIME_PERIOD_FILTER]: [PIPELINE_TIME_PERIOD_OPTIONS.at(-1)],
    [TECHNOLOGY_FILTER]: [technologyFilterOptions[0]],
    [COST_EFFICIENCY_FILTER]: [costEfficiencyFilterOptions[0]],
  });

  const handleChangeDropdowns = (key, value) => {
    setDropdownsValue((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    if (pipelineTechnologyStateOption.length) {
      if (
        dropdownsValue[TECHNOLOGY_FILTER][0].value !==
        pipelineTechnologyStateOption[0].value
      ) {
        handleChangeDropdowns(TECHNOLOGY_FILTER, pipelineTechnologyStateOption);
      }

      delete location.state.filterPipelineTechnologyIds;
      history.replace({ ...history.location, state: location.state });
    }
  }, [dropdownsValue, history, location.state, pipelineTechnologyStateOption]);

  useEffect(() => {
    if (pipelineEfficiencyStateOption.length) {
      if (
        dropdownsValue[COST_EFFICIENCY_FILTER][0].value !==
        pipelineEfficiencyStateOption[0].value
      ) {
        handleChangeDropdowns(
          COST_EFFICIENCY_FILTER,
          pipelineEfficiencyStateOption
        );
      }

      delete location.state.filterPipelineEfficiencyIds;
      history.replace({ ...history.location, state: location.state });
    }
  }, [dropdownsValue, history, location.state, pipelineEfficiencyStateOption]);

  const tableAlertAfterLabel =
    dropdownsValue[TABLE_ALERT_STATUS_FILTER][0].value ===
    ALERT_FILTER_OPTIONS[0].value
      ? ' of destination tables'
      : ' destination tables';

  const pipelineStatusAfterLabel =
    dropdownsValue[ACTION_STATUS_FILTER][0].value ===
    PIPELINE_STATUS_FILTER_OPTIONS[0].value
      ? ' of pipelines'
      : '';

  const pipelineStatusBeforeLabel =
    dropdownsValue[ACTION_STATUS_FILTER][0].value !==
    PIPELINE_STATUS_FILTER_OPTIONS[0].value
      ? 'Only '
      : '';

  const filteredDropdownsValue = useMemo(() => {
    return Object.keys(dropdownsValue)
      .filter((key) => existingDropdown.includes(key))
      .reduce((obj, key) => {
        obj[key] = dropdownsValue[key];
        return obj;
      }, {});
  }, [dropdownsValue, existingDropdown]);

  useEffect(() => {
    onChangeDropdownsValue(filteredDropdownsValue);
  }, [filteredDropdownsValue, onChangeDropdownsValue]);

  return (
    <div>
      {existingDropdown.includes(TECHNOLOGY_FILTER) && (
        <StyledDropdown
          options={technologyFilterOptions}
          defaultValue={dropdownsValue[TECHNOLOGY_FILTER]}
          Icon={TechnologyIcon}
          onSelect={(value) => handleChangeDropdowns(TECHNOLOGY_FILTER, value)}
          selectAllOption={technologyFilterOptions[0]}
          multipleSelect={false}
        />
      )}

      {existingDropdown.includes(TIME_PERIOD_FILTER) && (
        <StyledDropdown
          options={PIPELINE_TIME_PERIOD_OPTIONS}
          defaultValue={dropdownsValue[TIME_PERIOD_FILTER]}
          Icon={ClockIcon}
          onSelect={(value) => handleChangeDropdowns(TIME_PERIOD_FILTER, value)}
          multipleSelect={false}
        />
      )}

      {existingDropdown.includes(TABLE_ALERT_STATUS_FILTER) && (
        <StyledDropdown
          options={ALERT_FILTER_OPTIONS}
          defaultValue={dropdownsValue[TABLE_ALERT_STATUS_FILTER]}
          Icon={RegularMiniIcon}
          onSelect={(value) =>
            handleChangeDropdowns(TABLE_ALERT_STATUS_FILTER, value)
          }
          selectAllOption={ALERT_FILTER_OPTIONS[0]}
          afterLabel={tableAlertAfterLabel}
        />
      )}

      {existingDropdown.includes(ACTION_STATUS_FILTER) && (
        <StyledDropdown
          options={PIPELINE_STATUS_FILTER_OPTIONS}
          defaultValue={dropdownsValue[ACTION_STATUS_FILTER]}
          Icon={StatusIcon}
          onSelect={(value) =>
            handleChangeDropdowns(ACTION_STATUS_FILTER, value)
          }
          selectAllOption={PIPELINE_STATUS_FILTER_OPTIONS[0]}
          afterLabel={pipelineStatusAfterLabel}
          beforeLabel={pipelineStatusBeforeLabel}
          multipleSelect={false}
        />
      )}

      {existingDropdown.includes(COST_EFFICIENCY_FILTER) && (
        <StyledDropdown
          options={costEfficiencyFilterOptions}
          defaultValue={dropdownsValue[COST_EFFICIENCY_FILTER]}
          Icon={CostStatsIcon}
          onSelect={(value) =>
            handleChangeDropdowns(COST_EFFICIENCY_FILTER, value)
          }
          selectAllOption={costEfficiencyFilterOptions[0]}
          multipleSelect={false}
        />
      )}
    </div>
  );
};

export { Dropdowns };
